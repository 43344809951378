

import { util } from "../../util/index";
import { store, useStateRef } from "../../util/store";
import { useCallback } from "react";
import { useEffect } from "react";
import {
  getStoryMediaUrl,
} from "../CallApi";
import ImageViewer from "../map/ImageViewer";



import "./StoryImageFull.css";
import AssetCloseFullStory from "../../assets/CloseFullStory.svg";

const StoryImageFull = () => {
  const [storyImage, setStoryImage] = useStateRef();

  const [marginTransition, setMarginTransition] =
    useStateRef();

  const [topMargin, setTopMargin] = useStateRef();



  const doClose = useCallback(async () => {
    store.setSession("show_full_story_image", false);
  }, []);

  const onClose = useCallback(async () => {
    setTopMargin(String(window.innerHeight) + "px");
    setTimeout(() => {
      doClose().then(() => { });
    }, 500);
  }, []);

  const slideUp = useCallback(async () => {
    setTopMargin("0px");
  }, []);

  useEffect(() => {
    const initialize = async () => {
      setTopMargin(String(window.innerHeight) + "px");
      setMarginTransition("margin-top 0.5s");
      setTimeout(() => {
        slideUp().then(() => { });
      });
      setStoryImage(
        await getStoryMediaUrl(store.getSession("current_story"), "imageFile")
      );
    };
    initialize().then(() => { });
  }, []);

  return (
    <div
      className="story-image-full-main-container"
      style={{
        transition: (marginTransition),
        marginTop: (topMargin ?? "10000px"),
      }}
    >
      <ImageViewer src={storyImage} />

      <div className="story-image-full-header-container">
        <div className="story-image-full-close-container">
          <img
            className="story-image-full-close-image"
            onClick={onClose}
            src={AssetCloseFullStory}
          />
        </div>
      </div>
    </div>
  );
};

export default StoryImageFull;
