import { useState, useRef, useEffect, useSyncExternalStore } from "react";

import asset_Back from "../assets/Back.svg";
import asset_BackRed from "../assets/BackRed.svg";
import asset_ClioMuseLogo from "../assets/ClioMuseLogo.svg";
import asset_CloseFullStory from "../assets/CloseFullStory.svg";
import asset_Directions from "../assets/Directions.svg";
import asset_Downloading from "../assets/Downloading.svg";
import asset_ExpandStory from "../assets/ExpandStory.svg";
import asset_FinishMarker from "../assets/FinishMarker.svg";
import asset_FinishPoint from "../assets/FinishPoint.svg";
import asset_GoToMap from "../assets/GoToMap.svg";
import asset_Info from "../assets/Info.svg";
import asset_Like from "../assets/Like.svg";
import asset_Liked from "../assets/Liked.svg";
import asset_LoginLogo from "../assets/LoginLogo.svg";
import asset_MapTarget from "../assets/MapTarget.svg";
import asset_MinimizeDown from "../assets/MinimizeDown.svg";
import asset_MoALogo from "../assets/MoALogo.png";
import asset_Onboarding1 from "../assets/Onboarding1.svg";
import asset_Onboarding2 from "../assets/Onboarding2.svg";
import asset_OnboardingSwipe from "../assets/OnboardingSwipe.svg";
import asset_Pause from "../assets/Pause.svg";
import asset_Play from "../assets/Play.svg";
import asset_Play10sec from "../assets/Play10sec.svg";
import asset_PlayNext from "../assets/PlayNext.svg";
import asset_PlayPrevious from "../assets/PlayPrevious.svg";
import asset_PoiMarker from "../assets/PoiMarker.svg";
import asset_RightArrow from "../assets/RightArrow.svg";
import asset_ShareFullStory from "../assets/ShareFullStory.svg";
import asset_StartMarker from "../assets/StartMarker.svg";
import asset_StartPoint from "../assets/StartPoint.svg";
import asset_StatThumbnail from "../assets/StatThumbnail.png";
import asset_Stories from "../assets/Stories.svg";
import asset_StoryPause from "../assets/StoryPause.svg";
import asset_StoryPlay from "../assets/StoryPlay.svg";
import asset_TourPhoto from "../assets/TourPhoto.png";
import asset_TourPlay from "../assets/TourPlay.svg";
import asset_TypeAlternative from "../assets/TypeAlternative.svg";
import asset_TypeArcheology from "../assets/TypeArcheology.svg";
import asset_TypeArchitecture from "../assets/TypeArchitecture.svg";
import asset_TypeArt from "../assets/TypeArt.svg";
import asset_TypeDirections from "../assets/TypeDirections.svg";
import asset_TypeFun from "../assets/TypeFun.svg";
import asset_TypeGastronomy from "../assets/TypeGastronomy.svg";
import asset_TypeHistory from "../assets/TypeHistory.svg";
import asset_TypeKids from "../assets/TypeKids.svg";
import asset_TypeLegend from "../assets/TypeLegend.svg";
import asset_TypeMythology from "../assets/TypeMythology.svg";
import asset_TypeNature from "../assets/TypeNature.svg";
import asset_TypeNightlife from "../assets/TypeNightlife.svg";
import asset_TypeQuirky from "../assets/TypeQuirky.svg";
import asset_TypeQuiz from "../assets/TypeQuiz.svg";
import asset_TypeSelfie from "../assets/TypeSelfie.svg";
import asset_TypeShopping from "../assets/TypeShopping.svg";
import asset_TypeVirtualtour from "../assets/TypeVirtualtour.svg";
import asset_more_icon from "../assets/more_icon.svg";

// Store

const store = {
  state: {
    session: {
      assets: {
        Back: {
          url: "../assets/Back.svg",
          contentType: "image/svg+xml",
          file: asset_Back,
        },
        BackRed: {
          url: "../assets/BackRed.svg",
          contentType: "image/svg+xml",
          file: asset_BackRed,
        },
        ClioMuseLogo: {
          url: "../assets/ClioMuseLogo.svg",
          contentType: "image/svg+xml",
          file: asset_ClioMuseLogo,
        },
        CloseFullStory: {
          url: "../assets/CloseFullStory.svg",
          contentType: "image/svg+xml",
          file: asset_CloseFullStory,
        },
        Directions: {
          url: "../assets/Directions.svg",
          contentType: "image/svg+xml",
          file: asset_Directions,
        },
        Downloading: {
          url: "../assets/Downloading.svg",
          contentType: "image/svg+xml",
          file: asset_Downloading,
        },
        ExpandStory: {
          url: "../assets/ExpandStory.svg",
          contentType: "image/svg+xml",
          file: asset_ExpandStory,
        },
        FinishMarker: {
          url: "../assets/FinishMarker.svg",
          contentType: "image/svg+xml",
          file: asset_FinishMarker,
        },
        FinishPoint: {
          url: "../assets/FinishPoint.svg",
          contentType: "image/svg+xml",
          file: asset_FinishPoint,
        },
        GoToMap: {
          url: "../assets/GoToMap.svg",
          contentType: "image/svg+xml",
          file: asset_GoToMap,
        },
        Info: {
          url: "../assets/Info.svg",
          contentType: "image/svg+xml",
          file: asset_Info,
        },
        Like: {
          url: "../assets/Like.svg",
          contentType: "image/svg+xml",
          file: asset_Like,
        },
        Liked: {
          url: "../assets/Liked.svg",
          contentType: "image/svg+xml",
          file: asset_Liked,
        },
        LoginLogo: {
          url: "../assets/LoginLogo.svg",
          contentType: "image/svg+xml",
          file: asset_LoginLogo,
        },
        MapTarget: {
          url: "../assets/MapTarget.svg",
          contentType: "image/svg+xml",
          file: asset_MapTarget,
        },
        MinimizeDown: {
          url: "../assets/MinimizeDown.svg",
          contentType: "image/svg+xml",
          file: asset_MinimizeDown,
        },
        MoALogo: {
          url: "../assets/MoALogo.png",
          contentType: "image/png",
          file: asset_MoALogo,
        },
        Onboarding1: {
          url: "../assets/Onboarding1.svg",
          contentType: "image/svg+xml",
          file: asset_Onboarding1,
        },
        Onboarding2: {
          url: "../assets/Onboarding2.svg",
          contentType: "image/svg+xml",
          file: asset_Onboarding2,
        },
        OnboardingSwipe: {
          url: "../assets/OnboardingSwipe.svg",
          contentType: "image/svg+xml",
          file: asset_OnboardingSwipe,
        },
        Pause: {
          url: "../assets/Pause.svg",
          contentType: "image/svg+xml",
          file: asset_Pause,
        },
        Play: {
          url: "../assets/Play.svg",
          contentType: "image/svg+xml",
          file: asset_Play,
        },
        Play10sec: {
          url: "../assets/Play10sec.svg",
          contentType: "image/svg+xml",
          file: asset_Play10sec,
        },
        PlayNext: {
          url: "../assets/PlayNext.svg",
          contentType: "image/svg+xml",
          file: asset_PlayNext,
        },
        PlayPrevious: {
          url: "../assets/PlayPrevious.svg",
          contentType: "image/svg+xml",
          file: asset_PlayPrevious,
        },
        PoiMarker: {
          url: "../assets/PoiMarker.svg",
          contentType: "image/svg+xml",
          file: asset_PoiMarker,
        },
        RightArrow: {
          url: "../assets/RightArrow.svg",
          contentType: "image/svg+xml",
          file: asset_RightArrow,
        },
        ShareFullStory: {
          url: "../assets/ShareFullStory.svg",
          contentType: "image/svg+xml",
          file: asset_ShareFullStory,
        },
        StartMarker: {
          url: "../assets/StartMarker.svg",
          contentType: "image/svg+xml",
          file: asset_StartMarker,
        },
        StartPoint: {
          url: "../assets/StartPoint.svg",
          contentType: "image/svg+xml",
          file: asset_StartPoint,
        },
        StatThumbnail: {
          url: "../assets/StatThumbnail.png",
          contentType: "image/png",
          file: asset_StatThumbnail,
        },
        Stories: {
          url: "../assets/Stories.svg",
          contentType: "image/svg+xml",
          file: asset_Stories,
        },
        StoryPause: {
          url: "../assets/StoryPause.svg",
          contentType: "image/svg+xml",
          file: asset_StoryPause,
        },
        StoryPlay: {
          url: "../assets/StoryPlay.svg",
          contentType: "image/svg+xml",
          file: asset_StoryPlay,
        },
        TourPhoto: {
          url: "../assets/TourPhoto.png",
          contentType: "image/png",
          file: asset_TourPhoto,
        },
        TourPlay: {
          url: "../assets/TourPlay.svg",
          contentType: "image/svg+xml",
          file: asset_TourPlay,
        },
        TypeAlternative: {
          url: "../assets/TypeAlternative.svg",
          contentType: "image/svg+xml",
          file: asset_TypeAlternative,
        },
        TypeArcheology: {
          url: "../assets/TypeArcheology.svg",
          contentType: "image/svg+xml",
          file: asset_TypeArcheology,
        },
        TypeArchitecture: {
          url: "../assets/TypeArchitecture.svg",
          contentType: "image/svg+xml",
          file: asset_TypeArchitecture,
        },
        TypeArt: {
          url: "../assets/TypeArt.svg",
          contentType: "image/svg+xml",
          file: asset_TypeArt,
        },
        TypeDirections: {
          url: "../assets/TypeDirections.svg",
          contentType: "image/svg+xml",
          file: asset_TypeDirections,
        },
        TypeFun: {
          url: "../assets/TypeFun.svg",
          contentType: "image/svg+xml",
          file: asset_TypeFun,
        },
        TypeGastronomy: {
          url: "../assets/TypeGastronomy.svg",
          contentType: "image/svg+xml",
          file: asset_TypeGastronomy,
        },
        TypeHistory: {
          url: "../assets/TypeHistory.svg",
          contentType: "image/svg+xml",
          file: asset_TypeHistory,
        },
        TypeKids: {
          url: "../assets/TypeKids.svg",
          contentType: "image/svg+xml",
          file: asset_TypeKids,
        },
        TypeLegend: {
          url: "../assets/TypeLegend.svg",
          contentType: "image/svg+xml",
          file: asset_TypeLegend,
        },
        TypeMythology: {
          url: "../assets/TypeMythology.svg",
          contentType: "image/svg+xml",
          file: asset_TypeMythology,
        },
        TypeNature: {
          url: "../assets/TypeNature.svg",
          contentType: "image/svg+xml",
          file: asset_TypeNature,
        },
        TypeNightlife: {
          url: "../assets/TypeNightlife.svg",
          contentType: "image/svg+xml",
          file: asset_TypeNightlife,
        },
        TypeQuirky: {
          url: "../assets/TypeQuirky.svg",
          contentType: "image/svg+xml",
          file: asset_TypeQuirky,
        },
        TypeQuiz: {
          url: "../assets/TypeQuiz.svg",
          contentType: "image/svg+xml",
          file: asset_TypeQuiz,
        },
        TypeSelfie: {
          url: "../assets/TypeSelfie.svg",
          contentType: "image/svg+xml",
          file: asset_TypeSelfie,
        },
        TypeShopping: {
          url: "../assets/TypeShopping.svg",
          contentType: "image/svg+xml",
          file: asset_TypeShopping,
        },
        TypeVirtualtour: {
          url: "../assets/TypeVirtualtour.svg",
          contentType: "image/svg+xml",
          file: asset_TypeVirtualtour,
        },
        more_icon: {
          url: "../assets/more_icon.svg",
          contentType: "image/svg+xml",
          file: asset_more_icon,
        },
      },
      languages: ["en", "el", "en"],
      globalStyles: {
      },
      messages: {
        en: {
          tour_authorTitle: "Author:",
          tour_author_subtitle: "Tour's Author",
          tour_startingPoint: "Starting point",
          tour_pointsOfInterest: "Points of interest",
          tour_start_text: "Start your tour now",
          tour_sponsor_description: "Description",
          signUp_title: "Create your account",
          signUp_subtitle: "Login to continue",
          email: "Email",
          password: "Password",
          signUp_agree_text: "I agree with",
          signUp_privacy_policy: "Privacy Policy",
          signUp_and_text: "and",
          signUp_terms_text: "Terms of Use",
          signUp_signup_text: "Sign up",
          signUp_have_account_text: "Already have an account?",
          signUp_login_text: "Log in",
          login_title: "Hello",
          login_subtitle: "Login to continue",
          login_forgot_text: "Forgot password?",
          login_skip_text: "Skip it?",
          login_login_text: "Log in",
          login_no_account_text: "Don't have an account?",
          login_signup_text: "Sign up now",
          items_map_text: "Go to map",
          playerControl_multimedia_title: "Multimedia",
          playerControl_next_stops_title: "Jump to next stops",
          storyText_more_text: "More",
          loader_ok_button_text: "OK",
        },
        el: {
          tour_authorTitle: "Author:",
          tour_author_subtitle: "Tour's Author",
          tour_startingPoint: "Starting point",
          tour_pointsOfInterest: "Points of interest",
          tour_start_text: "Start your tour now",
          tour_sponsor_description: "Description",
          signUp_title: "Create your account",
          signUp_subtitle: "Login to continue",
          email: "Email",
          password: "Password",
          signUp_agree_text: "I agree with",
          signUp_privacy_policy: "Privacy Policy",
          signUp_and_text: "and",
          signUp_terms_text: "Terms of Use",
          signUp_signup_text: "Sign up",
          signUp_have_account_text: "Already have an account?",
          signUp_login_text: "Log in",
          login_title: "Hello",
          login_subtitle: "Login to continue",
          login_forgot_text: "Forgot password?",
          login_skip_text: "Skip it?",
          login_login_text: "Log in",
          login_no_account_text: "Don't have an account?",
          login_signup_text: "Sign up now",
          items_map_text: "Go to map",
          playerControl_multimedia_title: "Multimedia",
          playerControl_next_stops_title: "Jump to next stops",
          storyText_more_text: "More",
          loader_ok_button_text: "OK",
        },
      },
      test240: {
        is_featured: true,
        isIndoors: false,
        showRoute: false,
        id: 240,
        langId: 2,
        name: "Acropolis Classic",
        description:
          "There is a ritual associated with a visit to Athens. First, you enjoy a souvlaki and an authentic Greek salad. And then, as soon as possible, you climb to the Acropolis. There are many superlatives to describe it and it may often feel too familiar or humdrum. But look closely, and you will discover a universe of stories hiding in plain sight.",
        address: "",
        openHours: "",
        admission: "",
        audioStatus: 3,
        imageFile: "tour_img_1533024926.jpg",
        thumbFile: "tour_thumb_1533024926.jpg",
        groundImageFile: "tour_ground_img_1568810611.jpg",
        type_id: 2,
        value_id: 2,
        lat: "37.97164303641",
        lon: "23.72612265522",
        author: "Clio Muse",
        authorDescription:
          "Award-winning creators of a unique, innovative platform enabling accredited professionals to design self-guided audio tours, based on an original and enticing storytelling concept.",
        telephone: "+302103006246",
        email: "bookings@cliomusetours.com",
        website: "https://www.cliomusetours.com",
        authorImage: "tour_logo_1555315940.jpg",
        hasSponsor: 0,
        sponsorImage: null,
        sponsorWebsite: "",
        sponsor: "",
        sponsorTitle: "",
        contributors: "Clio Muse",
        minZoom: 14,
        maxZoom: 19,
        starting_point_name: "",
        starting_point_address: "",
        finishing_point_name: null,
        finishing_point_address: null,
        mapStyle: null,
        items: [
          {
            id: 2697,
            langId: 2,
            index: 1,
            imageFile: "tour_item_img_1568747238.jpg",
            thumbFile: "tour_item_thumb_1568747238.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1568747238.jpg",
            lon: "23.729305",
            lat: "37.96962",
            name: "Entrance",
            secret:
              "Entrance to the site: Side Acropolis entrance from the south-east slopes of the hill  (Thrasillou, Athens, 105 58)",
            stories: [
              {
                id: 8771,
                langId: 2,
                sequence_id: null,
                imageFile: "story_img_1568747525.jpg",
                videoFile: "",
                title: "How to get there",
                body: "The Acropolis of Athens is visible just about anywhere in the city and the easiest way to get there is from the Acropolis metro station (Red Line). You will take the exit toward Makrigianni street and walk up the road until you reach Dionysiou Areopagitou, the pedestrianized street that runs below the Acropolis hill. You’ll turn left onto Dionysiou Areopagitou and then immediately turn right to walk up a short flight of stairs. On your left, you will see the South Slopes entrance. Although this is not the main entrance to the Acropolis, it is preferable to enter the site from this gate as you will get the opportunity to see and explore more attractions that you would otherwise miss, namely the theatre of Dionysus, the Asklepieion and the Odeon of Herodes of Atticus (view from above). Skip the main ticket line and follow the expedited line to enter the site.",
                audioFile: "story_audio_EN_1568872926.mp3",
                duration: 67056,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 8772,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "Introduction",
                body: "Welcome to the Acropolis of Athens! The word Acropolis consists of two Greek words: Akron, meaning edge, and polis, which means city. In ancient times most city-states had their own acropolis but this particular acropolis was immortalized and is today so famous because it was the acropolis of a superpower! The 5th century BC was a great time to be an Athenian! After the defeat of the Persians in 479 BC, a new era of prosperity dawned for Athens which, under the leadership of the famed statesman and orator Pericles, became the unchallenged master of the Delian league and the most powerful city-state of the then known Greek world. With money flowing from the common treasury of the Delian League, the state embarked on a grand architectural project to reconstruct the Acropolis, which had suffered severe damages during the Persian wars. Most of the monuments you will admire over the next two hours were constructed between 447 and 406 BC. You will set out to explore, first, the south slopes and then gradually ascend to the sacred rock, upon which artifacts of timeless beauty will bring to life the glorious days of the Athenian hegemony!",
                audioFile: "story_audio_EN_1568872956.mp3",
                duration: 83040,
                category: {
                  type: "Info",
                  values: ["Introduction"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2698,
            langId: 2,
            index: 2,
            imageFile: "tour_item_img_1568746810.jpg",
            thumbFile: "tour_item_thumb_1568746810.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1568746810.jpg",
            lon: "23.727792",
            lat: "37.970371",
            name: "Theatre of Dionysus",
            secret:
              "Name an ancient tragedy you were taught at school and it’s been performed right here, in the oldest theatre in the world!",
            stories: [
              {
                id: 8773,
                langId: 2,
                sequence_id: null,
                imageFile: "story_img_1568747540.jpg",
                videoFile: "",
                title: "Directions",
                body: "Upon entering the site, follow the main ramp and turn right. You will walk past a canopy, which is home to members from the ancient theatre and Roman sculptures that once decorated its stage, and, at the end of the path, you will turn left to find on your right hand the first monumental complex we encounter in the archaeological area of the South Slopes, the sanctuary and theatre of Dionysus Elefthereus.",
                audioFile: "story_audio_EN_1568872976.mp3",
                duration: 31056,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
              },
              {
                id: 8774,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "The birth of tragedy",
                body: "Theatre didn’t start out in one day. Its roots are traced in primordial ritual traditions, in which the worshippers reenacted mythical episodes from the lives of their gods so as to receive divine blessing. But in the second half of the 6th century BC, a major step forward would pave the way for theatre as we know it today. And guess what! It all took place here in Attica, the birth land of tragedy. It was the invention of a man called Thespis, believed to be the world's first actor. He was a singer of dithyrambs, hymns in honor of the god Dionysus, which were sung by a chorus of up to 50 men. Thespis came up with the idea of enriching this choral song by introducing a protagonist holding the role of an individual character. Dialogue became possible between the actor and the chorus and, thus, tragedy was born! In fact, Thespis presented in this very theatre his first tragedy in 534 BC!",
                audioFile: "story_audio_EN_1568873007.mp3",
                duration: 70032,
                category: {
                  type: "Themes",
                  values: ["History", "Archeology", "Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 8775,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "The world’s first theatre",
                body: "Now let’s examine the theatre of Dionysus, the world’s oldest theatre! Designed to seat up to 17.000 people, today only the lower rows of its stone-made seating area, the koilon, have been preserved. The front- row thrones are made of marble as they were seats of honor, with the central one being dedicated to the priest of Dionysus. The tier is divided into 13 bleachers, so as to host the 10 Athenian tribes, the executives of the boule, the teenage boys and foreign visitors. All seats look out on the orchestra, the circular space at the center. It was designed for the most vital body of the tragedy, the chorus, who would there stand, sing and dance. Originally, it was a surface of beaten earth and was later paved during Roman times. The low marble wall along the perimeter was also added over that period to protect the audience as the Romans would stage here gladiator games and wild animal fights! The actors would appear on the stage, the skene, which is now lost. Finally, let’s have a look at the raised wall behind the orchestra. Built in the 3rd century AD, the Bema of Phaedrus was a platform for orators to present their speeches. Its interior side, facing the seating area, is decorated with the myths of Dionysus. But who was this playful god? Listen to the next story to find out!",
                audioFile: "story_audio_EN_1568873029.mp3",
                duration: 95520,
                category: {
                  type: "Themes",
                  values: ["History", "Architecture", "Archeology"],
                },
                difficulty: "Easy",
              },
              {
                id: 8776,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "The appearance of a new god",
                body: "Dionysus had a very unusual background as indicated by his name which means Born twice. He was the offspring of Zeus’s illicit affair with princess Semele, who spent together many sleepless nights until the girl was made pregnant. Tricked by Hera, whose plotting mind was set to destroy her, Semele was advised to ask proof of her lover’s divinity. She made him promise on the River Styx to grant her a favor and then asked him to reveal himself in all his divine splendor. Forced by his oath to comply, Zeus did unwillingly as she wished and appeared before her bearing his deadly bolts, but, as one might expect, the moment he saw him Semele, intoxicated by the formidable sight, died in his arms. Yet, the baby was still alive, so Zeus rescued it by sewing it into his thigh and kept it safe until it was born a couple of months later. Baby Dionysus was raised by nymphs and when he grew up he claimed his rightful place among the Olympian gods.",
                audioFile: "story_audio_EN_1568873052.mp3",
                duration: 72048,
                category: {
                  type: "Themes",
                  values: ["Mythology", "Quirky"],
                },
                difficulty: "Easy",
              },
              {
                id: 8777,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "The theatre games",
                body: "The cult of Dionysus arrived in Athens during the rule of Peisistratus, the 6th BC tyrant who founded the sanctuary of Dionysius and established the City Dionysia festival. These were theatre games that took place every year, at the beginning of spring. The celebration opened with a joyous procession. The participants marched carrying a wooden statue of Dionysus and phalluses in different sizes, made of various materials. The celebration was, as one might expect, fun and wine galore! The next morning the writers presented their plays and the judges were announced. The performances would last for five days. The first three were reserved for the solemn tragedies and during the other two days, dithyrambic contests took place, while from 487 and on comedies were also included in the repertoire. Despite being founded by a tyrant the festival became the foremost pillar of Athenian democracy as the theatre was an important spiritual and educational institution based on the notion of co-participation. No matter their wealth, all citizens were encouraged to attend, while for the rich it was considered an honor to sponsor the performances through an official public service known as choregia.",
                audioFile: "story_audio_EN_1568873073.mp3",
                duration: 85584,
                category: {
                  type: "Themes",
                  values: ["History", "Fun"],
                },
                difficulty: "Mild",
              },
              {
                id: 8778,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "And the Oscar goes to…",
                body: "The three great tragedians, Aeschylus, Sophocles and Euripides, all presented their plays here during the City Dionysia. Aeschylus is said to have won the first prize 13 times, Sophocles 18 times and Euripides is believed to have had 5 wins. Each of them belongs to a different generation and represents a different phase in the evolution of the Attic tragedy. Poor Euripides, who was the youngest, was fiercely mocked for his modernizing innovations while Aeschylus with his solemn and lofty verses was considered to be the pinnacle of the tragic genre. This is evident in Aristophanes’ comedy The Frogs, in which god Dionysus travels to the Underworld to return to life the Best Tragic Poet. A contest is held between the two deceased writers, Aeschylus and Euripides, and the winner is eventually Aeschylus, who is allowed to return to life in order to save Athens from the despairing state of the living tragedians!",
                audioFile: "story_audio_EN_1568873096.mp3",
                duration: 69024,
                category: {
                  type: "Themes",
                  values: ["History", "Art"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2699,
            langId: 2,
            index: 3,
            imageFile: "tour_item_img_1568746366.jpg",
            thumbFile: "tour_item_thumb_1568746366.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1568746366.jpg",
            lon: "23.726843",
            lat: "37.970442",
            name: "Stoa of Eumenes",
            secret:
              "Open air performances are delightful when the weather is sunny and bright. Sometimes though, clouds appeared in the sky and the people had to seek for a roofed shelter.",
            stories: [
              {
                id: 8779,
                langId: 2,
                sequence_id: null,
                imageFile: "story_img_1568747557.jpg",
                videoFile: "",
                title: "Directions",
                body: "Continue straight on the path that runs along the Theatre of Dionysus and, once you’ve reached its end, turn right to walk up a hilly paved footway. At the end of the paved footway, you will see on your left the remains of the Stoa of Eumenes.",
                audioFile: "story_audio_EN_1568873116.mp3",
                duration: 19584,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 8780,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "How to make it through the rain",
                body: "Where did the people find shelter when the weather was bad? Placed between the Theatre and the Asklepieion, the Stoa of Eumenes was an elongated structure 163 meters long, built on two floors. On the ground floor, its portico was supported by an outer row of 64 Doric columns while the interior was adorned with 32 columns of the so-called Pergamene order, a style that was in vogue during the period of the Attalid dynasty. The stoa connected the theater to the ancient Peripatos, the path that environs the Acropolis Hill and served as a shelter from rain and sun to the audience during the interval. It was constructed during the rule of Eumenes II, who was responsible for the construction of many public buildings and is pretty much similar in its style and architecture to the Stoa of Attalos, built by his brother, which is located in the archaeological site of the ancient Agora. Unlike the other stoa, though, this venue was not intended for shopping and business.",
                audioFile: "story_audio_EN_1568873135.mp3",
                duration: 69552,
                category: {
                  type: "Themes",
                  values: ["History", "Architecture", "Archeology"],
                },
                difficulty: "Mild",
              },
              {
                id: 8781,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "The loving brother",
                body: "Eumenes II and Attalus II were sons of the great king Attalus I and have gone down in history as the Attalid dynasty, a powerful family who ruled the city of Pergamon in Asia Minor during the Hellenistic Age. Eumenes, the elder brother, was a bright personality and understood well that in order to establish Pergamum as a center of power in the East he had to maintain friendly relationships with Rome. During his reign, he collaborated with the Romans to fight against the Seleucid and Macedonian kingdoms, but, under the suspicion that he had his own secret agenda, Eumenes fell out of favor with the Romans. Thus, they offered Attalus II assistance to overthrow the king, but faithful to the bond of blood, the younger brother declined, earning the nickname Philadelphus among historians, meaning brother-loving. When Eumenes died, Attalus II ascended the throne as regent, married his brother’s widowed wife and raised his young son.",
                audioFile: "story_audio_EN_1568873153.mp3",
                duration: 68544,
                category: {
                  type: "Themes",
                  values: ["History", "Quirky"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2700,
            langId: 2,
            index: 4,
            imageFile: "tour_item_img_1568746638.jpg",
            thumbFile: "tour_item_thumb_1568746638.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1568746638.jpg",
            lon: "23.726681",
            lat: "37.970756",
            name: "Asklepieion",
            secret:
              "Who said there were no hospitals in ancient times? The healing sanctuaries, known as Asclepieia, were visited by pilgrims who sought spiritual and physical healing.",
            stories: [
              {
                id: 8782,
                langId: 2,
                sequence_id: null,
                imageFile: "story_img_1568747576.jpg",
                videoFile: "",
                title: "Directions",
                body: "Leaving behind you the Stoa of Eumenes, you will turn left onto a narrow dirt track and briefly after you will see on your right hand a short flight of stairs leading to the temple of Asclepios. Find a place in the shade of a tree to listen to the stories of the second point of interest.",
                audioFile: "story_audio_EN_1568873171.mp3",
                duration: 22032,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 8783,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "From life to eternity",
                body: "The Asklepieion is dedicated to the god of Medicine, Asclepios. Asclepios was initially a mortal and started out his career as a doctor. His life, as is often the case in Greek mythology, was rather complicated. He was the son of Apollo and Coronis, whose love story went wrong when Coronis betrayed the mighty god and married a mortal. Driven by his fury, Apollo punished his ex-lover by throwing her onto a pyre but as she burned she gave birth to their son. What could he do but rescue the poor boy? He handed his son to the Centaur Chiron to raise and by him, Asclepios was taught the art of medicine. When he grew up he was given the blood of the Gorgon by goddess Athena, a very special gift, for it had the power to cure all illnesses and bring the dead back to life. Yet, Zeus did not like the prospect of humans becoming independent and for this reason, he killed Asclepios with his unmissable thunderbolt. The people loved him, however, and started worshipping him as a god.",
                audioFile: "story_audio_EN_1568873192.mp3",
                duration: 74544,
                category: {
                  type: "Themes",
                  values: ["Mythology", "Quirky"],
                },
                difficulty: "Easy",
              },
              {
                id: 8784,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "The arrival of the god of medicine",
                body: "The year was 430 BC. Athens was already involved in a terrible war against Sparta and its allies and a bleak period for the city was about to begin. Struck by the plague, which gradually spread all across their city, the Athenians would be dying one after the other for the next three years. The people felt that the gods had abandoned them. And perhaps they had, but there was one god that was now needed more than ever before, Asclepios. Just a decade after the outbreak of the deadly disease, which brought about the death of an estimated 25% of the city’s population, the cult of Asclepios arrives in Athens. Like all gods, he needs a home and his sanctuary is established here on the south slope of the Acropolis.",
                audioFile: "story_audio_EN_1568873212.mp3",
                duration: 54048,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Easy",
              },
              {
                id: 8785,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "An ancient hospital",
                body: "The temple had two main uses: sanctuary and healing center. It was pretty much a hospital of ancient times housing dream incubation chambers, where patients would sleep. The god would appear in their dreams and heal them. Patients could then stay in a guest house until the end of their treatment. Inside the temple stood the cult statues of Asclepios and Hygeia and the sacred altar, while water would flow from a sacred spring inside the Temple court. Building a hospital next to a theatre wasn’t a random choice at all. In the value system of the ancient Greeks, the well-being of the body and a healthy mind were notions inextricably intertwined. As Aristotle has suggested in his Poetics, attending a tragedy was a deeply therapeutic process leading the audience to a state of catharsis. Thus, it shouldn’t surprise us that archaeologists have found the majority of theatres to be adjacent to healing sanctuaries. In fact, the most significant Asklepieion is located next to the theatre of Epidaurus in the Peloponnese.",
                audioFile: "story_audio_EN_1568873243.mp3",
                duration: 74496,
                category: {
                  type: "Themes",
                  values: ["History", "Architecture"],
                },
                difficulty: "Easy",
              },
              {
                id: 8786,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "Tip",
                body: "To learn more about the cult Asclepios, visit the Acropolis Museum, where marble votive sculptures serving as offerings to the god are exhibited. They commonly depicted body parts healed by the god or scenes of worshippers approaching Asclepios and his family. Today one can see the votive offering of Praxias, an impressive column in whose chiseled niche rests a marble face with inlaid eyes. It was dedicated to Asclepios after the healing of his wife’s eyes.",
                audioFile: "story_audio_EN_1568873267.mp3",
                duration: 34560,
                category: {
                  type: "Tips",
                  values: ["Alternative activity"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2701,
            langId: 2,
            index: 5,
            imageFile: "tour_item_img_1568746721.jpg",
            thumbFile: "tour_item_thumb_1568746721.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1568746721.jpg",
            lon: "23.724687",
            lat: "37.971133",
            name: "Odeon of Herodes Atticus",
            secret:
              "A memorial gift is an enduring tribute to a loved person. Thousands of years after her death, the memory of Aspasia Annia Regilla is still alive in the stones of this Roman Odeon.",
            stories: [
              {
                id: 8787,
                langId: 2,
                sequence_id: null,
                imageFile: "story_img_1568747595.jpg",
                videoFile: "",
                title: "Directions",
                body: "Now walk down the stairs you climbed to reach the temple of Asclepius and turn right to continue your stroll. You will walk along the path until you end in front of a water basin that was once a Byzantine Cistern. You will turn right and then immediately to the left to follow the footway that will lead you to the viewpoint from where you will be able to gaze out on the Odeon of Herodes of Atticus. You will see in front of you a metallic fence, below which the Roman Odeon rests.",
                audioFile: "story_audio_EN_1568873289.mp3",
                duration: 37584,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 8788,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "Roman with a Greek twist",
                body: "The Odeon of Herodes Atticus was the last public edifice that was built on the slopes of the Acropolis. It was intended for, mainly, musical events and could accommodate approximately 5000 people. Constructed in 161 AD, at a time when Athens was a province of the Roman Empire, its shape is semicircular, typical of the odeons of the imperial age. However, the construction does not entirely follow the Roman model, as it was not built on flat ground, but carved on the rock of the Acropolis like a Greek theatre. Therefore no arches and vaults were needed to support the cavea. Facing the skene, which is preserved today to a height of 28 meters, we do notice, though, the evolution of style, from the orthogonal simplicity ascribed to Greek classicism to the dynamic use of curves prevalent in Roman architecture.",
                audioFile: "story_audio_EN_1568873313.mp3",
                duration: 62544,
                category: {
                  type: "Themes",
                  values: ["Architecture", "Archeology"],
                },
                difficulty: "Mild",
              },
              {
                id: 8789,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "The roof",
                body: "Although today we have no signs of it anymore, we have to imagine the stunning structure to be covered by a wooden roof, made of expensive cedar from Lebanon. The roof of the Odeon must have been a major breakthrough as it seems that it didn’t have any vertical support. This is quite admirable, even for our times, if we consider the size of its surface. Perhaps this is also the reason why the Odeon was constructed with extremely thick walls so as to support the enormous weight of the wooden roof. ",
                audioFile: "story_audio_EN_1568873339.mp3",
                duration: 37056,
                category: {
                  type: "Themes",
                  values: ["Architecture"],
                },
                difficulty: "Easy",
              },
              {
                id: 8790,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "Death is not the end",
                body: "The amphitheater is known as the Odeon of Herodes Atticus since it was built by the Athenian magnate Herodes, a distinguished rich nobleman and a personal friend of Emperor Antonius Pius. While living in Rome, where he was invited by the Emperor himself to educate his sons and was appointed a Roman consul, he met Aspasia Annia Regilla. She was a member of an aristocratic and very wealthy Roman family and, although he was in his forties and she was only 14, the two of them seemed to be quite fitting. They got married and after some years moved with their children to Greece, where they used all their wealth and influence to build monumental structures and be honored by the Greek society as patrons of the arts and letters. When Regilla died, struck by grief, Herodes erected this grand Odeon in her memory.",
                audioFile: "story_audio_EN_1568873360.mp3",
                duration: 61008,
                category: {
                  type: "Themes",
                  values: ["History", "Fun"],
                },
                difficulty: "Mild",
              },
              {
                id: 8791,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "Tip",
                body: "There are no words to describe the feeling of awe one experiences while attending a performance at the stunning Odeon of Herodes Atticus. Since 1920 when it returned to use, some of the greatest names from the international music scene have performed here, including Maria Callas, Plácido Domingo, José Carreras, Montserrat Caballé and Patti Smith among many, many others! It is one of the main venues of the Athens Festival every summer. Why not book a ticket?",
                audioFile: "story_audio_EN_1568873381.mp3",
                duration: 37104,
                category: {
                  type: "Tips",
                  values: ["Alternative activity"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2013,
            langId: 2,
            index: 6,
            imageFile: "tour_item_img_1533024989.jpg",
            thumbFile: "tour_item_thumb_1533024989.jpg",
            thumb_landscape_filename: null,
            lon: "23.724778",
            lat: "37.971633",
            name: "The great staircase",
            secret:
              "Right from the get-go the Acropolis offers stories and monuments and lessons in art, strategy, and politics. The path may be slippery but at least you won’t be greeted by a shower of lethal missiles. ",
            stories: [
              {
                id: 8792,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1568747856.jpg",
                videoFile: "",
                title: "Directions",
                body: "Walk up the wooden stairway that is attached to the fence and follow the path that will lead you to a short flight of stairs carved on the rock. You will turn right and walk upward along the main cobbled path. Turn left to climb a few more stairs and then continue to the left to find the great central stairway that will lead you to the top of the Acropolis Hill. Be careful and watch your steps as the ground becomes very slippery from here on.",
                audioFile: "story_audio_EN_1568757995.mp3",
                duration: 30048,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 6711,
                langId: 2,
                sequence_id: 2,
                imageFile: "story_img_1568747612.jpg",
                videoFile: "",
                title: "The ramp of Claudius",
                body: "Access to the Acropolis was always easiest from the west and this has always been the main way to the top of the rock. The earliest trail was narrow and winding, allowing its users to observe the ever-changing scenery as they climbed the hill. This path was supported by successive terraces. During the reign of the emperor Claudius (1st century AD) a huge ramp replaced the winding trail. It began in the flat plateau at the foot of the Hill of Ares (near where the present-day ticket booth is) and ended at the Propylaea. The ramp allowed animals to reach the Acropolis, while monumental marble staircases on either side of the ramp were reserved for human traffic.    ",
                audioFile: "story_audio_English_1533024797.mp3",
                duration: 49368,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Easy",
              },
              {
                id: 6712,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "The right bastion",
                body: "As you climb towards the Acropolis you will notice a bastion on your right (atop of which stands the small temple of Athena Nike). There is no corresponding bastion on your left but this is as it should be. This bastion is a reminder of the defensive role of the Acropolis. After all this was a castle and a refuge for the people of Athens in times of danger. Ancient Greeks always fought holding a shield with their left hand and a spear with their right. This means that the right side of any attacker was exposed to missiles hurled by the defender, while the left side was well-protected behind the shield. This bastion allowed the Athenians to attack their enemies with lethal force long before they could hope to reach the gates of the Acropolis.  ",
                audioFile: "story_audio_English_1533024812.mp3",
                duration: 52584,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Easy",
              },
              {
                id: 6713,
                langId: 2,
                sequence_id: 4,
                imageFile: null,
                videoFile: "",
                title: "Windows to the past",
                body: "The bastion is actually a rock that was covered in a sheath of limestone during the 420s BCE, when this part of the Acropolis was reorganized in the spirit of the major works that transformed the rock under the guidance of the great Athenian statesman, Pericles. Near the base of the bastion’s west face there are two rectangular niches (you may have noticed them on the way up here) that were deliberately left open to reveal the bastion’s Mycenaean core within. This was a reminder of the deep and sacred past of the Acropolis, a site occupied for hundreds of years before Pericles decided to endow it with the classical buildings we see today. It is possible that altars or statues once occupied this two niches. ",
                audioFile: "story_audio_English_1533024829.mp3",
                duration: 51768,
                category: {
                  type: "Themes",
                  values: ["History", "Archeology"],
                },
                difficulty: "Easy",
              },
              {
                id: 6714,
                langId: 2,
                sequence_id: 5,
                imageFile: null,
                videoFile: "",
                title: "Shameless politicking",
                body: "Across from the bastion, on your left, is a pedestal, ten meters tall, made with marble from Mount Hymettus. This pedestal was built in 178 BCE in honour of King Eumenes II of Pergamon, who had won the chariot race in the Panathenaic Games that year. Atop the pedestal was a bronze four-horse chariot driven by Eumenes and his brother Attalos. In 27 BCE though, the people of Athens replaced this chariot with another one and dedicated the statue to Marcus Agrippa, son-in-law of Augustus, the first Roman Emperor. This was an expression of gratitude for the odeon he built in the Agora. There is an inscription on the west side of the pedestal commemorating Agrippa as a benefactor, while the earlier inscription which referred to Eumenes was erased.  ",
                audioFile: "story_audio_English_1533024849.mp3",
                duration: 58176,
                category: {
                  type: "Themes",
                  values: ["History", "Quirky", "Fun"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2014,
            langId: 2,
            index: 7,
            imageFile: "tour_item_img_1533025002.jpg",
            thumbFile: "tour_item_thumb_1533025002.jpg",
            thumb_landscape_filename: null,
            lon: "23.724898",
            lat: "37.971509",
            name: "The temple of Athena Nike",
            secret:
              "One of the most elegant monuments on the Acropolis was built in the midst of war and adorned in the thrill of victory to celebrate a goddess who was brazen enough to steal someone else’s identity.  ",
            stories: [
              {
                id: 8793,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1568747967.jpg",
                videoFile: "",
                title: "Directions",
                body: "Climb up the stairs and follow the ramp heading to the left side of the great stairway. This is a good viewpoint from where you can marvel at the famed temple of Athena Nike. Facing the majestic entranceway, the temple is perched on your right, on the southwest wing of the Propylaea. It is an Ionic tetrastyle with a colonnaded portico on its front and rear facade.",
                audioFile: "story_audio_EN_1568757971.mp3",
                duration: 28032,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 6715,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The bizarre staircase",
                body: "The temple of Athena Nike is an Ionic structure with four columns in front and four in back. It is set against the western edge of the bastion and has some of the more characteristic features of the Parthenon (the use of Pentelic marble, the long walls lean inwards and the columns lean back slightly). There is an altar to the east of the temple (towards the Parthenon) and the entire precinct is paved in marble. Access to the temple was either through the south wing of the Propylaea or up the small stairway you can see on the wall above the ramp you have been using to climb towards the Propylaea. The stairway is currently far above the level of the ramp, but in the time of Pericles the ground level was much higher.",
                audioFile: "story_audio_English_1533024875.mp3",
                duration: 49032,
                category: {
                  type: "Themes",
                  values: ["Architecture", "Archeology"],
                },
                difficulty: "Easy",
              },
              {
                id: 6716,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "Victories everywhere",
                body: "The temple’s architect is unknown, but he may have been Mnesikles. The temple’s construction probably began after the completion of work on the Propylaea, so it should be dated in 427-424 BCE. A marble parapet surrounded the temple and ensured that no visitor would fall off the edge. The parapet consisted of slabs that were one meter tall and were adorned on their exterior with sculptures that depicted a parade of Victories (Nikes). They erect victory trophies, they offer sacrifices, and give presents to the goddess that appears seated on the end of each side. The sense of triumph suggests a carve date in the years after 410 BCE when the Athenians scored numerous decisive victories in naval battles against the Spartans.\r\n \r\n",
                audioFile: "story_audio_English_1533024891.mp3",
                duration: 56568,
                category: {
                  type: "Themes",
                  values: ["History", "Archeology"],
                },
                difficulty: "Easy",
              },
              {
                id: 6717,
                langId: 2,
                sequence_id: 4,
                imageFile: "story_img_1568791273.jpg",
                videoFile: "",
                title: "Contemporary history",
                body: "The temple of Athena Nike was adorned with its own frieze. On the east side we have a gathering of the gods around Zeus, who sits on his throne, and goddess Athena. They both occupy the center of the frieze. But on the other three sides, the unknown artist decided to depict a historical event, something rather unusual for an ancient Greek temple. We can see violent battle scenes with Greeks fighting against the Persians but also scenes where Greeks fight against other Greeks. This seems to indicate that the artist drew inspiration from the battle of Plataea, the last major battle fought on Greek soil against the Persians in 479 BCE. As for the Greeks depicted, they must be the Boeotians who supported the Persians.  ",
                audioFile: "story_audio_English_1533024908.mp3",
                duration: 54072,
                category: {
                  type: "Themes",
                  values: ["History", "Archeology"],
                },
                difficulty: "Easy",
              },
              {
                id: 6718,
                langId: 2,
                sequence_id: 5,
                imageFile: null,
                videoFile: "",
                title: "Clipped wings",
                body: "Nike was the winged goddess who personified victory. This is not the goddess worshipped here. The wooden cult statue housed in the temple depicted Athena holding a pomegranate (symbol of fertility) on her right hand and her helmet on the left hand. It is clear that Athena had substituted a much older cult honoring Nike. The Athenians also claimed that her statue lacked wings so that victory would never leave their city. This bizarre (and perhaps naïve) belief was not singularly Athenian. In fact, the Spartans (perennial mortal enemies of Athens) also had a statue of a war god whose legs were tied together to prevent him from running away from Sparta.    ",
                audioFile: "story_audio_English_1533024926.mp3",
                duration: 50112,
                category: {
                  type: "Themes",
                  values: ["History", "Archeology", "Quirky"],
                },
                difficulty: "Easy",
              },
              {
                id: 6719,
                langId: 2,
                sequence_id: 6,
                imageFile: null,
                videoFile: "",
                title: "Black sails",
                body: "The temple of Athena Nike occupies a prominent position on the Acropolis from where it is possible to keep an eye on the sea, as well as the nearby islands and the distant mountains of the Peloponnese. Aegeus, the king of Athens, used to come here and scan the horizon waiting for the return of his son, Theseus, from Crete, where he had gone there to kill the Minotaur. His ship left Athens with black sails, but his father had told him to put up white sails if he came back alive. Unfortunately, Theseus forgot to change them and when Aegeus saw the black sails coming into Athens, he killed himself by jumping from the bastion. ",
                audioFile: "story_audio_English_1533024945.mp3",
                duration: 46152,
                category: {
                  type: "Themes",
                  values: ["Mythology", "Quirky"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2015,
            langId: 2,
            index: 8,
            imageFile: "tour_item_img_1533025012.jpg",
            thumbFile: "tour_item_thumb_1533025012.jpg",
            thumb_landscape_filename: null,
            lon: "23.725154",
            lat: "37.971711",
            name: "The Propylaea",
            secret:
              "What did the Parthenon mean to the ancient Greeks? Well, not very much for there are only a handful of references to it. Not so with the Propylaea.",
            stories: [
              {
                id: 8794,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1568748059.jpg",
                videoFile: "",
                title: "Directions",
                body: "Climb up the last part of the ramp and the stairway. Having a mesmerizing view of the city behind you and another flight of wooden stairs passing through the Propylaea in front of you, you can stand here to listen to the next set of stories about one of the most imposing landmarks of the Acropolis.",
                audioFile: "story_audio_EN_1568758010.mp3",
                duration: 22032,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 6720,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Absolute necessities",
                body: "Ancient Greeks loved to make a grand entrance. Literally. Their sanctuaries were almost invariably surrounded by a wall that identified and set apart a piece of sacred land. Such a wall should have an entrance. Frequently this entrance required some protection from the sun and the rain, so people placed tree-trunks on each side of the door to support the roof. Eventually the wooden posts were replaced by stone columns and the roof became an elaborate piece of masonry richly adorned. The love of the ancient Greeks for these porches could assume ridiculous proportions. There are examples of porches in sanctuaries where there was no wall and no temple!    \r\n \r\n",
                audioFile: "story_audio_English_1533024970.mp3",
                duration: 50088,
                category: {
                  type: "Themes",
                  values: ["Architecture", "Fun"],
                },
                difficulty: "Easy",
              },
              {
                id: 6721,
                langId: 2,
                sequence_id: 3,
                imageFile: "story_img_1568786585.jpg",
                videoFile: "",
                title: "The paint gallery",
                body: "Pericles was determined to endow the Acropolis with a magnificent new entrance. He entrusted the architect Mnesikles with the task and work began in 437 BCE, before all the sculptures for the Parthenon had been completed. His plan envisioned a great main entrance and two wings to the left and right of the central building. The left wing served as dining room for ritual feasts (there was space for seventeen couches) and as a picture gallery where portable panel paintings were provided for the pleasure of visitors. Some of the paintings included two mythological scenes by the great painter Polygnotos, and a portrait of Alkibiades, the beautiful young man who made Socrates cry from love…and then ruined Athens with his reckless political decisions.  ",
                audioFile: "story_audio_English_1533024986.mp3",
                duration: 57072,
                category: {
                  type: "Themes",
                  values: ["History", "Art", "Quirky"],
                },
                difficulty: "Mild",
              },
              {
                id: 6722,
                langId: 2,
                sequence_id: 4,
                imageFile: null,
                videoFile: "",
                title: "The objection of the priests",
                body: "The right wing is much smaller and was designed to make the structure symmetrical. It served as an access route to the temple of Athena Nike, as well as a waiting area. It is also conceivable that plans for a larger wing encountered the opposition of the priesthood, who would not have approved of a design that would have reduced the area of the sanctuaries of Athena Nike or Artemis, both of which were located next to the Propylaea. In any case, as work was progressing, Athens found itself embroiled in the Peloponnesian War against Sparta. Beginning in 431 BCE the resources of the city were directed towards the war effort and all work on the Acropolis (and the Propylaea) stopped.  ",
                audioFile: "story_audio_English_1533025010.mp3",
                duration: 51072,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Easy",
              },
              {
                id: 6723,
                langId: 2,
                sequence_id: 5,
                imageFile: null,
                videoFile: "",
                title: "Propylaea vs the Parthenon: 1-0",
                body: "In his celebration of Athens at the time of Pericles, the 4th-century orator Demosthenes lists the Propylaea first and the Parthenon second. In fact, this magnificent entrance to the Acropolis was widely believed to be the most memorable building of classical Athens, a grand assertion of the city’s power and glory. Various sources list extravagant amounts that were supposedly spent on them, from a low 1,000 talents all the way to 2,012 talents, a cost far beyond what can reasonably be expected. And yet it is indisputable that the Athenians dedicated substantial resources on a building that was not normally required to be costly. After all, what is the Propylaea other than a gatehouse?  ",
                audioFile: "story_audio_English_1533025034.mp3",
                duration: 56016,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Mild",
              },
              {
                id: 6724,
                langId: 2,
                sequence_id: 6,
                imageFile: "story_img_1568786808.jpg",
                videoFile: "",
                title: "The two orders",
                body: "The Propylaea combine two architectural orders. The exterior is Doric, which was considered more serious and stable. But because of the sharply rising ground on which the building is set, the architect chose to use Ionic columns in the central hall. These have a greater height relative to their diameter and are also slender, so they would take up less valuable space. There are six Ionic columns, three on each side of the four-meter-wide central passageway. The Propylaea are the oldest building where these two orders are combined. At the far end is the main door through which passed the sacrificial animals in the procession of the Great Panathenaia. The other four doors were approached by a flight of steps and were meant for human traffic.",
                audioFile: "story_audio_English_1533025062.mp3",
                duration: 54240,
                category: {
                  type: "Themes",
                  values: ["Architecture"],
                },
                difficulty: "Easy",
              },
              {
                id: 6725,
                langId: 2,
                sequence_id: 7,
                imageFile: "story_img_1568786849.jpg",
                videoFile: "",
                title: "Hermes by Socrates",
                body: "The Propylaea were full of statues and dedicatory inscriptions. The most notable one was a statue of Hermes which was claimed to be the work of Socrates, the famous philosopher who had been trained as a sculptor next to his father. The most famous decorative feature of the Propylaea, though, was the ceiling. The marble coffers (the sunken panels in the ceiling) were decorated with gold or gilded stars against a blue background. The primary purpose of the coffers was to reduce the overall weight of the ceiling, but the sculptors who worked on them created a spectacular visual decoration that remained unsurpassed in its beauty for centuries.",
                audioFile: "story_audio_English_1533025098.mp3",
                duration: 46848,
                category: {
                  type: "Themes",
                  values: ["Architecture", "Art"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2016,
            langId: 2,
            index: 9,
            imageFile: "tour_item_img_1533025026.jpg",
            thumbFile: "tour_item_thumb_1533025026.jpg",
            thumb_landscape_filename: null,
            lon: "23.725512",
            lat: "37.971781",
            name: "Τhe Statue of Athena Promachos",
            secret:
              "The statue of Athena Promachos greeted anyone who climbed atop the Acropolis hill, while its imposing view from afar reminded all inhabitants and visitors the grandeur of the city!",
            stories: [
              {
                id: 8795,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1568748721.jpg",
                videoFile: "",
                title: "Directions",
                body: "Continue walking upward along the processional way until you see a sign on your left. It indicates the location of the statue of Athena Promachus. It once stood imposingly in the large open space between the Propylaea and the Erechteion, the smaller temple opposite the Parthenon. On your right, the open space in front of the west facade of the Parthenon was home to the sanctuary of Artemis Brauronia.",
                audioFile: "story_audio_EN_1568758032.mp3",
                duration: 30048,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 6730,
                langId: 2,
                sequence_id: 2,
                imageFile: "story_img_1568787469.jpg",
                videoFile: "",
                title: "A giant Athena",
                body: "The most notable sculpture in this area was the bronze statue of Athena Promachos (“who fights in the front line”). This was a gigantic work of art, made by Phidias himself around 456 BCE to commemorate the battle of Marathon and the successful repulsion of the Persian invasion by the Athenians. It stood almost ten meters tall. The goddess faced towards the sea and wore an ankle-length garment and a helmet on her head. Her left hand held the folds of her dress, while the right hand held her spear. The top of the helmet and the tip of her spear could be seen by sailors for many miles. It stood before the old Mycenaean wall you can see in the distance to your left and many trophies won in the Persian Wars were placed around it. ",
                audioFile: "story_audio_English_1533025220.mp3",
                duration: 54168,
                category: {
                  type: "Themes",
                  values: ["History", "Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 6729,
                langId: 2,
                sequence_id: 3,
                imageFile: "story_img_1568787584.jpg",
                videoFile: "",
                title: "The Trojan horse",
                body: "To the right of the Parthenon as you see it from the Propylaea stood the sanctuary of Artemis Brauronia. This was a precinct without a temple but it boasted a bronze statue of Artemis by Praxiteles, as well as offerings to the goddess (especially women’s robes, since Artemis was the patron goddess of expectant mothers). There was also a huge bronze rendering of the Trojan Horse with life-size Greek heroes peeking out of it. Only a few steps carved on the rock remain of this sanctuary. To its right stood the Chalkotheke (Bronze store), a simple structure that was contemporary with the Parthenon. It was used to store bronze dedications (especially weapons, shields, and statues of Nike or owls, the sacred bird of Athena).",
                audioFile: "story_audio_English_1533025198.mp3",
                duration: 56160,
                category: {
                  type: "Themes",
                  values: ["History", "Archeology"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2017,
            langId: 2,
            index: 10,
            imageFile: "tour_item_img_1533025040.jpg",
            thumbFile: "tour_item_thumb_1533025040.jpg",
            thumb_landscape_filename: null,
            lon: "23.726358",
            lat: "37.972176",
            name: "Erechtheion (north entrance)",
            secret:
              "Everybody wants to be free and do as they wish. But sometimes living with age-old restrictions can have the most invigorating and ground-breaking effect on your creativity. ",
            stories: [
              {
                id: 8796,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1568748897.jpg",
                videoFile: "",
                title: "Directions",
                body: "Continue your walk along the processional way having the Parthenon on your right. Turn left toward the Erechtheion, the smaller temple opposite the Parthenon, following the designated route. You can stand in front of the sacred olive tree which is situated in front of the west facade of the temple or move a little bit further to the left toward the north entrance.",
                audioFile: "story_audio_EN_1568758064.mp3",
                duration: 26544,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 6731,
                langId: 2,
                sequence_id: 2,
                imageFile: "story_img_1568788248.jpg",
                videoFile: "",
                title: "A unique temple",
                body: "The construction of the Erechtheion began in 421 BCE. The Athenians, who had completed the Parthenon more than a decade earlier, decided it was time to erect a magnificent new temple to house the revered wooden statue of the goddess Athena. They had been at war with Sparta for the past ten years but now peace had returned and they had time and the resources to turn to the task at hand. It was not easy though, for soon Athens found itself embroiled in new hostilities against Sparta and it was only in the period 409-405 BCE that the Erechtheion was finally completed. It is a temple unlike any other anywhere in the Greek world and was designed to accommodate numerous deities and traditions which accounts for its peculiar plan. ",
                audioFile: "story_audio_English_1533025251.mp3",
                duration: 55128,
                category: {
                  type: "Themes",
                  values: ["History", "Architecture", "Archeology"],
                },
                difficulty: "Easy",
              },
              {
                id: 6732,
                langId: 2,
                sequence_id: 3,
                imageFile: "story_img_1568788562.jpg",
                videoFile: "",
                title: "Knitting in stone",
                body: "The name of the architect of the Erechtheion is not known with certainty (it may have been Mnesicles). At the time of its construction there were many sculptors and stonemasons in Athens who had gained precious experience working on the Parthenon. This is most obvious in the unsurpassable quality of the work on the north side of the Erechtheion. The columns and the great door are intricately carved and adorned with decorative elements in stone. It took a crew of 5-7 stonemasons about two months to complete a column. The great door was much admired in the modern period and inspired countless architects. It is 4,88 meters tall and 2,42 meters wide near the bottom (it narrows somewhat towards the top).  ",
                audioFile: "story_audio_English_1533025273.mp3",
                duration: 52920,
                category: {
                  type: "Themes",
                  values: ["Architecture", "Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 6733,
                langId: 2,
                sequence_id: 4,
                imageFile: null,
                videoFile: "",
                title: "The trident",
                body: "The architects had to account for a significant height difference between the various sides of the temple. The north side, where we stand, is almost three meters lower than the south side. If you look closely, you will see an opening on the marble floor next to the column that is closest to the temple wall on your left-hand side. Poseidon struck the rock there with his trident and water flowed, leaving behind the marks of his trident on the rock. There is also an opening in the ceiling above it, indicating a sacred spot that should not be covered. According to tradition, Zeus struck the earth here with his bolt and Killed Erechtheus. He was the legendary king of Athens after whom the temple was named.    ",
                audioFile: "story_audio_EN_1582708161.mp3",
                duration: 51072,
                category: {
                  type: "Themes",
                  values: ["Mythology", "Quirky", "Fun"],
                },
                difficulty: "Easy",
              },
              {
                id: 6734,
                langId: 2,
                sequence_id: 5,
                imageFile: null,
                videoFile: "",
                title: "The sea",
                body: "The Erechtheion had to accommodate numerous pre-existing altars and sacred sites. Beneath the floor when you enter through the north door was the so-called Sea of Erechtheus, the gift of briny water offered by Poseidon to the Athenians. The altar of Erechtheus, who had become Athena’s faithful servant in the form of a serpent, was located to the left of the door. Poseidon was also worshipped in a room adjacent to that of Erechtheus. The priests of Athena, Poseidon, and Erechtheus were traditionally selected from the noble family of the Boutades, whose ancestor, Boutes, also had an altar here. Finally, through a door at the far end of the room, people had access to the tomb of Kekrops, the supreme cultural hero of the Athenians. Six Athenian maidens stood as perennial keepers of his final resting place, forming the famous Porch of the Caryatids.  ",
                audioFile: "story_audio_English_1533025316.mp3",
                duration: 63984,
                category: {
                  type: "Themes",
                  values: ["Architecture", "Mythology"],
                },
                difficulty: "Easy",
              },
              {
                id: 6735,
                langId: 2,
                sequence_id: 6,
                imageFile: "story_img_1568788804.jpg",
                videoFile: "",
                title: "The olive tree of Athena",
                body: "The west side of the Erechtheion is the least satisfactory, at least in terms of its architectural plan. It seems awkward, an assortment of walls and columns that is very far removed from the neat organization of the classical Greek temple. The reason is the need to accommodate the pre-existing sanctuary of Pandrosus, a daughter of Cecrops. The sanctuary contained the altar of Zeus Herkeios (protector of the hearth), the oldest altar on the Acropolis. It stood under the sacred olive tree planted by Athena during her contest with Poseidon for the land of Athens. This tree was burned by the Persians in 480 BCE, but the very next day it had sprouted a new branch over a meter long. The olive tree you see today was planted by the members of the American School of Archaeology in 1952.",
                audioFile: "story_audio_English_1533025337.mp3",
                duration: 62112,
                category: {
                  type: "Themes",
                  values: ["History", "Nature"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2018,
            langId: 2,
            index: 11,
            imageFile: "tour_item_img_1533025050.jpg",
            thumbFile: "tour_item_thumb_1533025050.jpg",
            thumb_landscape_filename: null,
            lon: "23.726508",
            lat: "37.972101",
            name: "Erechtheion (east entrance)",
            secret:
              "Athena must have been mighty pleased with her people. After all, they built three masterpieces in her honour on the Acropolis, including a temple to house a most bizarre wooden statue that simply fell from the sky.",
            stories: [
              {
                id: 8797,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1568749008.jpg",
                videoFile: "",
                title: "Directions",
                body: "Walk past the north entrance and climb up the stairway. Then turn right to admire the east facade of the temple.",
                audioFile: "story_audio_EN_1568758078.mp3",
                duration: 11040,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 6736,
                langId: 2,
                sequence_id: 2,
                imageFile: "story_img_1568788889.jpg",
                videoFile: "",
                title: "The sacred snake",
                body: "The largest compartment of the temple is the east cella (the inner chamber) with the Ionic portico on the east end. This part of the temple was accessible from the north side with a large staircase (much larger than the one currently in use). The temple was built entirely of marble from Mount Pentelikon (as was the Parthenon) but the friezes were made of black limestone and bore sculptures in white marble. The cella was accessible through a main door, while on either side of it there were narrow windows. Columns divided the interior into three aisles, while the sacred snake of Athena resided in a subterranean room beneath the marble floor.  ",
                audioFile: "story_audio_English_1533025363.mp3",
                duration: 49080,
                category: {
                  type: "Themes",
                  values: ["Architecture", "Mythology", "Quirky"],
                },
                difficulty: "Easy",
              },
              {
                id: 6737,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "The golden lamp",
                body: "The east cella housed the statue of Athena Polias (Protector of the city). The statue was made of olive wood and the Athenians believed it had fallen from the sky (see the stories on the next point of interest for more details). It depicted the goddess sitting down and holding a gold container. She was dressed in the peplos that was renewed every four years during the Great Panathenaia festival. In front of her statue burned continuously a golden lamp. It was made by the sculptor Callimachus and was widely known as the “asbestos lychnia” (the light that cannot be extinguished). The reason for this peculiar name was the asbestos wick that was refuel with olive oil only once each year.   ",
                audioFile: "story_audio_English_1533025385.mp3",
                duration: 51072,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Mythology"],
                },
                difficulty: "Easy",
              },
              {
                id: 6738,
                langId: 2,
                sequence_id: 4,
                imageFile: null,
                videoFile: "",
                title: "The chair of Daedalus",
                body: "Right above the golden lamp stood a bronze palm tree that collected the smoke and removed it from the room. Besides these two technical marvels, there was a wooden statue of Hermes, the messenger of the gods. It was believed to be a dedication of the mythical king Cecrops and it was permanently hidden behind myrtle branches. This was a common practice among the ancient Greeks. Another votive offering associated with a mythological figure was a collapsible chair that was made by Daedalus, the famous craftsman and artist credited with designing the labyrinth in Knossos, as well as statues that were so realistic that people tied them down to prevent them from wandering off.",
                audioFile: "story_audio_English_1533025405.mp3",
                duration: 50472,
                category: {
                  type: "Themes",
                  values: ["Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 6739,
                langId: 2,
                sequence_id: 5,
                imageFile: null,
                videoFile: "",
                title: "The invisible breast plate",
                body: "The cella also contained historical relics. There was the sword of Mardonius, the leading Persian military commander during the Persian Wars, who died at the battle of Plataea in 479 BCE. Mardonius sacked Athens and gained the enmity of all Athenians. Another precious spoil from the Persian Wars was the gold breastplate of Masistius, a Persian cavalry commander who also died in Plataea. During the battle, an Athenian arrow killed his horse and Masistius fell to the ground. The Athenians set upon him but were unable to kill him because he was protected by his breast plate, a work of art so thin as to be almost invisible beneath his purple chiton. Eventually an Athenian stabbed him in the eye.   ",
                audioFile: "story_audio_English_1533025438.mp3",
                duration: 54720,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2019,
            langId: 2,
            index: 12,
            imageFile: "tour_item_img_1568792798.png",
            thumbFile: "tour_item_thumb_1568792798.png",
            thumb_landscape_filename: "tour_item_land_thumb_1568792798.png",
            lon: "23.726464",
            lat: "37.971936",
            name: "Old temple of Athena",
            secret:
              "There are many famous stories about the Acropolis during the Persian Wars (the wooden walls, the snake that would not eat his honey cake etc.). And yet they all concern a temple that almost nobody notices.  ",
            stories: [
              {
                id: 8798,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1568749110.jpg",
                videoFile: "",
                title: "Directions",
                body: "Having the Erechtheion on your right and the Parthenon on your left, you can now observe the open space next to the Erechtheion. The stone bases lying in front of the Caryatids, the curvaceous maidens supporting the temple’s portico, were once home to the Mycenaean palace and the old temple of Athena",
                audioFile: "story_audio_EN_1568758115.mp3",
                duration: 22032,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 6740,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The palace of the king",
                body: "Long before the Athenians decided to transform the top of their acropolis into the religious center of their city-state, the Acropolis was occupied by the palace of the Mycenaean kings of Athens. There were no temples dedicated to the gods during the Mycenaean period. The altars were located in the palace courtyard and the king served as the supreme religious authority (in addition to his role as military and political leader). The palace occupied the plateau next to the present-day Erechtheion but all that survives is a couple of stone bases for wooden columns that once supported the palace roof. They are among the stones you see in front of the Caryatids",
                audioFile: "story_audio_English_1533025465.mp3",
                duration: 47664,
                category: {
                  type: "Themes",
                  values: ["History", "Archeology"],
                },
                difficulty: "Mild",
              },
              {
                id: 6741,
                langId: 2,
                sequence_id: 3,
                imageFile: "story_img_1568789208.jpg",
                videoFile: "",
                title: "Born from the Acropolis",
                body: "The oldest temple on the Acropolis is hiding in plain sight. It may seem like a string of stones neatly laid out next to the Erechtheion, but this was for a very long time the most sacred spot on the Acropolis. It consists of two rectangles; the larger exterior one was the foundation for the columns, while the walls of the temple stood on the smaller interior one. It is 100 Attic feet long (32,80 meters) and the builders used two different types of stone. The foundation for the columns was made of marble from Mount Hymettus (the mountain that rises to the east of the Acropolis, closest to Athens) while stones from the rock of the Acropolis were used for the main temple.",
                audioFile: "story_audio_English_1533025492.mp3",
                duration: 50472,
                category: {
                  type: "Themes",
                  values: ["History", "Architecture"],
                },
                difficulty: "Easy",
              },
              {
                id: 6742,
                langId: 2,
                sequence_id: 4,
                imageFile: null,
                videoFile: "",
                title: "The flying statue",
                body: "This old temple served as the perennial residence of the sacred statue of Goddess Athena. The Athenians believed that the wooden statue had fallen from the sky and spared no expense to demonstrate their piety towards it. The most famous example of their devotion to this old relic was the festival of the Great Panathenaia, forever immortalized in the Parthenon frieze. During the festival, held every four years in the height of summer, the people of Athens formed a grand procession to deliver the peplos, a special robe made by the women of Athens for the wooden statue of Athena.  ",
                audioFile: "story_audio_English_1533025517.mp3",
                duration: 42768,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Easy",
              },
              {
                id: 6743,
                langId: 2,
                sequence_id: 5,
                imageFile: null,
                videoFile: "",
                title: "Enemy at the gates",
                body: "In the summer of 480 BCE the vast army of the Persian king Xerxes was approaching Athens, determined to destroy the city that had defeated his predecessor in the battle of Marathon ten years earlier. The general Themistocles argued that the Athenians should abandon their city and fight the Persians at sea, but many people believed that they were safe as long as Athena was by their side. There was a widespread belief that when a city faced destruction, the patron deity left first. Athena used to reveal herself as a great snake that lived in the temple, even though only the priestesses seemed able to see it. The snake received a honey cake once a month, but as the Persians approached, the cake was left untouched.",
                audioFile: "story_audio_English_1533025550.mp3",
                duration: 52656,
                category: {
                  type: "Themes",
                  values: ["History", "Mythology", "Quirky"],
                },
                difficulty: "Easy",
              },
              {
                id: 6744,
                langId: 2,
                sequence_id: 6,
                imageFile: null,
                videoFile: "",
                title: "The great massacre",
                body: "To the priestess it was clear that Athena had left the city. When she notified the Athenians, there was a mass exodus. Only a few hundred people, mostly old or extremely pious, decided to seek shelter behind a wooden palisade on the Acropolis. Their defence was brave but fruitless. The Persians shot countless flaming arrows into the enclosure, before they discovered a steep trail in the cleft of the rock that brought them to the top of the hill. Many defenders took refuge in the temple in the belief that the Persians would not dare to violate the sanctuary. They were wrong. The Persians opened the gates, murdered the suppliants, looted the temple and burned it to the ground.",
                audioFile: "story_audio_English_1533025589.mp3",
                duration: 50184,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2020,
            langId: 2,
            index: 13,
            imageFile: "tour_item_img_1533025073.jpg",
            thumbFile: "tour_item_thumb_1533025073.jpg",
            thumb_landscape_filename: null,
            lon: "23.727861",
            lat: "37.971814",
            name: "Belvedere",
            secret:
              "The so-called Belvedere is an excellent viewing point from which you can enjoy spectacular views of Athens and the mountains from where the ancient Athenians brought all the marble for the temples on the Acropolis.  ",
            stories: [
              {
                id: 8799,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1568749355.jpg",
                videoFile: "",
                title: "Directions",
                body: "Leaving the Parthenon behind you, head to the viewpoint, the so-called Belvedere,  from where you can take in a breathtaking panorama of the city. It won’t be hard to spot it! You will recognize it from the Greek flag which is situated on the Belvedere tower.",
                audioFile: "story_audio_EN_1568758131.mp3",
                duration: 20064,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 6745,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The preeminent Greek city",
                body: "Contemporary Athens, home to four million people, is the largest city in Greece. In that sense it remains faithful to its role during the time of Pericles. In the middle of the 5th century BCE Athens had a territory of 2,600 square kilometers and a population of half a million people. It was the wealthiest and most populous Greek city-state. Its coin, struck from silver mined in the south-east of Attica, was the most desirable currency in the Greek world. Its politics were democratic and most offices were open to all citizens (except for the poorest). The general assembly of the people decided many matter with few limits on its freedom, and every politician had to take into consideration the aspirations and dreams of this volatile mass of free citizens.  ",
                audioFile: "story_audio_English_1533025644.mp3",
                duration: 57720,
                category: {
                  type: "Themes",
                  values: ["History", "Fun"],
                },
                difficulty: "Easy",
              },
              {
                id: 6746,
                langId: 2,
                sequence_id: 3,
                imageFile: "story_img_1568789451.jpg",
                videoFile: "",
                title: "The Athenians meet marble",
                body: "The monuments of the Acropolis are built almost entirely of marble but the Athenians were rather late to take advantage of this precious material. It was only in 550-540 BCE, about a century before the major works on the Acropolis, that the Athenians were first exposed to the beauty of marble as a construction material. And it was only after their victory at the battle of Marathon in 490 BCE that they decided to use the marble quarries of Mount Penteli, north of Athens. They stopped importing marble from the Cycladic islands of Paros and Naxos and began developing the largest marble quarry anywhere in Greece. The quarry was public property but the state had rented it to civilians and undertook to extract the marble that they then sold back to the state.  ",
                audioFile: "story_audio_English_1533025688.mp3",
                duration: 56088,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Easy",
              },
              {
                id: 6747,
                langId: 2,
                sequence_id: 4,
                imageFile: "story_img_1568789373.jpg",
                videoFile: "",
                title: "Six hours on the road",
                body: "It took six hours for a load of marble to travel from the quarry to the foot of the Acropolis. They used carts pulled by draft animals that had a carrying capacity of 13-14 tonnes. The marble received an initial treatment at the quarry to remove any excess material and to acquire the basic shape, depending on its eventual location and use on the monument. The trip was easy in the countryside because the carts moved downhill, but as soon as they entered the city they had to deal with narrow streets, tight corners, and an uphill climb to the Acropolis. In front of the Propylaea there was a system of pulleys that used ropes and weights to carry the stones to the top of the hill.",
                audioFile: "story_audio_English_1533025717.mp3",
                duration: 47784,
                category: {
                  type: "Themes",
                  values: ["Quirky"],
                },
                difficulty: "Easy",
              },
              {
                id: 6748,
                langId: 2,
                sequence_id: 5,
                imageFile: "story_img_1568789409.jpg",
                videoFile: "",
                title: "The cranes",
                body: "There were eight cranes around the Parthenon, each approximately 27 or 28 meters tall (equal to a ten-storey building). These cranes allowed a group of 10 people to lift stones that weighed as much as a modern bus and place them in their correct place on the monument in 15-20 minutes. But before they did that, stonemasons and sculptors gave the stone its final shape. This was hard work that could last for weeks or months. The greatest problem was to ensure that each piece would fit perfectly with its neighbours, and the workers used numerous guides to ensure that these heavy pieces that dangled in the air would fall in the right place. ",
                audioFile: "story_audio_English_1533025759.mp3",
                duration: 47760,
                category: {
                  type: "Themes",
                  values: ["Architecture"],
                },
                difficulty: "Easy",
              },
              {
                id: 6749,
                langId: 2,
                sequence_id: 6,
                imageFile: null,
                videoFile: "",
                title: "The workers’ signatures",
                body: "A large segment of the population worked on this project. In addition to the labourers at the quarry and the drivers of the carts, there must have been more than 150 stonemasons on the Acropolis, as well as 50 sculptors. Countless other people laboured to provide ropes, timber, containers for anything from paint to drinking water, clothing, iron, lead, and copper. Just for the roof of the Parthenon they manufactured 9000 marble tiles, each of which required approximately 5-7 days of work. Some of the workers on the Parthenon used red paint to write their names on surfaces that would not be visible when the stone was put in place. Callias, Xanthias, and Euthymos are some of the names that survived to the present day.",
                audioFile: "story_audio_English_1533025792.mp3",
                duration: 53640,
                category: {
                  type: "Themes",
                  values: ["Architecture", "Fun"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2021,
            langId: 2,
            index: 14,
            imageFile: "tour_item_img_1533025087.jpg",
            thumbFile: "tour_item_thumb_1533025087.jpg",
            thumb_landscape_filename: null,
            lon: "23.727076",
            lat: "37.971576",
            name: "The Parthenon",
            secret:
              "It took the Athenians thirty years to decide to build the Parthenon (by ignoring a solemn oath) but only nine years to complete the project. And then a gold and ivory Athena settled in her magnificent residence. \r\n",
            stories: [
              {
                id: 8800,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1568749436.jpg",
                videoFile: "",
                title: "Directions",
                body: "Start walking toward the Parthenon. Stand in front of the east facade of the temple, which is exactly opposite the Belvedere tower, and listen to this set of stories.",
                audioFile: "story_audio_EN_1568758179.mp3",
                duration: 13536,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 6750,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The vow",
                body: "The Persian defeats at the battle of Plataea (479 BCE) preserved the freedom of Greece. But the victorious Athenians came back to a burned city. Their homes, the public buildings, the tombs of their ancestors, and the temples of their gods lay in smouldering ruins. The Acropolis had been destroyed with such a vengeance that almost no stone had been left intact. All around the temple of Athena spread a field of statues methodically maimed by Persian axes and hammers. According to the legend, the Athenians stood among the ruins of their city and took a solemn vow never to rebuild their temples as a perpetual reminder of the danger that once engulfed their city.   ",
                audioFile: "story_audio_English_1533025833.mp3",
                duration: 49656,
                category: {
                  type: "Themes",
                  values: ["Quirky"],
                },
                difficulty: "Easy",
              },
              {
                id: 6751,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "Never say never",
                body: "Time heals all and eventually even the Athenians realized it was time to move on. A new generation, wealthy, strong, and resolute came to power in the middle of the 5th century BCE and decided to express their newfound confidence with a grand architectural program. The mastermind behind it was Pericles, the great Athenian statesman, who envisioned a series of temples on the Acropolis that would provide a large segment of the population with steady employment, while advertising to the Greek world the power and glory of Athens. He entrusted his vision to the greatest sculptor of his time, Phidias, who supervised a brilliant group of architects, stonemasons, and artists.  ",
                audioFile: "story_audio_English_1533025865.mp3",
                duration: 50256,
                category: {
                  type: "Themes",
                  values: ["History", "Fun"],
                },
                difficulty: "Mild",
              },
              {
                id: 6752,
                langId: 2,
                sequence_id: 4,
                imageFile: null,
                videoFile: "",
                title: "The two architects",
                body: "Ictinus is considered the architect of the Parthenon, but there are very few details available about his life. The Roman author Vitruvius informs us that Ictinus had written a treatise on the Parthenon, but this irreplaceable work has been lost. We don’t know if he was an Athenian. He may have been a native Elean (from the western Peloponnese) who was enlisted by Phidias for work in Olympia. He is credited with the temple of Apollo at Bassae (450 BCE). He was then called to Athens, through the interest of Phidias, and he embarked on the project of designing the Parthenon. Ictinus was ably assisted by Callicrates, an architect who must have overseen the actual process of construction on the site. ",
                audioFile: "story_audio_English_1533025892.mp3",
                duration: 54576,
                category: {
                  type: "Themes",
                  values: ["Architecture"],
                },
                difficulty: "Easy",
              },
              {
                id: 6753,
                langId: 2,
                sequence_id: 5,
                imageFile: null,
                videoFile: "",
                title: "Nine years",
                body: "The Parthenon was was built in nine years (447-438 BCE). Then, it took another six years for Phidias and his sculptors to complete the fifty large statues that adorned the two pediments (the triangular upper part of the front of the building). The construction was a cause of great excitement for the people of Athens, who competed among themselves in their effort to contribute their skills in this project. But nothing would have been possible had not Pericles secured the necessary funds from the contributions of the more than 150 Greek city-states that belonged to the Delian League, an association whose purpose who to fight the Persians. ",
                audioFile: "story_audio_English_1533025916.mp3",
                duration: 48456,
                category: {
                  type: "Themes",
                  values: ["History", "Architecture"],
                },
                difficulty: "Easy",
              },
              {
                id: 6754,
                langId: 2,
                sequence_id: 6,
                imageFile: "story_img_1568789767.jpg",
                videoFile: "",
                title: "A goddess of gold and ivory",
                body: "Pericles envisioned a plan whereby 1/60 of each city’s contribution was reserved for the goddess of Athena. He was thus able to finance not only the buildings on the Acropolis but also the gold and ivory statue of Athena that was housed in the Parthenon. The statue had a wooden core, was 11 meters tall and was made by Phidias. He used ivory for the flesh and more than 1,100 kilograms of pure gold for the clothes. It depicted Athena Parthenos (the Virgin) who supported a shield with her left hand, while a winged Victory rested on her right hand. There was a small pool of water in front of it to provide the humidity required so that the ivory would not dry out and crack.    ",
                audioFile: "story_audio_English_1533025939.mp3",
                duration: 55416,
                category: {
                  type: "Themes",
                  values: ["Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 6755,
                langId: 2,
                sequence_id: 7,
                imageFile: null,
                videoFile: "",
                title: "The birth of a goddess",
                body: "An ancient Greek temple was the residence of the deity. No ceremonies took place inside. The priests had unlimited access to the interior but the people could enter only on particular days and times. The statue of Athena stood in a semi darkness dispelled only in the early morning by the reflection of the sun’s rays on the gold surfaces. The temple’s main entrance faced east to receive the first light of the day, so Phidias chose the east pediment to depict the birth of Athena. But he opted to present the goddess minutes after her appearance from the head of her father. She was fully armed and ready for battle, surrounded by the gods who marvel at the unexpected miracle of her birth.",
                audioFile: "story_audio_English_1533025959.mp3",
                duration: 53208,
                category: {
                  type: "Themes",
                  values: ["Architecture", "Art", "Mythology"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2022,
            langId: 2,
            index: 15,
            imageFile: "tour_item_img_1533025100.jpg",
            thumbFile: "tour_item_thumb_1533025100.jpg",
            thumb_landscape_filename: null,
            lon: "23.726179",
            lat: "37.971421",
            name: "The west side of the Parthenon",
            secret:
              "The Parthenon was the supreme gift of the people of Athens to their patron goddess. Its very perfection is so pronounced that one is left to wonder whether it was only Athena herself who could truly appreciate it. ",
            stories: [
              {
                id: 8801,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1568749543.jpg",
                videoFile: "",
                title: "Directions",
                body: "Facing the east facade of the Parthenon, head to the left to walk along the monument until you reach the opposite, west facade, which is our next point of interest.",
                audioFile: "story_audio_EN_1568758202.mp3",
                duration: 13536,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 6756,
                langId: 2,
                sequence_id: 2,
                imageFile: "story_img_1568790082.jpg",
                videoFile: "",
                title: "The poetics of the column",
                body: "The columns are the most distinctive feature of an ancient Greek temple. They stand between two solid horizontal lines (consisting of the foundations and the entablature i.e. the superstructure which lies horizontally above the columns) and seem to carry the whole weight of the temple. The Parthenon has 46 such columns, each playing its part in supporting the building. They resemble the free citizens of Athens who come together, as a body of willing individuals, to perform their duty for the common good. The columns stand at intervals of equal lengths, except for the corners where they are arranged closer together. The corner columns are also thicker, because there was no wall behind them and when seen against the sky they would appear thinner than the others.",
                audioFile: "story_audio_English_1533025981.mp3",
                duration: 57192,
                category: {
                  type: "Themes",
                  values: ["Architecture"],
                },
                difficulty: "Easy",
              },
              {
                id: 6757,
                langId: 2,
                sequence_id: 3,
                imageFile: "story_img_1568791124.jpg",
                videoFile: "",
                title: "All the colours of the rainbow",
                body: "The pristine white marble of the Parthenon may seem almost cruel under the bright midday sun and the Athenians took measures to minimize the consequent physical and aesthetic discomfort. Some parts of the building received a colourless varnish that reduced the glare. The superstructure required far more drastic measures. Large surfaces were painted deep red, blue, gold, or black. These are colours that can withstand exposure to the elements. Only the parts of the temple that support the structure (columns etc.) received no colour. Time has endowed the marble with a deep gold patina that makes the new pieces of marble that were added as part of the restoration process to stand out.",
                audioFile: "story_audio_English_1533026004.mp3",
                duration: 53304,
                category: {
                  type: "Themes",
                  values: ["Architecture", "Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 6758,
                langId: 2,
                sequence_id: 4,
                imageFile: "story_img_1568790011.jpg",
                videoFile: "",
                title: "The real Parthenon",
                body: "The Parthenon consists of two compartments. The largest one was called naos, was accessible from the east, and contained the statue of Athena. The smaller was called sekos and was accessible from the west, only this door rarely opened. The sekos was known as the “Parthenon” and the temple took its name from it. It did not serve any religious needs and no virgins (parthenae) ever seem to have used it. It may have functioned as a treasury for valuable dedications. The bronze slab with the financial accounts of the gold and ivory statue of Athena, ten gold Winged Victories dedicated to the goddess by the people of Athens in memory of her victory against the Giants, and the throne used by Xerxes during the battle of Salamis were kept here.",
                audioFile: "story_audio_EN_1582708354.mp3",
                duration: 58032,
                category: {
                  type: "Themes",
                  values: ["Architecture"],
                },
                difficulty: "Easy",
              },
              {
                id: 6759,
                langId: 2,
                sequence_id: 5,
                imageFile: null,
                videoFile: "",
                title: " For the eyes of the goddess",
                body: "If you look closely among the columns, you will see the famous Parthenon frieze near the top of the temple’s exterior wall. The frieze is a continuous band of sculptures that surrounds the temple. It depicts 360 humans and 220 animals who participate in the procession of the Great Panathenaia festival. This was the first time that a subject inspired from contemporary life was selected to adorn a Greek temple. The frieze has been rightfully celebrated for many centuries but what is striking is that its location pretty much ensured that humans would not be able to fully enjoy and appreciate it. It is simply too high and there was not enough light for the details to be visible. This mattered not to Phidias, who offered this great piece of sculpture as a gift to the goddess. It was for her eyes only.  ",
                audioFile: "story_audio_English_1533026048.mp3",
                duration: 63000,
                category: {
                  type: "Themes",
                  values: ["Architecture", "Art", "Quirky"],
                },
                difficulty: "Easy",
              },
              {
                id: 6760,
                langId: 2,
                sequence_id: 6,
                imageFile: null,
                videoFile: "",
                title: "The pyramid",
                body: "There is almost no straight line on the Parthenon’s exterior. The 46 columns lean inwards ever so slightly, with the corner columns doing so in a more pronounced fashion. The long walls of the temple also lean inwards (unlike the short walls at both ends that rise perfectly perpendicular). If you were to extend the lines of the columns and the walls skywards, they would meet at a height of 1782 meters, thus forming a pyramid. And yet this inward tendency of the Parthenon’s exterior is counterbalanced by the architrave (the beam that rests on the capitals of the columns) and the roof tiles that had a most pronounced inclination towards the sides and the earth, thus reducing the apparent weight of the superstructure.",
                audioFile: "story_audio_English_1533026067.mp3",
                duration: 57768,
                category: {
                  type: "Themes",
                  values: ["Architecture", "Fun"],
                },
                difficulty: "Easy",
              },
              {
                id: 8802,
                langId: 2,
                sequence_id: 7,
                imageFile: null,
                videoFile: "",
                title: "Directions to the exit",
                body: "You can now leave the archaeological site walking through the Propylaea once again and climbing down the great stairway. Instead of turning left to follow the way you used to enter continue straight and follow the cobbled path that will lead you to the West entrance-exit of the Acropolis.",
                audioFile: "story_audio_EN_1568758265.mp3",
                duration: 21552,
                category: {
                  type: "Info",
                  values: ["Closure"],
                },
                difficulty: "Easy",
              },
            ],
          },
        ],
        userAccess: true,
        categories: ["History"],
        stops: 15,
        stories: 79,
        points: [
          {
            lat: "37.971633",
            lon: "23.724778",
            sequence: 1,
          },
          {
            lat: "37.96962",
            lon: "23.729305",
            sequence: 1,
          },
          {
            lat: "37.971509",
            lon: "23.724898",
            sequence: 2,
          },
          {
            lat: "37.970371",
            lon: "23.727792",
            sequence: 2,
          },
          {
            lat: "37.971711",
            lon: "23.725154",
            sequence: 3,
          },
          {
            lat: "37.970442",
            lon: "23.726843",
            sequence: 3,
          },
          {
            lat: "37.971781",
            lon: "23.725512",
            sequence: 4,
          },
          {
            lat: "37.970756",
            lon: "23.726681",
            sequence: 4,
          },
          {
            lat: "37.972176",
            lon: "23.726358",
            sequence: 5,
          },
          {
            lat: "37.971133",
            lon: "23.724687",
            sequence: 5,
          },
          {
            lat: "37.972101",
            lon: "23.726508",
            sequence: 6,
          },
          {
            lat: "37.971936",
            lon: "23.726464",
            sequence: 7,
          },
          {
            lat: "37.971814",
            lon: "23.727861",
            sequence: 8,
          },
          {
            lat: "37.971576",
            lon: "23.727076",
            sequence: 9,
          },
          {
            lat: "37.971421",
            lon: "23.726179",
            sequence: 10,
          },
        ],
        mapBounds: {
          southWest: {
            lat: 37.96462,
            lon: 23.719687,
          },
          northEast: {
            lat: 37.977176,
            lon: 23.734305,
          },
        },
      },
      test278: {
        is_featured: false,
        isIndoors: true,
        showRoute: true,
        id: 278,
        langId: 2,
        name: "Acropolis Museum: the treasures of Athena",
        description:
          "The Museum is a project inspired by myth and history. Hosts collections of items from the sanctuaries on the slopes and Archaic Acropolis, the collection of the Parthenon’s sculptural decoration, the exhibits from the Propylaia, Temple of Athena Nike, and the Erechtheion, as well as other items dating back between the 5th c. BC and the 3rd c. AD",
        address: "The Acropolis Museum,  Dionysiou Areopagitou 15, Athina",
        openHours:
          "Acropolis Museum: Mon. 8.00-16.00, Tue.-Sun. 8.00-20.00, Fri. 8.00-22.00",
        admission:
          "Winter season (1 November - 31 March)General admission: 5 EurosReduced admission: 3 EurosSummer season (1 April - 31 October)General admission: 10 EurosReduced admission: 5 Euros",
        audioStatus: 3,
        imageFile: "tour_img_1559769330.jpg",
        thumbFile: "tour_thumb_1559769330.jpg",
        groundImageFile: "tour_ground_img_1557152897.jpg",
        type_id: 2,
        value_id: 2,
        lat: "37.97158765579",
        lon: "23.72794197718",
        author: "Clio Muse",
        authorDescription:
          "Award-winning creators of a unique, innovative platform enabling accredited professionals to design self-guided audio tours, based on an original and enticing storytelling concept.",
        telephone: "+302103006246",
        email: "bookings@cliomusetours.com",
        website: "https://www.cliomusetours.com",
        authorImage: "tour_logo_1555315924.jpg",
        hasSponsor: 0,
        sponsorImage: null,
        sponsorWebsite: "",
        sponsor: "",
        sponsorTitle: "",
        contributors: "Clio Muse",
        minZoom: 14,
        maxZoom: 19,
        starting_point_name: "Museum's entrance",
        starting_point_address: "Dionysiou Areopagitou 15, Athens 117 42",
        finishing_point_name: " Portrait of a ruler Sauromates, number NAM 419",
        finishing_point_address:
          " Portrait of a ruler Sauromates, number NAM 419",
        mapStyle: null,
        items: [
          {
            id: 2368,
            langId: 2,
            index: 1,
            imageFile: "tour_item_img_1559769424.jpg",
            thumbFile: "tour_item_thumb_1559769424.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769424.jpg",
            lon: null,
            lat: null,
            name: "The Εntrance",
            secret:
              "Designed as a “metaphor” for the Acropolis rock, the museum is an architectural triumph per se. On the ground level, visitors can catch glimpses of an ancient quarter below the building.\r\n",
            stories: [
              {
                id: 7738,
                langId: 2,
                sequence_id: 2,
                imageFile: "story_img_1556868364.jpg",
                videoFile: "",
                title: "Directions - Tip",
                body: "The Acropolis Museum is located to the southeast of the Acropolis, in the historic Makrigianni district. The entrance of the Museum is situated onto Dionysiou Areopagitou street, the central backbone of the road network leading to the interconnected archaeological sites of the city. The Acropolis station is located on the east side of the Museum site.\r\n\r\nIn the entrance of the Museum, there is an X-Ray security control for visitors and their luggage. You are therefore advised to avoid carrying large bags.\r\nThe cloakroom is situated on the ground floor of the Museum, where the visitors are asked to leave their backpacks and voluminous items. The Museum holds no responsibility for fragile and valuable items deposited in the cloakroom. \r\nAmateur photography and filming for personal use are permitted throughout the Museum, apart from the Archaic Gallery The use of flash and additional professional equipment (spotlights and tripod) is not allowed in the Museum galleries. Publication of photographs in print and electronic media is not allowed.  \r\n",
                audioFile: "story_audio_EN_1557208575.mp3",
                duration: 77040,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2475,
            langId: 2,
            index: 2,
            imageFile: "tour_item_img_1559769472.jpg",
            thumbFile: "tour_item_thumb_1559769472.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769472.jpg",
            lon: null,
            lat: null,
            name: "Engkainion",
            secret:
              "Designed as a “metaphor” for the Acropolis rock, the museum is an architectural triumph pe se. On the ground level, visitors can take glimpses of an ancient quarter below the building",
            stories: [
              {
                id: 8107,
                langId: 2,
                sequence_id: null,
                imageFile: "story_img_1556868375.jpg",
                videoFile: "",
                title: "Directions - Tip",
                body: "Having passed through the security control, continue straight toward the 1st Museum gallery. Once you’ve scanned your ticket, the turnstiles will be activated and you will enter the gallery of the Slopes. Head to the right side of the room where the 1st display case is situated. Direct your gaze downwards through the transparent glass floor and you will spot the egkainion. You will see a collection of small ceramics, animal bones and burned sacrifice remnants onto an illuminated panel. They have been obtained from a 3rd century BC residence. \r\nTip: It was a common practice among the Ancient Greeks to place an egkainion, namely a foundation deposit below the buildings they constructed to secure good luck and prosperity. The placing of the egkainion on this location is symbolical and of a similar purpose. This custom has been preserved until the present day",
                audioFile: "story_audio_EN_1557208612.mp3",
                duration: 62544,
                category: {
                  type: "Themes",
                  values: ["Archeology"],
                },
                difficulty: "Easy",
              },
              {
                id: 8108,
                langId: 2,
                sequence_id: null,
                imageFile: null,
                videoFile: "",
                title: "An ambitious project",
                body: "The first architectural competition for the new Acropolis Museum was staged in 1976, but it took three more contests before the Swiss-French architect Bernard Tschumi was officially declared the winner in late 2001. He created a museum whose interior is airy and light-filled. Large glass surfaces on the facade allow natural light to enter the building and “embrace” the sculptures. Tschumi envisaged the repatriation of the Parthenon Marbles from the British Museum and created the areas where they can be displayed. His design also took into consideration the preservation of the ancient residential quarter discovered below the museum. The glass floor allows visitors to explore classical Athens beneath their feet.",
                audioFile: "story_audio_EN_1557153134.mp3",
                duration: 57072,
                category: {
                  type: "Themes",
                  values: ["Architecture"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2369,
            langId: 2,
            index: 3,
            imageFile: "tour_item_img_1559769485.jpg",
            thumbFile: "tour_item_thumb_1559769485.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769485.jpg",
            lon: null,
            lat: null,
            name: "Loutrophoria with Female Flute Players",
            secret:
              "In the first gallery the rituals of ancient Athenians are brought to life. The pre-nuptial water vessels reveal more about the essential preparations before a couple tied the knot.\r\n",
            stories: [
              {
                id: 8109,
                langId: 2,
                sequence_id: null,
                imageFile: "story_img_1556868385.jpg",
                videoFile: "",
                title: "Directions",
                body: "From the egkainion on the right side of the room, move to the opposite left side. The first display case situated next to the turnstiles holds the number 4. You will find a small ceramic item, a water vessel, number ΝΑ 1955+1957 Αα 31, at the end of the lower row of items. It is the exhibit Loutrophoria with female flute players and it represents a pre-nuptial procession in which the gods Apollo and Hermes are also present.",
                audioFile: "story_audio_EN_1557208642.mp3",
                duration: 36528,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7739,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Water for the bride",
                body: "Water played an important role in the wedding-ritual of ancient Athens. On the eve of her wedding day, the bride was given a ritual bath with water brought from the famous Enneakrounos (Nine Fountains) spring. This ceremony was so important that 8th-century Athenians created a special vessel to carry the water. The loutrophoros was a clay vase with one or two handles and was decorated with wedding scenes; common among them was the actual procession which carried the loutrophoros to the bride’s house. Just before or after the wedding ritual, the bride came to the shrine of the Nymph on the slope of the Acropolis and dedicated the loutrophoros used in the ceremony. \r\n",
                audioFile: "story_audio_English_1553375255.mp3",
                duration: 49080,
                category: {
                  type: "Themes",
                  values: ["Archeology"],
                },
                difficulty: "Easy",
              },
              {
                id: 7740,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "The wedding planning checklist",
                body: "The Nymph was not the only deity who received dedications and sacrifices as part of the Athenian wedding rituals. Aphrodite, the goddess of love, obviously received generous sacrifices and libations. But Artemis, the virgin goddess, also had to be propitiated by the girls who were about to leave her sphere of influence, otherwise, she was capable of causing significant harm to the bride. Sacrifices were offered to ancestral deities called the Tritopatores, to the first couple (Ouranos and Gaia), and to Athena, the patroness of the city. The Furies were also entitled to offerings for the marriage rite since Athena herself had so decried when she entrusted them with the protection of Athens.",
                audioFile: "story_audio_English_1553375268.mp3",
                duration: 53520,
                category: {
                  type: "Themes",
                  values: ["History", "Mythology"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2370,
            langId: 2,
            index: 4,
            imageFile: "tour_item_img_1559769497.jpg",
            thumbFile: "tour_item_thumb_1559769497.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769497.jpg",
            lon: null,
            lat: null,
            name: "Spindle Whorls with Painted Decoration",
            secret:
              "The votive offerings from the sanctuaries provide insights into the daily life of women in the gendered ancient world. Whiling away the time productively, they wove garments for the entire family.\r\n",
            stories: [
              {
                id: 8110,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868399.jpg",
                videoFile: "",
                title: "Directions",
                body: "You will remain on the same side of the room and continue to the display case holding the number 6, which is situated right next. You will find in the middle, in the lower row of items, the exhibit. Spindle whorls with painted decoration, number 128. The whorl is secured on the base of the spindle to facilitate its rotation. A spindle is a tool used for the spinning of wool and the making of handcrafted weaving thread.   ",
                audioFile: "story_audio_EN_1557208687.mp3",
                duration: 33024,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7741,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Let’s get weaving!",
                body: "Clay spindle whorls were another common dedication to the sanctuary of the Nymph. Athenian women were responsible for the manufacture of cloth and clothing for the entire family. Young women spent most of their time processing wool. Cleaning, combing, and spinning were burdensome but necessary tasks that ensured the self-sufficiency of the Athenian household. It took a month to prepare the wool, seven days to set up the loom, and a few days to weave a simple garment. More elaborate pieces of clothing required even more time. Long before they married, girls learned the necessary skills; after all the peplos of the goddess Athena was prepared by girls between the ages of seven and eleven.\r\n",
                audioFile: "story_audio_English_1553375282.mp3",
                duration: 52128,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2371,
            langId: 2,
            index: 5,
            imageFile: "tour_item_img_1559769511.jpg",
            thumbFile: "tour_item_thumb_1559769511.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769511.jpg",
            lon: null,
            lat: null,
            name: "Pillar with an Offering to Asclepios",
            secret:
              "Going blind was very common in ancient times due to accidents, battle injuries, or as a divine punishment received after a wrongful act. When all medical practices failed, the gods were the only resort.\r\n",
            stories: [
              {
                id: 8111,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868414.jpg",
                videoFile: "",
                title: "Directions",
                body: "You will remain on the left side of the room and continue straight ahead, where you will see in front of you a stone-made rectangular stele with a white figure immured onto its surface and several rectangular carvings. It is the exhibit Pilar with an offering to Asclepios, number NAM 15244. It was an offering by Praxias, after the healing of his wife’s eyes.  ",
                audioFile: "story_audio_EN_1557208708.mp3",
                duration: 31056,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7742,
                langId: 2,
                sequence_id: 2,
                imageFile: "story_img_1554384348.jpg",
                videoFile: "",
                title: "A world of blind people",
                body: "Blind people were ubiquitous in ancient Athens. People could lose their eyesight as a result of divine or mortal revenge; a man called Evenius was blinded by the villagers when he failed to tend the sacred sheep. People could become blind as a result of workplace accidents; quarrying, mining, masonry, and smithing were dangerous occupations. Warriors lost their eyesight in battle; the eyes of Peisander fell before Menelaus’ feet, while Epizelus the Athenian went blind before the battle of Marathon as a result of seeing a vision. Finally, there was nutritional blindness; the Athenian statesman Demetrius of Phalerum became blind but was cured through the favor of the gods and a good dose of Vitamin A when he was allowed by the priests to taste sacrificial meat. \r\n",
                audioFile: "story_audio_English_1553375327.mp3",
                duration: 58080,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Mild",
              },
              {
                id: 7743,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "Terracotta eyes",
                body: "The ancient Greeks had an intimate relationship with their gods; it was based on the belief that the immortals would assist them in times of peril, and they would offer them something in return for their benevolence. The usual method of expressing gratitude was a bloody sacrifice, but in matters of health, there was another alternative. Greeks dedicated to the deity that helped them an ex-voto modeled on the body part that was cured through divine intervention. Terracotta models of human heads, eyes, legs, hands, arms, genitals, feet, breasts etc. are common finds in sanctuaries throughout Greece. Wealthy individuals could offer votives made of gold, silver, or other precious materials. It is a tradition that is still very much alive throughout Greece.\r\n",
                audioFile: "story_audio_English_1553375340.mp3",
                duration: 55584,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Easy",
              },
              {
                id: 8112,
                langId: 2,
                sequence_id: 4,
                imageFile: null,
                videoFile: "",
                title: "Directions",
                body: "To the left of the previous exhibit Pilar with an offering to Asclepios, you will see, placed on the wall, the exhibit Offering to Asclepios, with number NAM 1332, which represents Asclepios or the Goddess Demeter and her daughter Persephone welcoming a group of famed doctors. The doctors’ names have been carved on the wreaths below the display.  \r\n",
                audioFile: "story_audio_EN_1557208731.mp3",
                duration: 29568,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7744,
                langId: 2,
                sequence_id: 5,
                imageFile: "story_img_1559769571.jpg",
                videoFile: "",
                title: "Doctors and priests",
                body: "Chiron the Centaur was the first to practice surgery; Apollo the first to perform oracular medicine; and Asklepios the first to establish the principles of sickbed medicine. The epic poems of Homer demonstrate an impressive knowledge of anatomy and physiology, while historians, rhetoricians, and philosophers exhibited a keen interest in medical matters. Gradually the Greeks were able to assemble a monumental collection of sixty medical writings known as the “Hippocratic” corpus. Greek medicine was influenced by Egyptian practices (the land of the pharaohs was a source of drugs), but soon the Greeks produced their own famous doctors (Hippocrates, Galen etc.) And when human abilities failed, patients could turn to the gods (Apollo, Asklepios) and seek assistance in the great healing sanctuaries. \r\n",
                audioFile: "story_audio_English_1553375353.mp3",
                duration: 61104,
                category: {
                  type: "Themes",
                  values: ["History", "Mythology"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2372,
            langId: 2,
            index: 6,
            imageFile: "tour_item_img_1559769531.jpg",
            thumbFile: "tour_item_thumb_1559769531.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769531.jpg",
            lon: null,
            lat: null,
            name: "Cult of the Hero on Blaute",
            secret:
              "Sandals weren’t merely footwear for the ancient Greeks. A mysterious votive alludes to an ancient cult on the slopes of the Acropolis. \r\n",
            stories: [
              {
                id: 8113,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868425.jpg",
                videoFile: "",
                title: "Directions",
                body: "Facing the stone-made rectangular stele titled Pilar with an offering to Asclepios, you will move directly opposite to the right side of the gallery. There you will find the next exhibit Cult of the Hero on Blaute, number NAM 2565. It is a stone-made stele, upon which a snake is slithering, while on the top there is a sandal with the inscription Silon, the name of the assigner, carved on it.   \r\n",
                audioFile: "story_audio_EN_1557208799.mp3",
                duration: 34032,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7745,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Sandals",
                body: "Silon has tentatively been identified as a cobbler, but we do not know the reasons that led him to dedicate this votive stele on the Acropolis. An inscription found built in an Ottoman bastion near the temple of Athena Nike refers to a shrine of Blaute and Ge Kourotrophos (Earth Nursing Mother). Other ancient writers refer to Blaute as an Athenian hero or a place in Athens. Modern scholars identify Blaute with Aphrodite. To make matters even more confusing, blaute was the name of a type of sandal. Ancient Greeks considered sandals as functional items of clothing but also used them as decorative elements and symbols of mobility, transition, and transformation.\r\n",
                audioFile: "story_audio_English_1553375366.mp3",
                duration: 51072,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Mythology"],
                },
                difficulty: "Mild",
              },
              {
                id: 7746,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "Are you afraid of snakes?",
                body: "Ophidiophobia (the abnormal fear of snakes) is the most common reported phobia, affecting a third of adult humans (and many primates). And yet the snake is the most readily recognizable symbol of Asclepius, the ancient Greek god of medicine. Snakes shed their entire skin in one fell swoop (unlike most other animals, which discard feathers, skin, or fur gradually). The ancient Greeks observed this “ecdysis” (molting) and believed that snakes have the ability to renew (and thus heal) themselves. Snakes also spend their lives on and in the earth, so they possessed an intimate knowledge of the earth’s healing properties as exemplified in minerals and herbs. \r\n",
                audioFile: "story_audio_English_1553375379.mp3",
                duration: 50088,
                category: {
                  type: "Themes",
                  values: ["Quirky"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2373,
            langId: 2,
            index: 7,
            imageFile: "tour_item_img_1559769631.jpg",
            thumbFile: "tour_item_thumb_1559769631.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769631.jpg",
            lon: null,
            lat: null,
            name: "The Three-Bodied Daemon",
            secret:
              "The Hekatompedon temple was adorned with some creepy sculptural decorations, including a ferocious three-bodied demon. Scholars have identified the creature with the mythical Typhon.\r\n",
            stories: [
              {
                id: 8114,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868487.jpg",
                videoFile: "",
                title: "Directions",
                body: "Climb the stairs and you will reach the 1st floor, the next exhibit is right in front of you. It is situated on the right side of the group of sculptures and it is the Three-bodied demon from the pediment of the Hekatompedos temple, number Acr.35. At the center of the pediment, two lions are devouring a bull. Hercules is fighting against Triton on the left, while on the right, the three-bodied demon holds in hand the elements of nature.  \r\n",
                audioFile: "story_audio_EN_1557208816.mp3",
                duration: 34032,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7747,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The father of all monsters",
                body: "The first great stone temple to appear on the Acropolis was probably inaugurated before the establishment of the Great Panathenaia festival in 566 BCE. This building is more popularly known as the Hekatompedon (“100 feet long”) or the “Bluebeard” temple as a result of an astonishing group of sculptures in the corner of a pediment (triangular gables). This “bluebeard” is a human-headed, triple-bodied monster with spreading wings and a lower body that terminates in three entwined coils that resemble a snake. The faces have wide eyes, a broad grin, and trimmed pointed beards painted in a bright blackish-blue. This monster may be Typhon, the last son of Gaia (Earth) and father of all monsters; he was killed by Zeus.\r\n",
                audioFile: "story_audio_English_1553375475.mp3",
                duration: 59568,
                category: {
                  type: "Themes",
                  values: ["Architecture", "Art", "Mythology"],
                },
                difficulty: "Easy",
              },
              {
                id: 7748,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "Earth, air, water",
                body: "Each of the three bodies of “Bluebeard” holds an attribute; there is water, there is grain, and there is one bird. These objects may stand for the three realms of the sea, earth, and air, endowing the monster with a philosophical message. It is also possible that the three attributes correspond to the Athenians who lived on the coast, the plain, and the hills of Attica. The entwined coils remind us that the Athenians claimed to be indigenous i.e. sprung from the soil of their land (much like a snake). It was for this reason that their early kings (Erechtheus and Cecrops) were usually depicted as half-human and half-serpent. \r\n",
                audioFile: "story_audio_English_1553375489.mp3",
                duration: 50088,
                category: {
                  type: "Themes",
                  values: ["History", "Art", "Mythology"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2374,
            langId: 2,
            index: 8,
            imageFile: "tour_item_img_1559769647.jpg",
            thumbFile: "tour_item_thumb_1559769647.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769647.jpg",
            lon: null,
            lat: null,
            name: "The Lioness Pediment",
            secret:
              "Sometimes it is impossible to know what an artist living thousands of years ago must have had in mind when crafting a work of art. The hermaphrodite lion pediment poses a riddle until today.\r\n",
            stories: [
              {
                id: 8115,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868502.jpg",
                videoFile: "",
                title: "Directions",
                body: "Having the three-bodied demon at your back, you will find the next exhibit to the left of the stairway. It is the lioness pediment from the Hekatompedos temple, number Acr. 4, which represents a breasted lion.",
                audioFile: "story_audio_EN_1557208833.mp3",
                duration: 19056,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7749,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The hermaphrodite lion",
                body: "The middle of the pediment, to the left of the bluebeard monster, is occupied by a hermaphrodite lion savaging a bull. The lion/lioness was once assisted by another animal on the left, but it seems to have no difficulty crumpling the terrified, bellowing bull. The lion has the right claws and musculature but it also has both a mane and teats. Some scholars have explained this bizarre combination of male and female characteristics to the artist’s ignorance of the animal. It is also possible that the sculptor wished to create a fantastic beast that would be even more ferocious and terrifying exactly because it seemed so unnatural. Finally, it may be a fine example of the adoption of Near Eastern mythology, where the bull brought down by lions is a potent symbol of the struggle between the forces of life and death. \r\n",
                audioFile: "story_audio_English_1553375504.mp3",
                duration: 60576,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Mythology"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2375,
            langId: 2,
            index: 9,
            imageFile: "tour_item_img_1559769663.jpg",
            thumbFile: "tour_item_thumb_1559769663.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769663.jpg",
            lon: null,
            lat: null,
            name: "The Moschophoros (Calf-Bearer)",
            secret:
              "Who is this man carrying a calf for sacrifice? Should you read left to right the inscription, you’ll discover his name.\r\n",
            stories: [
              {
                id: 8116,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868523.jpg",
                videoFile: "",
                title: "Directions",
                body: "Having the previous exhibit, the lioness pediment, on your right, you will continue straight to enter the Archaic Gallery collection. On your left hand, you will encounter a marble exhibit, which is the next point of interest, the Calf-bearer, number Acr. 624. It represents a young man bearing a calf in his arms. \r\n",
                audioFile: "story_audio_EN_1557208849.mp3",
                duration: 25536,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7750,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The smiling calf-bearer",
                body: "There are comparatively fewer male statues on the Acropolis, but this makes perfect sense since Athena was a goddess and female statues were a much more reasonable offering to her. The Calf-Bearer (moschophoros) is a wonderful exception. The statue was dedicated by a man called Rhonbos; he may be the one carrying the calf, an expression of his gratitude to Athena for her benevolence. In that case, this statue is a double-offering, since Rhonbos not only brings the calf but also dedicated the whole sculpture. He wears a fine cloak (himation) but is otherwise naked (the garment was once painted). This is one of the earliest Greek sculptures to wear the “archaic smile”; the significance of this convention is not known, but it was widely used by the artists of the period, even on statues depicting dying warriors.\r\n",
                audioFile: "story_audio_English_1553375535.mp3",
                duration: 63072,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2376,
            langId: 2,
            index: 10,
            imageFile: "tour_item_img_1559769678.jpg",
            thumbFile: "tour_item_thumb_1559769678.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769678.jpg",
            lon: null,
            lat: null,
            name: "The Peplos Kore",
            secret:
              "The Korai are puzzling artifacts for archaeologists. The statues represent female bodies, whose elegance and garments show evidence of their divine or earthly nature but their exact identity remains elusive.\r\n",
            stories: [
              {
                id: 8117,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868535.jpg",
                videoFile: "",
                title: "Directions",
                body: "Having the Calf-bearer at your back, you will see right opposite the next marble exhibit. It is the Peplos Kore, number Acr. 679. The statue represents a Kore, whose right hand is adjusted to her body while her left hand is partly raised. You will recognize her by her slightly red-colored hair. \r\n",
                audioFile: "story_audio_EN_1557208866.mp3",
                duration: 25536,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7751,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "An Archaic mystery",
                body: "Clothes don’t make the man, but sometimes it pays to study carefully what someone is wearing. The Peplos Kore was named after the peplos (a simple, long, woolen cloth) that early scholars thought she wore. As it turns out, she is wearing four different garments, one of which was reserved solely for goddesses. She is therefore not a mortal, but then again, which goddess is she? The attribute held in her missing left arm would have enabled viewers to immediately identify her, but to us, she remains a mystery. The Kore is 1.18m tall (3.87 ft) and her body and hair were painted with bright colours. She also wears the “archaic smile”, an indication perhaps that she is alive and in a state of well-being.\r\n",
                audioFile: "story_audio_English_1553375556.mp3",
                duration: 56544,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 7752,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "The daughters of Athena",
                body: "The Korai (singular: kore) of the Acropolis are a group of female statues created and erected during the sixth and early fifth centuries BCE. Ancient authors do not mention these statues, and most ancient Athenians during the classical period would not have seen them since they were badly damaged when the Persians sacked the Acropolis (480/479 BCE) and were subsequently buried. Inscriptions confirm that they were dedicated to Athena by individuals in gratitude for the goddess’ assistance. The statues are not identified with proper names, so we are not sure whether they are true portraits or generic female statues. In any case, some are noticeably beautiful while others appear plain or even unseemly.\r\n",
                audioFile: "story_audio_English_1553375578.mp3",
                duration: 56112,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Mild",
              },
              {
                id: 7753,
                langId: 2,
                sequence_id: 4,
                imageFile: null,
                videoFile: "",
                title: "A plausible speculation",
                body: "A theory claims that they represent priestesses, but there are far more statues than is to be expected for a lifetime office (Lysimache served for 64 years!). Alternatively, the korai might be nymphs or deities. All of them are young women of marriageable age and their bodies are prominent and revealed under their clothes. They were dedicated exclusively by men (at least based on the extant inscriptions); fishermen, merchants, potters, and manufacturers offered a kore as “aparche” to commemorate and express gratitude for the first production of the harvest or a commercial transaction.",
                audioFile: "story_audio_English_1553375596.mp3",
                duration: 45576,
                category: {
                  type: "Themes",
                  values: ["Archeology"],
                },
                difficulty: "Mild",
              },
              {
                id: 7754,
                langId: 2,
                sequence_id: 5,
                imageFile: null,
                videoFile: "",
                title: "Double gifts",
                body: "Korai are defined by elaborate hairstyles, colourful garments, and intricate accouterments (painted, carved, or added in another material). Surprisingly enough, their hair may represent wigs and not actual hairstyles current among the women of Athens. They are brunettes, red-haired, or blonde; it is not uncommon that the colours of the garments match the colour of the hair! Korai have highly decorated earrings or headgear of various shapes and sizes; some are barefoot, some shod with elaborate sandals or red shoes. Almost all of them held a gift in their extended arm, while some used one of their hands to pull aside their skirts. The extant offerings include apples, pomegranates, and birds. The dedicator is basically doubling the gift since he offers a statue that will perpetually give a smaller gift.\r\n",
                audioFile: "story_audio_English_1553375613.mp3",
                duration: 58968,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2377,
            langId: 2,
            index: 11,
            imageFile: "tour_item_img_1559769694.jpg",
            thumbFile: "tour_item_thumb_1559769694.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769694.jpg",
            lon: null,
            lat: null,
            name: "Hunting Dog",
            secret:
              "Who says that only representations of human bodies could be offered as votives to the gods? Our four-legged friends have a long pedigree in the history of art! \r\n",
            stories: [
              {
                id: 8118,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868547.jpg",
                videoFile: "",
                title: "Directions",
                body: "To the left of the Peplos Kore, in front of the cement pillar, you will see the next marble exhibit which is the Hunting dog, number Acr. 143. It represents a dog, rested on a base as only one of his feet has been preserved. ",
                audioFile: "story_audio_EN_1557208888.mp3",
                duration: 21552,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7755,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The goddess’ best friend",
                body: "The hunting dog is also attributed to the sculptor of the Rampin Rider. The statue was made in ca. 520 BCE and was part of a pair of dogs (fragments of the other animal are also preserved). The dog’s body is tense and muscular as the animal bends its legs in readiness to pursue its prey. The motive behind the dedication of the animal is not immediately clear but ancient sources record the dedication of animal figures to commemorate a successful hunt. Lions and wild boars were dedicated to other important sanctuaries (Delphi and Pergamon); the two Archaic dogs on the Acropolis may also honor such an occasion. Alternatively, the dogs may have served as guardians that flanked the entrance to a sanctuary.\r\n",
                audioFile: "story_audio_English_1553375627.mp3",
                duration: 55056,
                category: {
                  type: "Themes",
                  values: ["Archeology"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2378,
            langId: 2,
            index: 12,
            imageFile: "tour_item_img_1559769710.jpg",
            thumbFile: "tour_item_thumb_1559769710.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769710.jpg",
            lon: null,
            lat: null,
            name: "The Chian Kore",
            secret:
              "Examining a statue we can learn much about the social status of the represented figure. It seems that there is always class distinction, even in the world of sculpture. \r\n",
            stories: [
              {
                id: 8119,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868566.jpg",
                videoFile: "",
                title: "Directions",
                body: "Having at your back the face of the hunting dog, continue straight until you reach the next cement pillar. To the left of the pillar, you will see the back of the next marble exhibit. It is the Chian Kore, number Acr 675. It represents a kore without hands, having dark patches on her clothes. The patches have been created by the corrosion of the ancient colors. Next, to her, there is a smaller-sized replica, which has been painted in order to give us an idea of what the original colors must have looked like. ",
                audioFile: "story_audio_EN_1557208907.mp3",
                duration: 38544,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7756,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Uptown girl",
                body: "Just a few steps from the Peplos Kore stands a girl from the island of Chios. This Kore wears the light linen Ionian chiton and a heavier himation (mantle), the garments of choice for fashionable women of the late 6th century BCE. She is grasping part of her chiton in her left hand to lift it off the ground as she is ready to start walking. The garment had lavish, bright colors, and intricate decorative patterns that enhanced the overall sense of elegance, grace, and luxury. These are typical attributes of the more relaxed Ionian way of life that contrasted sharply to the austere and plain Doric way (exemplified by the harsh life at Sparta). \r\n",
                audioFile: "story_audio_English_1553375645.mp3",
                duration: 49224,
                category: {
                  type: "Themes",
                  values: ["Archeology"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2379,
            langId: 2,
            index: 13,
            imageFile: "tour_item_img_1559769728.jpg",
            thumbFile: "tour_item_thumb_1559769728.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769728.jpg",
            lon: null,
            lat: null,
            name: "The Rampin Rider",
            secret:
              "Most people are familiar with the fascination of the Greeks with olive wreaths. It might seem surprising but wreaths of celery were also common.\r\n",
            stories: [
              {
                id: 8120,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868581.jpg",
                videoFile: "",
                title: "Directions",
                body: "Leaving behind you the Chian Kore, you will head to the opposite side where you will find the marble exhibit the Rampin Rider, number Acr. 590. The statue represents a rider without hands, seated on a horse. Only a few of the horse’s members have been preserved.  ",
                audioFile: "story_audio_EN_1557208925.mp3",
                duration: 23568,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7757,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Wreath of celery",
                body: "In ancient Athens, it was a sign of wealth and social status to be able to provide a war horse, while the cavalry was given the place of honour in the major religious festivals and processions. The Athenian cavalry corps consisted of approximately 1000 men, who were often engaged in scouting operations during the campaign. Members of the aristocracy were proud of their contribution to the defense of Athens and dedicated equestrian statues to goddess Athena. The Rampin Rider was made in 560-550 BCE. The rider turns and bends slightly to the right, while the horse moves the other way. He wears a wreath of wild celery, so he may be a victorious athlete in the Nemean or Isthmian Games.\r\n",
                audioFile: "story_audio_English_1553375663.mp3",
                duration: 53568,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2380,
            langId: 2,
            index: 14,
            imageFile: "tour_item_img_1559769747.jpg",
            thumbFile: "tour_item_thumb_1559769747.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769747.jpg",
            lon: null,
            lat: null,
            name: "The Endoios Athena",
            secret:
              "Upon returning to their city after the Persian invasion, the Athenians found a scorched land. Yet, one of the few artifacts that survived the sack was a statue of their patron goddess.\r\n",
            stories: [
              {
                id: 8121,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868593.jpg",
                videoFile: "",
                title: "Directions",
                body: "Having the horse-back rider’s back behind you, move ahead following a slightly rightward direction. In front of the next cement pillar, you will see the next marble exhibit. It is the Endoios Athena, number Acr. 625. It depicts goddess Athena seated on her throne. Yet, she is headless and has no hands.      \r\n",
                audioFile: "story_audio_EN_1557208945.mp3",
                duration: 28032,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7758,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "A goddess on her throne",
                body: "The marble Endoios Athena is the oldest extant monumental statue of the goddess Athena seated. In 155-160 CE the traveler Pausanias saw it on the Acropolis accompanied by an inscription which read that it had been made by the sculptor Endoios and dedicated by Callias. Endoios was active from ca. 530-500 BCE and was said to be a pupil of Daedalus, the mythical craftsman, and father of Icarus. Endoios Athena was near the Erechtheion and survived the Persian sack of the Acropolis (480-479 BCE). It depicts the goddess seated on a throne; she is wearing a large aegis (animal skin) with holes for the attachment of bronze snakes, as well as a gorgoneion (an amulet showing the head of Gorgon that deflected misfortune) on her breast.\r\n",
                audioFile: "story_audio_English_1553375750.mp3",
                duration: 65088,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 7759,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "The millionaire",
                body: "Callias, the man who dedicated the statue of Endoios Athena, was a prosperous Athenian and a strong opponent of the tyrant Pisistratus. His family was among the wealthiest in Athens, and Callias seems to have been fearless, for he did not hesitate to purchase the property of Pisistratus whenever the tyrant was banished after losing power. Callias inherited most of his wealth from his uncle and embarked on an illustrious career of racing at the Olympic and Pythian Games. He was also unique in that he allowed his three daughters to choose their own husband at a time when most girls had almost no say in who they married. Each received a most generous dowry. \r\n",
                audioFile: "story_audio_English_1553375764.mp3",
                duration: 47064,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2381,
            langId: 2,
            index: 15,
            imageFile: "tour_item_img_1559769776.jpg",
            thumbFile: "tour_item_thumb_1559769776.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769776.jpg",
            lon: null,
            lat: null,
            name: "The Gigantomachy Pediment",
            secret:
              "Athena wasn’t only clever but also a fearless warrior. Her victory over the Giants is considered one of her greatest triumphs in battle.\r\n",
            stories: [
              {
                id: 8122,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868606.jpg",
                videoFile: "",
                title: "Directions",
                body: "Facing the statue of the Endoios Athena, the next marble exhibit is situated on your right. It is Goddess Athena from the Gigantomachy pediment of the ancient temple. It depicts the goddess leaning to the front holding a snake\r\n.   \r\n",
                audioFile: "story_audio_EN_1557208976.mp3",
                duration: 20064,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7760,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The Giants",
                body: "The Gigantomachy was very popular among the Athenians; according to their traditions, the procession of the Great Panathenaia, the greatest festival in classical Athens, was established by the legendary ruler Erichthonius to commemorate Athena’s victory over the Giants. They were monsters that sprang from the blood that dripped when Kronos severed the testicles of Ouranos. There were 24 Giants against the 12 Olympian gods, and the former were so big that their heads touched the clouds. Athena distinguished herself in this cosmic war by annihilating two of her opponents. Pallas was relatively easy to overcome, so she played him and used his impenetrable skin for her aegis. Enceladus was much stronger, so she lifted the island of Sicily and threw it on him, crushing him beneath Mount Etna. \r\n",
                audioFile: "story_audio_EN_1637246182.mp3",
                duration: 62064,
                category: {
                  type: "Themes",
                  values: ["Mythology"],
                },
                difficulty: "Mild",
              },
              {
                id: 7761,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "Fighting Athena",
                body: "The colossal statue of Athena pouncing a fallen Giant belonged to the Gigantomachy pediment of the Old Temple of Athena. This was located between the Parthenon and the Erechtheion, and it was built between 525 and 500 BCE to celebrate the establishment of a new, democratic, system of government. The statues that adorned the pediments were carved in flawless Parian marble, a material so expensive and highly prized that it was reserved for some of the greatest masterpieces of ancient Greek sculpture. The goddess is depicted going after her opponent, using her aegis (equipped with terrifying snakes) as a shield and a weapon. Three more Giants are seen in various stances that express their defeat.\r\n",
                audioFile: "story_audio_English_1553375796.mp3",
                duration: 53592,
                category: {
                  type: "Themes",
                  values: ["Art", "Mythology"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2382,
            langId: 2,
            index: 16,
            imageFile: "tour_item_img_1559769803.jpg",
            thumbFile: "tour_item_thumb_1559769803.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769803.jpg",
            lon: null,
            lat: null,
            name: "The Nike of Callimachus",
            secret:
              "Tales of war are best told by those who fought in the battle. The victory of the Athenians in Marathon proved that the Persians could be beaten and Callimachus was the first to believe in this. \r\n",
            stories: [
              {
                id: 8123,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868620.jpg",
                videoFile: "",
                title: "Directions",
                body: "Facing the statue of Athena, on your left hand straight ahead you will encounter the next marble exhibit, the Victory of Callimachus, number Acr. 690. Due to the fact that it has been only partly preserved, the members of the colonnade have been placed on a metallic stele while a statue of the Goddess Nike rests atop. To get a better idea of what the statue originally looked like, take a look at the small-sized replica to the left of the stele.  \r\n\r\n",
                audioFile: "story_audio_EN_1557208998.mp3",
                duration: 35040,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7762,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "A general’s goats",
                body: "The Nike of Callimachus is a monument erected by the Athenians in honor of Callimachus, the senior military commander at the Battle of Marathon (490 BCE). On the eve of the battle, the other ten generals were split evenly on whether to fight the Persians, so Callimachus cast the decisive vote in favor of the battle. He is said to have promised to sacrifice to the goddess Artemis as many goats as the number of Persians killed in battle. The Athenians compromised by offering 500 animals annually because it was difficult to find 6,400 goats for a single sacrifice. Callimachus fought bravely and died pierced with so many spears that his body remained in an upright position. The monument is almost five meters tall (16 ft); it is an inscribed marble column topped by a sculpted figure of Nike (Victory).\r\n",
                audioFile: "story_audio_English_1553375816.mp3",
                duration: 62088,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Mild",
              },
              {
                id: 7763,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "Back with a vengeance",
                body: "The monument of Callimachus was erected shortly after the battle of Marathon northeast of the older Parthenon (near the Erechtheion). In 480 BCE the Persians returned to Athens. The few defenders on the Acropolis were soon defeated; most of them were massacred or threw themselves off the walls. The victorious Persians burned the temples and attacked the statues, mutilating the korai with axes. Hammers and mallets were used against noses, cheeks, chins, heads, feet, and arms. The Nike of Callimachus was a potent reminder of Persia humiliation and thus received extensive damage. The column was broken into more than 100 pieces, the face of Nike was smashed and her body was cut in two. \r\n",
                audioFile: "story_audio_English_1553375840.mp3",
                duration: 54672,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2383,
            langId: 2,
            index: 17,
            imageFile: "tour_item_img_1559769820.jpg",
            thumbFile: "tour_item_thumb_1559769820.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769820.jpg",
            lon: null,
            lat: null,
            name: "The Kritios Boy",
            secret:
              "There are some works of art that show evidence of a turning point in the evolution of style. The world of sculpture would never be the same after the creation of the Kritios boy.\r\n",
            stories: [
              {
                id: 8124,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868634.jpg",
                videoFile: "",
                title: "Directions",
                body: "Having the back side of the Victory of Callimachus behind you, move forward and, on your left, you will find the next marble exhibit. It is the Kritios Boy, number Acr. 698. The statue depicts a young athlete without hands and missing his right foot. The size of this statue is smaller compared to the rest of the other male statues you’ve encountered so far.  \r\n\r\n",
                audioFile: "story_audio_EN_1557209070.mp3",
                duration: 29040,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7764,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Say no to bird droppings",
                body: "The Kritios Boy is among the most famous ancient Greek marble statues. It is the earliest extant free-standing sculpture to break with the formula of the Archaic kouros through the alternation of weight-bearing and free legs. The statue is less than life-size (1.167 m.) and may depict a victorious athlete. The eyes were inserted into another material. The statue has an impressive hairstyle with traces of red paint in the grooves between the rolls of hair on the back side on the right of the head. There are also traces of a bronze rod at the top of the head that may have supported a meniscus i.e. a bronze disk to prevent bird droppings from accumulating on a statue that was exposed to the elements.\r\n",
                audioFile: "story_audio_English_1553375944.mp3",
                duration: 53472,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2384,
            langId: 2,
            index: 18,
            imageFile: "tour_item_img_1559769840.jpg",
            thumbFile: "tour_item_thumb_1559769840.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769840.jpg",
            lon: null,
            lat: null,
            name: "The Blond Boy",
            secret:
              "The end of the Persian Wars signaled the passage to the solemn Classical period. After the terrible events of the war, the Archaic smile would begin to fade away from the statues’ expression.\r\n",
            stories: [
              {
                id: 8125,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868656.jpg",
                videoFile: "",
                title: "Directions",
                body: "Facing the Kritios Boy, you will find the next marble exhibit moving leftward to the front. It is the statue of the Blond Boy, number Acr 689. Only the head of the boy, who is crowned with a tiara, has been preserved.  ",
                audioFile: "story_audio_EN_1566915387.mp3",
                duration: 22032,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7765,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The blond boy",
                body: "The Blond Boy is a youth made of Parian marble. He owes his name to the light red paint that still colored his hair when he was discovered. It is a typical example of the Severe Style, a new style that defines the transition from Archaic to Classical art. The Archaic smile is replaced by an austere look. The heavy jaw, full lips, and heavy lids surrounding the eyes contribute to a serious expression, which has long been considered the result of the Persian Wars and the destruction of Athens in 480 BCE. The Blond Boy has the most amazing hairstyle; his long hair forms two braids that cross on the nape of the neck and disappear beneath the curls of his forehead. The naked torso with the thighs (Acr. 6478) nearby also belongs to this statue.",
                audioFile: "story_audio_English_1553375960.mp3",
                duration: 58560,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2385,
            langId: 2,
            index: 19,
            imageFile: "tour_item_img_1559769862.jpg",
            thumbFile: "tour_item_thumb_1559769862.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769862.jpg",
            lon: null,
            lat: null,
            name: "Relief of the Pensive Athena",
            secret:
              "The Athenians were full of sorrow for the thousands of men who fell in battle for their city and freedom. In times of grief, the patron goddess mourned the loss of the brave citizens.\r\n",
            stories: [
              {
                id: 8126,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868668.jpg",
                videoFile: "",
                title: "Directions",
                body: "Having the back side of the Blond Boy behind you, you will move straight ahead until you reach the end of the gallery where the next marble exhibit is situated. It is the Relief of the Pensive Athena, number Acr. 695. It is found on your left, in front of the last pillar, while on your right you can see the escalator. It represents Goddess Athena wearing a helmet and holding her spear with her head tilted down.      \r\n",
                audioFile: "story_audio_EN_1557213050.mp3",
                duration: 35040,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7766,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Athena in mourning",
                body: "Athens and the goddess Athena go together like peas and carrots. She was ever ready to assist her favourite city in times of peril and expressed her concern for the wellbeing and prosperity of her people. When they rejoiced so did she; when they mourned, she joined them in their sorrow. The “Mourning Athena” is a famous marble relief (ca. 460 BCE) that captures this incomparable relationship. Athena is depicted as a young woman wearing a peplos clasped on the shoulders and cinched at the waist. She has lifted her Corinthian helmet and grasps a spear as she bows her head before a stele (an upright stone slab) that was perhaps inscribed with the names of the Athenians who died at war.\r\n",
                audioFile: "story_audio_English_1553375977.mp3",
                duration: 53136,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Mythology"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2386,
            langId: 2,
            index: 20,
            imageFile: "tour_item_img_1559769880.jpg",
            thumbFile: "tour_item_thumb_1559769880.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769880.jpg",
            lon: null,
            lat: null,
            name: "The Parthenon Installation",
            secret:
              "Phidias was a great visionary whose skill reached the peak of artistic achievement. His work in the sculptural decoration of the Parthenon has been forever immortalized.\r\n",
            stories: [
              {
                id: 8127,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868682.jpg",
                videoFile: "",
                title: "Directions - Tip",
                body: "Take the escalator to the 3rd floor, where you will find the Parthenon gallery. The escalator will lead you to a central room where you can have a close look at the Parthenon installation to get an idea of what the temple and its pediments looked like when it was constructed in the times of Pericles. \r\nTip: The 10’ video presenting a comprehensive history of the Parthenon is not to be missed! \r\n\r\n",
                audioFile: "story_audio_EN_1557213090.mp3",
                duration: 29040,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7767,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "A small Parthenon",
                body: "The Parthenon Gallery occupies the sun-drenched top floor of the museum and serves as an extension of the Parthenon itself. The temple is visible from the interior of the museum, an innovative perspective enhanced by the fact that the floor sits askew on the lower levels to achieve the same orientation as the Parthenon. It also has the same dimensions, while the spacing of the 48 columns corresponds to the spacing of the marble columns on the ancient temple. The architects placed the frieze and the pediment sculptures at eye level for the comfort of the visitors. The metopes are displayed on the columns but at a lower height than the original. Plaster copies enable the visitors to differentiate between the original sculptures still in Athens, and those taken to the British Museum in the 19th century. \r\n",
                audioFile: "story_audio_English_1553375996.mp3",
                duration: 54576,
                category: {
                  type: "Themes",
                  values: ["Architecture", "Archeology", "Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 7768,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "Phidias the miracle-worker ",
                body: "Phidias, the creative supervisor of the Parthenon’s entire building program, is widely credited with conceiving and organizing the iconographic plan for the metopes, pediments, and frieze. Two of his most significant sculptures were the gold and ivory colossal statues of Athena Parthenos, which could be found inside the Parthenon, and Zeus in Olympia, one of the seven wonders of the ancient world. Phidias was a tireless worker and his workshop produced sculptures that decorated temples throughout Greece. Among these were the Athena Area at Plataea, the bronze Athena Promachos and the bronze Apollo Parnopius at the Athenian Acropolis, Lemnian Athena at the acropolis of Lemnos, and the great memorial for the battle of Marathon at Delphi.\r\n",
                audioFile: "story_audio_English_1553376018.mp3",
                duration: 56088,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2387,
            langId: 2,
            index: 21,
            imageFile: "tour_item_img_1559769902.jpg",
            thumbFile: "tour_item_thumb_1559769902.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769902.jpg",
            lon: null,
            lat: null,
            name: " Hebe and Hera – Northern Metope",
            secret:
              "Sometimes changing your name and starting over is the only way to survive, even in art! \r\n",
            stories: [
              {
                id: 8129,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868697.jpg",
                videoFile: "",
                title: "Directions",
                body: "Once you’ve seen the video, use the right way out having the window display with the Parthenon installation to your front. Continue to your left until you reach the end of the gallery and there you will find the north-west corner of the monument. You will see the block of Hebe and Hera placed on the floor, number B32.",
                audioFile: "story_audio_EN_1557213126.mp3",
                duration: 23568,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7769,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "A case of mistaken identity ",
                body: "In the Byzantine period, the Christians prohibited any form of pagan worship. Under this pretext, they damaged numerous pagan ancient temples. They disfigured the faces of the gods and carved crosses on many of them to ‘christianize’ them. The Parthenon was one of the temples damaged in this manner. The entire sculptural decoration suffered extensive damage, but one metope managed to escape unscathed. The lucky metope was number 32, on the northern side, which was mistaken for a representation of the Annunciation. In fact, it depicted the gods on Mount Olympus convening to decide the end of the Trojan War. The supposed Archangel is the goddess Hebe and the so-called Virgin Mary is the Hera.\r\n",
                audioFile: "story_audio_EN_1637246251.mp3",
                duration: 58032,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2388,
            langId: 2,
            index: 22,
            imageFile: "tour_item_img_1559769928.jpg",
            thumbFile: "tour_item_thumb_1559769928.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769928.jpg",
            lon: null,
            lat: null,
            name: "Poseidon – West Pediment",
            secret:
              "The gods, wished to share some of Athens’ glory, offered to become the city’s patrons. The rivalry between Athena and Poseidon is one of the most famed myths in Greek mythology!\r\n",
            stories: [
              {
                id: 8130,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868711.jpg",
                videoFile: "",
                title: "Directions",
                body: "Leaving behind the metope of Hebe and Hera, move straight and stop in front of the western pediment, whose installation you previously saw in the former gallery. In the middle of the pediment, there is a replica of Poseidon’s figure, which has partly been preserved and is exhibited in the British Museum.  \r\n",
                audioFile: "story_audio_EN_1557213153.mp3",
                duration: 22560,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7770,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Naming the city",
                body: "Legend has it that Athena and Poseidon had an argument over who would become the dominant deity of Athens, then known as Cecropia. So they decided that they would each present the city with a gift and ask the citizens to decide. Athena presented an olive tree and Poseidon a spring of seawater. The citizens chose the olive tree and Athena became their patron deity. For this reason, the city has been known as Athens ever since. At the center of the pediment, Athena and Poseidon are depicted with their gifts, an olive tree, and saltwater respectively. They are framed by their charioteers, Nike and Amphitrite. Beside them, the two messengers, Hermes and Iris are rushing to announce the end of the contest. \r\n",
                audioFile: "story_audio_English_1553376074.mp3",
                duration: 56040,
                category: {
                  type: "Themes",
                  values: ["Art", "Mythology"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2389,
            langId: 2,
            index: 23,
            imageFile: "tour_item_img_1559769950.jpg",
            thumbFile: "tour_item_thumb_1559769950.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769950.jpg",
            lon: null,
            lat: null,
            name: "The Great Panathenaea – West Frieze",
            secret:
              "The Athenians threw a grand party to celebrate the birthday of their patron goddess. The Great Panathenaea are depicted in the Parthenon’s frieze and no one missing. \r\n",
            stories: [
              {
                id: 8131,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868725.jpg",
                videoFile: "",
                title: "Directions",
                body: "You are standing in the middle of the pediment in front of the figure of Poseidon. The next exhibit is straight behind you. Just walk along the pediment and return back, walking along its other side this time, to reach the sculptures of the western frieze. Our point of interest is block VIII. It represents a horse-back rider. Ηis arm is raised and his garment is waving. His face is not preserved. The color of the stone here is brown since this is an original member and not a replica.",
                audioFile: "story_audio_EN_1557213194.mp3",
                duration: 37536,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7771,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The birthday of Athena ",
                body: "The Parthenon frieze is a continuous strip of low-relief sculptures adorning the upper part of the temple’s cella. It consists of 115 blocks and is 160 meters long and 1.02 meters high. A total of 378 human figures and deities and 200 animals, mainly horses, are depicted. The frieze does not depict a mythological subject but the Great Panathenaea, Athens’ greatest festival in honour of the goddess Athena. The festival took place every four years and lasted twelve days. It consisted of rites, sacrifices, athletic and musical contests. The festival culminated on the day when according to tradition the goddess was born, the 28th of the month Hekatombaion (July-August). A grand procession accompanied the new peplos (robe) for the statue of Athena to her temple on the Acropolis.\r\n",
                audioFile: "story_audio_English_1553376099.mp3",
                duration: 66576,
                category: {
                  type: "Themes",
                  values: ["History", "Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 7772,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "The preparations",
                body: "The west frieze depicts the preparations for the Panathenaic Procession in the Kerameikos district of ancient Athens. It is a mélange of calm and intense scenes; horsemen are depicted in conversation, tying their sandals, or trying to tame their horses. Each scene is self-contained in one block, strong evidence that the blocks may have been sculpted prior to being placed on the frieze. The sculptors were confronted with the difficulty of making the frieze visible at the original great height on the wall of the temple. They gave greater depth to the relief at the top so that the surface would be inclined towards the viewer. Viewing was improved by the use of colour on the frieze, particularly the blue in the background.\r\n",
                audioFile: "story_audio_English_1553376114.mp3",
                duration: 51576,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 7773,
                langId: 2,
                sequence_id: 4,
                imageFile: null,
                videoFile: "",
                title: "The hand of Phidias",
                body: "On the west frieze, you can see a block sculpted by Phidias himself. It is block No. 8 (VIII) and it depicts a single horse and its rider. The figure is believed to depict Theseus, the mythical founder of the Panathenaic Festival, who is attempting to restrain his runaway mount. The great attention to detail, the intricate folds of the garment, and the particularly realistic rendition of the figure bear witness to the mastery of the artist. Phidias was considered the only man to have seen the face of the Greek gods. He revealed their image to humanity and shaped the popular image of the gods of Mount Olympus that persists to the present day.\r\n",
                audioFile: "story_audio_English_1553376131.mp3",
                duration: 51576,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2390,
            langId: 2,
            index: 24,
            imageFile: "tour_item_img_1559769969.jpg",
            thumbFile: "tour_item_thumb_1559769969.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769969.jpg",
            lon: null,
            lat: null,
            name: "Centauromachy – South Metope",
            secret:
              "The 92 metopes of the Parthenon are brimful of action and adventure depicting the Gigantomachy, the Amazonomachy, the Centauromachy and the Trojan War. \r\n",
            stories: [
              {
                id: 8132,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868741.jpg",
                videoFile: "",
                title: "Directions",
                body: " Having the block VII of the western frieze to your front, head to the right toward the south-west corner of the monument. You will find there, placed on the floor, next to a colonnade, the south metope of the Centauromachy, number N1. It depicts a Centaur overcoming a Lapith. ",
                audioFile: "story_audio_EN_1557213589.mp3",
                duration: 23040,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7774,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Centaurs vs humans ",
                body: "The metopes can be found over the exterior columns, alternating with the triglyphs across the temple. The Parthenon had 92 metopes, depicting scenes inspired by Greek mythology. The four most important mythical battles of ancient Greece are depicted on the metopes. Mythical creatures, gods and men fight for victory. On the east side, we have the Gigantomachy; the western metopes depict the Amazonomachy; the south metopes the Centauromachy; and the metopes on the north are inspired by the Trojan War. The southern metopes are considered the best in terms of execution and conception. According to the myth, the Centaurs were half men and half horses. The war began when the king of the Centaurs, Eurytion, attempted to rape Hippodameia on her wedding night. He was captured by Peirithous (the groom), who punished him by cutting off his ears and nose. Thus a terrible battle between the Centaurs and the Lapiths began. Assisted by Theseus, the King of Athens, the Lapiths defeated the Centaurs.\r\n",
                audioFile: "story_audio_English_1553376146.mp3",
                duration: 78576,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art", "Mythology"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2391,
            langId: 2,
            index: 25,
            imageFile: "tour_item_img_1559769991.jpg",
            thumbFile: "tour_item_thumb_1559769991.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559769991.jpg",
            lon: null,
            lat: null,
            name: "Horsemen – South Frieze",
            secret:
              "The south frieze is jam-packed as it depicts the procession of the Great Panathenaea, an event that was , obviously, not-to-be-missed.\r\n",
            stories: [
              {
                id: 8133,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868765.jpg",
                videoFile: "",
                title: "Directions",
                body: "Leaving behind the metope of the Centauromachy, you will move forward until you reach the 5th consecutive colonnade on the south side. There you will step to the internal side to examine a block from the frieze. It is the block bearing the number N.X. 26-28, “the  horsemen and their horses”. It depicts 3 horsemen with their horses as the frieze is brimful with depictions of horsemen who participated in the grand celebration of the Panathenaea. The blocks are white since they are replicas.  \r\n",
                audioFile: "story_audio_EN_1557213609.mp3",
                duration: 39552,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7775,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The procession begins",
                body: "The south frieze depicts the procession of the Great Panathenaea. It begins with the marshal on the first block, the horsemen, charioteers, the elders, tray-bearers, olive branch-bearers, musicians, offering tray-bearers and animal drivers leading sacrificial animals. The horsemen are divided into ten ranks, distinguishable by their clothing. The ten ranks correspond to the ten Attic tribes, into which the citizens of Athens were grouped. The horses are all depicted in profile view and some appear calm, some galloping, whilst others appear to be racing each other. They are followed by ten chariots and then by the animal drivers and the sacrificial animals. There are 149 human forms in total, 60 horsemen, ten chariots and ten oxen.\r\n",
                audioFile: "story_audio_English_1553376172.mp3",
                duration: 60096,
                category: {
                  type: "Themes",
                  values: ["History", "Archeology", "Art"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2392,
            langId: 2,
            index: 26,
            imageFile: "tour_item_img_1559770010.jpg",
            thumbFile: "tour_item_thumb_1559770010.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559770010.jpg",
            lon: null,
            lat: null,
            name: "Τhe Goddess Athena – East Pediment",
            secret:
              "In any truly brilliant work of art, such as the Parthenon’s sculptural decoration, the concept of space and spatial organization is a creative element upon which the artist draws inspiration. \r\n",
            stories: [
              {
                id: 8134,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868780.jpg",
                videoFile: "",
                title: "Directions",
                body: "Facing the block from the frieze, head to your right and walk along the south side of the monument. You will reach the east side where the east pediment is situated. You saw it earlier while examining the installation in the previous gallery. On the left side of the pediment, God Dionysus, with his limbs missing, is seated gazing out on the horses’ heads. The sculpture is a replica, while the original is exhibited in the British Museum. ",
                audioFile: "story_audio_EN_1557213625.mp3",
                duration: 33552,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7776,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Time of birth",
                body: "The pediments are the two triangular spaces under the roof at the narrow sides of the temple. They are 28 meters long and 3.45 meters wide and depict a total of 50 figures drawn from Greek mythology. The east pediment over the main entrance depicted the birth of the goddess Athena from the head of her father, Zeus, in the presence of the gods. The triangular shape of the pediment forced the sculptors to create more compact statues for the corners. Rather than causing difficulties, this was used to depict day, night and the passage of time. Thus, on the left, the sun god Helios’ carriage can be seen rising from the sea, while on the right the moon goddess Selene’s carriage is sinking. In this way, time and life are linked to the myth of the birth of Athena, indicating that the birth took place at dawn.\r\n",
                audioFile: "story_audio_English_1553376190.mp3",
                duration: 62088,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art", "Mythology"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2393,
            langId: 2,
            index: 27,
            imageFile: "tour_item_img_1559770032.jpg",
            thumbFile: "tour_item_thumb_1559770032.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559770032.jpg",
            lon: null,
            lat: null,
            name: "East Frieze",
            secret:
              "The culmination of the ceremony was the preparation of the new peplon for the statue of Athena. Athena deserved only the best so the robe had to be designed and woven with great care. \r\n",
            stories: [
              {
                id: 8135,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868797.jpg",
                videoFile: "",
                title: "Directions",
                body: "Facing God Dionysus, you will see right behind the columns of the east side of the monument. Walk along heading rightward and you will find the next exhibit between the 5th and the 6th colonnade on the wall opposite. It is the block, number Acr. 856, from the east pediment and you, will easily recognize it since it is the only original block among the other replicas. It depicts a male and two female seated figures.",
                audioFile: "story_audio_EN_1557213644.mp3",
                duration: 33552,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7777,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The high point ",
                body: "The procession on the frieze began on the west side of the Parthenon and was divided into two branches running along the north and south sides. The leaders of the procession converge towards the gods on the east side. The twelve immortals are seated in two groups facing the approaching branches. In the centre of the east frieze, on a block that is almost four times larger than the normal, a bearded man folds a large piece of cloth with the help of a young male assistant; to their left are two young girls who bring stools and a taller, older woman. This is the high point of the ceremony, when the chief religious magistrate of Athens, the archon basileus, prepares the new peplon (robe) for the statue of Athena Polias (whose statue was actually housed in the Erechtheion and not the Parthenon).\r\n",
                audioFile: "story_audio_English_1553376213.mp3",
                duration: 58584,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 7778,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "Α very special garment ",
                body: "The peplos was made of wool prepared by girls from the ten Athenian tribes. It took them nine months to finish the garment. On the day of the procession, it was hoisted on the mast of a ceremonial ship mounted on wheels and carried to the foot of the Acropolis. The method by which it was then taken to the Erechtheion and the statue of Athena is not known. It is conceivable that the girls who prepared it, more than a hundred, took it down from the mast and brought it to the temple, under the supervision of the priestesses of the goddess. The garment must have been enormous and far too heavy for a single person to fold it or handle it. These issues raise important questions about the true meaning of the Parthenon frieze. After all, no ancient writer mentions it and the current popular interpretation is based on a theory proposed by two English visitors to Athens in the 18th century. \r\n",
                audioFile: "story_audio_English_1553376229.mp3",
                duration: 64608,
                category: {
                  type: "Themes",
                  values: ["History", "Archeology"],
                },
                difficulty: "Easy",
              },
              {
                id: 8136,
                langId: 2,
                sequence_id: 4,
                imageFile: null,
                videoFile: "",
                title: " Coffee break",
                body: "Take the escalator to the second floor and have a break at the Museum’s cafe which is on your right. You can rest and enjoy your coffee overlooking the Acropolis rock. If the weather is good you can sit on the balcony. Within this floor is one of the museum's gift shops, where you can do your shopping.\r\nJust outside the café, before taking the escalator to get down to the 1st floor, there is a small exhibition of machine models made by lego blocks showing the construction of the Parthenon.\r\n",
                audioFile: null,
                duration: null,
                category: {
                  type: "Tips",
                  values: ["Gastronomy - Restaurant"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2394,
            langId: 2,
            index: 28,
            imageFile: "tour_item_img_1559770056.jpg",
            thumbFile: "tour_item_thumb_1559770056.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559770056.jpg",
            lon: null,
            lat: null,
            name: "Hermes from a Hermaic Stele",
            secret:
              "At a time when social media weren’t available, inscribing verses on public sculptures to share your thoughts was perfectly acceptable. \r\n",
            stories: [
              {
                id: 8137,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868813.jpg",
                videoFile: "",
                title: "Directions",
                body: "Take the escalator to the 1st floor, and then turn right where you will find the next exhibit titled Hermes from a hermaic stele, number Acr. 14877. It is a stele with the head of God Hermes rested atop. Two hermaic stelae are exhibited here next to each other.  ",
                audioFile: "story_audio_EN_1557213662.mp3",
                duration: 26064,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7779,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Think just thoughts",
                body: "Herms (sculptures with a head, and perhaps a torso, above a plain, usually squared lower section) were a common feature in Athens. They could be placed near the gates to guard the city or the sanctuary, or they could be dedications to the gods. Many herms were placed as road markers, a tradition that may have originated at the time of the tyrant Pisistratus and his sons. One of them, Hipparchus, even inscribed verses on the plain lower sides of the herms with moral sentiments such as “think just thoughts” or “don’t deceive a friend”. There were also private herms guarding the entrances of households to protect the owners from the evil influences that may try to disturb domestic bliss.\r\n",
                audioFile: "story_audio_English_1553376330.mp3",
                duration: 53520,
                category: {
                  type: "Themes",
                  values: ["Archeology"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2395,
            langId: 2,
            index: 29,
            imageFile: "tour_item_img_1559770075.jpg",
            thumbFile: "tour_item_thumb_1559770075.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559770075.jpg",
            lon: null,
            lat: null,
            name: "Temple of Athena Nike",
            secret:
              "The temple of Athena Nike was one of the most potent places of worship of the city. Ritual life in Ancient Athens had a very strict etiquette. ",
            stories: [
              {
                id: 8138,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868835.jpg",
                videoFile: "",
                title: "Directions",
                body: "Having in front of you the hermaic stele, you will walk past it and walk through the open space between the colonnades. On your right and to the front, you will see a block from the south parapet of the Temple of Athena Nike. The exhibit is the Sandal binder Nike, number Acr. 973, and it represents Athena Nike putting on her sandal.       \r\n\r\n",
                audioFile: "story_audio_EN_1557213679.mp3",
                duration: 28032,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7780,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Take off your shoes, please!",
                body: "The temple of Athena Nike on the Acropolis was surrounded by a marble parapet adorned with a relief depicting a procession of Nikai (goddesses personifying victory) engaged in various activities in the presence of the goddess Athena. Some bring offerings, others lead and sacrifice bulls, while others erect trophies. One Nike is fidgeting with her sandal. She bends down to her right and lifts her right leg. Her right hand reaches for the sandal but without touching it. Is she binding or loosening it? Shoes often signal the transition to sacred space and have to be removed on arrival as part of the ritual. This Nike reminds visitors that they are about to enter sanctified grounds where it is customary to be barefoot.\r\n",
                audioFile: "story_audio_English_1553376348.mp3",
                duration: 57120,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 7781,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "Did you notice? ",
                body: "The Sandal binder Nike is attributed to the anonymous sculptor conventionally known as “Master E”. The motif of sandal tying was common at the time of Pericles and was employed in scenes depicting gatherings of women, wedding ceremonies, mythological heroines, or athletes. The motif appears once more on the Acropolis. Two partially naked youths appear on the west side of the Parthenon frieze. They rest their left leg on a rock; they bend down and tie their sandals with outstretched arms. It is hard to miss them; they face to the right whereas everyone else around them is facing left. The two images establish an unexpected and often overlooked relationship between the two buildings. \r\n",
                audioFile: "story_audio_English_1553376369.mp3",
                duration: 53088,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art", "Quirky"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2396,
            langId: 2,
            index: 30,
            imageFile: "tour_item_img_1559770094.jpg",
            thumbFile: "tour_item_thumb_1559770094.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559770094.jpg",
            lon: null,
            lat: null,
            name: "Two Nikai Leading a Bull to Sacrifice",
            secret:
              "What was the diet that the priestesses of Athena followed and what preparations did the people do to offer their sacrifices to the gods?  \r\n",
            stories: [
              {
                id: 8139,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868857.jpg",
                videoFile: "",
                title: "Directions",
                body: " Right next to the Sandal binder Nike, you’ll see the next exhibit, Two Nikai leading the bull to sacrifice, number Acr. 972. It is a block from the parapet of the temple.     \r\n\r\n",
                audioFile: "story_audio_EN_1557213696.mp3",
                duration: 18048,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7782,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "One hundred oxen",
                body: "The two Nikai leading a bull to sacrifice engage in a very important activity. Food was a popular offering to the gods and blood sacrifices were carefully planned rituals that culminated in the killing of an animal. During the festival of the Great Panathenaia, an hetacomb took place. This was the sacrifice of at least 100 oxen, an expensive proposition that only the community could undertake collectively. The most beautiful ox was reserved for a sacrifice to Athena Nike. Unlike oxen, goats were not welcome on the Acropolis. It was said that any olive tree bitten by a member of the goat family became sterile on account of the spittle being poisonous to the olive. The priestesses of Athena even refused to eat goat cheese.\r\n",
                audioFile: "story_audio_English_1553376386.mp3",
                duration: 57576,
                category: {
                  type: "Themes",
                  values: ["History", "Quirky"],
                },
                difficulty: "Mild",
              },
              {
                id: 7783,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "The sacrifice ritual",
                body: "The sacrifice began with the preparation, during which the participants ensured they were clean and gilded the horns of the animals. They wore their best clothes and lead the victim to the altar. There they cleaned themselves again with water and lit the fire. The priest poured water on the animal to make it nod, thus securing the victim's permission to be sacrificed. Then he removed some of the animal’s hair, threw them into the fire, and cut the animal’s throat. The blood was collected into a large bowl and then poured on the altar. The god received the thigh bones wrapped in fat; this portion was burned on the altar while wine was poured on the flames. The entrails were read for omens and the remaining meat was cooked and shared with the community.\r\n",
                audioFile: "story_audio_English_1553376405.mp3",
                duration: 58080,
                category: {
                  type: "Themes",
                  values: ["History", "Archeology"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2397,
            langId: 2,
            index: 31,
            imageFile: "tour_item_img_1559770113.jpg",
            thumbFile: "tour_item_thumb_1559770113.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559770113.jpg",
            lon: null,
            lat: null,
            name: "The Caryatids",
            secret:
              "The Caryatids are definitely the Hollywood stars of the Acropolis. Thousands of men fell under their mystifying spell, including a man called Lord Elgin. \r\n",
            stories: [
              {
                id: 8140,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868878.jpg",
                videoFile: "",
                title: "Directions",
                body: "Facing the two Nikai, move to your left and you will find on your left hand the pedestal upon which the 5 Caryatids are placed.  \r\n\r\n",
                audioFile: "story_audio_EN_1557213711.mp3",
                duration: 12528,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7784,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Six girls",
                body: "The Caryatids are famous. We know they are young girls that carry elaborate baskets on their heads and are entrusted with holding up the porch of the Erechtheion. Their hands are missing but we suspect they used to hold something with one of them and their garment with the other, in a posture typical of Archaic korai. Their hairstyle is elaborate and their peplos goes down to their feet. They are similar but not identical, each demonstrating differences in their hair and the folds of their drapery. The differences may be due to the various craftsmen who worked on them based on two original models. All six seem to move in a procession. And yet we do not know who they are. Building accounts of the period call them korai; the name caryatids does not appear anywhere. They are one of the loveliest mysteries of the Acropolis. \r\n",
                audioFile: "story_audio_English_1553376426.mp3",
                duration: 63072,
                category: {
                  type: "Themes",
                  values: ["History", "Archeology", "Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 7785,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "The abduction",
                body: "Thomas Bruce, 7th Earl of Elgin, ambassador to the Ottoman Empire (1799-1803), took advantage of Ottoman rule to remove and subsequently smuggle out of Greece parts of the Parthenon’s sculptural decoration. Faced with the enormous size of the blocks he desired to remove, and understanding the difficulties that would be involved in their transportation, Elgin elected to reduce their size with a saw. In the end, he removed 18 pedimental sculptures, 15 metopes and 56 frieze blocks, as well as one of the Caryatids. In 1816, these sculptures arrived at the British Museum in London. They comprise approximately 60% of the Parthenon’s surviving sculptures, while the remaining 40% can be found at the Acropolis Museum.\r\n",
                audioFile: "story_audio_English_1553376442.mp3",
                duration: 57576,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2398,
            langId: 2,
            index: 32,
            imageFile: "tour_item_img_1559770133.jpg",
            thumbFile: "tour_item_thumb_1559770133.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559770133.jpg",
            lon: null,
            lat: null,
            name: "Procne and Itys",
            secret:
              "You’ll never listen to the nightingale’s song the same way again once you’ve learned the tragic story of Procne and her sister. \r\n",
            stories: [
              {
                id: 8141,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868898.jpg",
                videoFile: "",
                title: "Directions",
                body: "Having at your back the Caryatids, you will move rightward and continue straight ahead. Once you’ve walked by the first colonnade, on your right hand you will see the marble statue Procne and Itys, number Acr. 1358. It represents a life-size female figure, her limbs missing, wearing a dress with many pleats.     ",
                audioFile: "story_audio_EN_1557213727.mp3",
                duration: 27552,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7786,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The student of Phidias",
                body: "The sculptor Alcamenes was born in Athens and was a pupil of Phidias. In this case, the apple didn't fall far from the tree, for the student was almost as famous as the teacher. He lived a long productive life, dying at the age of 70 (or thereabouts). His most famous work is the “Aphrodite of the Gardens”, a statue that received its last polish by Phidias himself. Pausanias, the famous geographer who visited Athens in the 2nd century CE, mentions the sculpture of Procne we see in the Acropolis Museum. Among the many statues and wonderful dedications on the Acropolis, he saw “Procne...who has already made up her mind about the boy, and Itys as well – a group dedicated by Alcamenes”. \r\n",
                audioFile: "story_audio_English_1553376460.mp3",
                duration: 56592,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 7787,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "The enormous crime",
                body: "Who is this Procne, though? She was the daughter of Pandion, the king of Athens, who gave her to Tereus, a Thracian king. When her husband raped her sister, the two women decided to exact a terrible revenge. Procne took Itys, her five-year-old son and struck him with a knife close to he heart as he cried out “Mother! Mother!” and stretched out his hands. The two sisters tore the child to pieces, cooked him, and served him to his father. When Procne revealed to him the contents of his meal Tereus grabbed his sword and tried to kill the two sisters, but the gods turned them to a nightingale (Procne) and a swallow (Philomela), while Tereus becomes a hoopoe. \r\n",
                audioFile: "story_audio_English_1553376477.mp3",
                duration: 54576,
                category: {
                  type: "Themes",
                  values: ["Mythology", "Quirky"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2399,
            langId: 2,
            index: 33,
            imageFile: "tour_item_img_1559770155.jpg",
            thumbFile: "tour_item_thumb_1559770155.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559770155.jpg",
            lon: null,
            lat: null,
            name: "The Lenormant Relief",
            secret:
              "The naval supremacy of the Athenians was irrefutable. Their fleet was a manifestation of military power and hegemony. \r\n",
            stories: [
              {
                id: 8142,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868912.jpg",
                videoFile: "",
                title: "Directions",
                body: " With the statue Procne and Itys on your right, you will see on the wall next to the colonnade an offering with a representation of a trireme, which is known as “the Lenormant Relief”, number Acr. 1339. It is partly preserved and it depicts a trireme and its rowers.      \r\n\r\n",
                audioFile: "story_audio_EN_1557213746.mp3",
                duration: 24048,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7788,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Row, row, row the boat ",
                body: "The so-called Lenormant relief was discovered by the French archaeologist François Lenormant on the Acropolis in 1856. It was carved in 400 BCE and represents a trireme, the most technologically advanced military machine created by the ancient Athenians. The fragment at the Acropolis Museum depicts the starboard side of the warship at the moment nine visible rowers put their weight into the stroke. Other fragments (some of them now lost) allow the reconstruction of 25 oarsmen, an officer, and some passengers, while the foot of a much larger figure above the trireme may have been the tribal hero Paralos. The ship has been identified as the “Paralus”, one of the two Athenian sacred ships.\r\n",
                audioFile: "story_audio_English_1553376515.mp3",
                duration: 59088,
                category: {
                  type: "Themes",
                  values: ["History", "Art"],
                },
                difficulty: "Mild",
              },
              {
                id: 7789,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "The sacred ships",
                body: "“Paralus” and “Salaminia” were the two state triremes during the classical period. During the Hellenistic periods, we hear of other sacred ships called “Antigonis”, “Demetrias”, and “Ammonias”. These ships were exceptionally speedy and served in sacred processions, carried embassies, messengers, or treasures from allied states, but also participated in naval battles under the command of an admiral. Interestingly enough, it appears that most Athenian ships were not named, and whenever it was necessary to differentiate them, people used the name of the owner or the captain. The Lenormant relief is also notable as a rare example in ancient Greek sculptural art where we can see individual sailors.\r\n",
                audioFile: "story_audio_English_1553376531.mp3",
                duration: 57240,
                category: {
                  type: "Themes",
                  values: ["Archeology", "Art"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2400,
            langId: 2,
            index: 34,
            imageFile: "tour_item_img_1559770173.jpg",
            thumbFile: "tour_item_thumb_1559770173.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559770173.jpg",
            lon: null,
            lat: null,
            name: "Artemis Brauronia",
            secret:
              "Who was the goddess protecting the women who were about to deliver? Artemis, the goddess of pregnancy and childbirth was also worshipped on the Acropolis rock. \r\n",
            stories: [
              {
                id: 8143,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868965.jpg",
                videoFile: "",
                title: "Directions",
                body: " Having the Lernormant Relief at your back, you will continue to the left and head to the center of the room, where you will find the next exhibit, the Artemis Brauronia, number Acr. 1352. It is an enormous head of goddess Artemis, whose nose is missing. ",
                audioFile: "story_audio_EN_1557213761.mp3",
                duration: 22032,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7790,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "A mysterious head ",
                body: "The colossal head (it is twice life size) is a mystery wrapped in a riddle. For many years scholars believed it depicted  Dionysus, the god of wine and drama. It was only after an exhaustive study of archaeological records, a 19th-century photograph, and the presence of the remnants of an earring that the archaeologist George Despinis was able to determine that this head of Parian marble depicts the goddess Artemis. The large size indicates that the head belonged to a cult statue; Pausanias noted that the sanctuary of Artemis on the Acropolis contained both a wooden statue (xoanon) and a stone statue carved by Praxiteles in 330 BCE. The nose, mouth, and eyes were probably smashed by the Christians.\r\n",
                audioFile: "story_audio_English_1553376547.mp3",
                duration: 58920,
                category: {
                  type: "Themes",
                  values: ["History", "Archeology", "Art"],
                },
                difficulty: "Easy",
              },
              {
                id: 7791,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "The bears ",
                body: "The main sanctuary of Artemis in Attica was in Brauron. There, the goddess was responsible for birth and children (especially girls). The goddess had power over life and death, as well as over the fertility of humans and animals. Pre-pubescent girls of aristocratic Athenian families would spend some time in her sanctuary at Brauron and participate in rites during which they played the bear (note the statue of a bear nearby). In the 6th century BCE the tyrant Pisistratus, who had been born in Brauron, transferred the cult to the Acropolis during a period when he tried to gain the support of farmers by elevating the importance of agrarian festivals. Soon, the cult statue of Artemis on the Acropolis became popular with pregnant women and young mothers who dedicated their clothes to the goddess. \r\n",
                audioFile: "story_audio_English_1553376562.mp3",
                duration: 61080,
                category: {
                  type: "Themes",
                  values: ["History", "Art"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2401,
            langId: 2,
            index: 35,
            imageFile: "tour_item_img_1559770191.jpg",
            thumbFile: "tour_item_thumb_1559770191.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559770191.jpg",
            lon: null,
            lat: null,
            name: "Portrait of Alexander the Great",
            secret:
              "Zeus and Alexander the Great discuss politics and agree on their spheres of influence. Sometimes a human can debate a god!\r\n",
            stories: [
              {
                id: 8144,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868981.jpg",
                videoFile: "",
                title: "Directions",
                body: " Looking at the front head of goddess Artemis, move forward to the intermediate space, to find the Portrait of Alexander the Great, number Acr. 1331. It is a bust of Alexander the Great at a young age. His nose is missing.      \r\n\r\n",
                audioFile: "story_audio_EN_1557213777.mp3",
                duration: 20544,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7792,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "Dividing the world",
                body: "The only artist authorized to make the image of Alexander the Great was the sculptor Lysippos of Sikyon. According to Plutarch, Lysippos was able to capture the distinctive features of Alexander, namely the poise of the neck turned slightly to the left and the “melting glance of the eyes”. Alexander used to gaze towards the sky as if he was challenging Zeus himself for dominion of the cosmos. The Macedonian king seemed to call upon Zeus to keep Mount Olympus, while he exercised control over the earth. Unfortunately, no original portrait of Alexander by Lysippos has survived; this one is considered a work of the sculptor Leochares and depicts Alexander with relatively short hair, unlike the heroic long mane that defines the copies of Lysippos’ portrait.\r\n",
                audioFile: "story_audio_English_1553376584.mp3",
                duration: 59088,
                category: {
                  type: "Themes",
                  values: ["History", "Art"],
                },
                difficulty: "Mild",
              },
              {
                id: 7793,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "The Persian shields",
                body: "The relations between Athens and Alexander the Great had always been fraught with difficulty, ever since the Athenians organized a spirited campaign against the plans of his father for dominion over the southern Greek city-states. Alexander repeatedly demonstrated his benevolence towards the city but he never truly entrusted the Athenians with important military tasks. The powerful Athenian navy contributed very little to Alexander’s conquests since the Macedonian king feared it would betray him. Nevertheless, Athens was an excellent vehicle for advertising his famous deeds. After his first great victory against the Persians in the battle of the Granicus River (May 334 BCE), Alexander sent 300 suits of Persian armour as a gift to Athena, while 14 bronze shields embellished the eastern facade of the Parthenon in memory of his triumph.\r\n",
                audioFile: "story_audio_English_1553376602.mp3",
                duration: 64080,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Mild",
              },
            ],
          },
          {
            id: 2402,
            langId: 2,
            index: 36,
            imageFile: "tour_item_img_1559770211.jpg",
            thumbFile: "tour_item_thumb_1559770211.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559770211.jpg",
            lon: null,
            lat: null,
            name: "Portrait of Emperor Lucius Verus",
            secret:
              "Roman Emperors loved adopting greek habits and “trends” in order to show off their sophistication. Growing a beard was also part of their predilection for the Greeks. \r\n",
            stories: [
              {
                id: 8145,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556868995.jpg",
                videoFile: "",
                title: "Directions",
                body: "Facing the bust of Alexander the Great, you will move forward and you will see on your right, behind the 2nd consecutive colonnade, the Portrait of Emperor Lucius Verus, number NAM 350. It depicts a bearded Roman figure.",
                audioFile: "story_audio_EN_1557213792.mp3",
                duration: 20544,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7794,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The naughty emperor",
                body: "Death opened (or should we say closed?) many doors to make Lucius Verus emperor. His father and an emperor (Antoninus Pius) had to perish before young Lucius ascended to the throne at the age of 30. He had been tutored by Cornelius Fronto, an orator considered inferior only to Cicero. Lucius succeeded to the throne along with Marcus Aurelius and soon set out for the East to fight the Persians. In Syria, he lived an unbridled life full of adulteries, love-affairs with young men, gambling, and visits to brothels and disreputable taverns. His commanders defeated the Persians in 166 CE, but Lucius only enjoyed his glory for a few years. In 169 CE he had a stroke and died at the age of 39.\r\n",
                audioFile: "story_audio_English_1553376618.mp3",
                duration: 62088,
                category: {
                  type: "Themes",
                  values: ["History", "Fun"],
                },
                difficulty: "Easy",
              },
              {
                id: 7795,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "Imperial fashion",
                body: "During the reign of Lucius Verus, members of the Roman elite followed a long tradition of immortalizing themselves in portrait statues. The portraits of members of the Imperial house became a benchmark that determined taste and fashion. The emperor officially commissioned his portrait, but workshops and artists were free to produce and market copies and casts of the original. The bust of Lucius Verus follows the conventions of the Antonine style. It depicts the emperor as an idealized bearded male; the hair and the beard are voluminous, with deep curls and corkscrew locks of hair. They express imperial admiration for Greek styles since the beard was initially foreign to the Romans.\r\n",
                audioFile: "story_audio_English_1553376633.mp3",
                duration: 53592,
                category: {
                  type: "Themes",
                  values: ["History", "Art"],
                },
                difficulty: "Mild",
              },
              {
                id: 7796,
                langId: 2,
                sequence_id: 4,
                imageFile: null,
                videoFile: "",
                title: "Looking manly and all",
                body: "The Greeks considered a long beard as a sure sign of manliness. Homeric heroes had beards and philosophers used theirs as a “calling card”; Socrates was famous for his luxurious facial hair. The Romans considered a long beard a sign of sloppiness and poverty, and celebrated the first time of shaving with great fanfare, often dedicating the hair to a god. During the reign of Emperor Hadrian, though, the beard became very popular. He was an ardent philhellene who grew a beard as a potent symbol of his adoption of Greek ideals. His fellow Romans also became enamored with Greek culture and intellectual achievements, leading to an impressive growth of learning throughout the empire. \r\n",
                audioFile: "story_audio_English_1553376650.mp3",
                duration: 54576,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Easy",
              },
            ],
          },
          {
            id: 2403,
            langId: 2,
            index: 37,
            imageFile: "tour_item_img_1559770227.jpg",
            thumbFile: "tour_item_thumb_1559770227.jpg",
            thumb_landscape_filename: "tour_item_land_thumb_1559770227.jpg",
            lon: null,
            lat: null,
            name: "Portrait of a Ruler",
            secret:
              "In the land of the Bosporan Kingdom there was once a handsome and brave prince who claimed to be the descendant of mighty Hercules. \r\n",
            stories: [
              {
                id: 8146,
                langId: 2,
                sequence_id: 1,
                imageFile: "story_img_1556869007.jpg",
                videoFile: "",
                title: "Directions",
                body: "Having the Portrait of Lucius Verus at your back, you will continue straight and to the left walking past a colonnade and you will find the next point of interest. It is the Portrait of the Ruler Sauromates, number NAM 419. It represents a bearded man with long hair side-glancing. ",
                audioFile: "story_audio_EN_1557213807.mp3",
                duration: 26064,
                category: {
                  type: "Info",
                  values: ["Directions"],
                },
                difficulty: "Easy",
              },
              {
                id: 7797,
                langId: 2,
                sequence_id: 2,
                imageFile: null,
                videoFile: "",
                title: "The handsome prince ",
                body: "Sauromates II was the ruler of the Bosporan Kingdom, a state located in eastern Crimea where a mixed population adopted the Greek language and culture and thrived on the export of wheat, fish, and slaves. As Romans came to dominate the Mediterranean world, the Bosporan Kingdom became a client state of Rome. Sauromates II claimed that he was a descendant of Hercules and appears to have been a handsome king. His reign brought a long period of peace to an abrupt end; he fought many wars with the natives and won victories against the Scythians and the Siraces, a hellenized tribe that inhabited the lands north of the Caucasus Mountains. He died in 210/211 CE.\r\n",
                audioFile: "story_audio_English_1553376665.mp3",
                duration: 55080,
                category: {
                  type: "Themes",
                  values: ["History"],
                },
                difficulty: "Easy",
              },
              {
                id: 8147,
                langId: 2,
                sequence_id: 3,
                imageFile: null,
                videoFile: "",
                title: "Closure",
                body: "At this point, the tour ended. In the Gallery of the Slopes of the Acropolis, you admired finds from the sanctuaries that were founded on the slopes of the Acropolis, as well as from the settlement that developed at the foot of the hill. You saw archaic exhibits, and a variety of architectural sculptures, and free excerpts, including the Kores. You have observed in detail the Parthenon’s sculptural decoration, as well as the sculptures of the Athena Nike's temple atrium and the famous Caryatids of the Erechtheion. Last but not least you saw statues and votive offerings from the Acropolis hill covering a period from the 5th century BC to the 3rd century AD. Well done that you made it!\r\n",
                audioFile: null,
                duration: null,
                category: {
                  type: "Info",
                  values: ["Closure"],
                },
                difficulty: "Easy",
              },
            ],
          },
        ],
        userAccess: true,
        categories: ["History", "Walking", "Museum"],
        stops: 37,
        stories: 100,
      },
      manifest: {
        short_name: "Clio Muse",
        name: "Clio Muse Tour",
        icons: [
          {
            src: "/192192icon192.png",
            type: "image/png",
            sizes: "192x192",
          },
          {
            src: "/512512icon512.png",
            type: "image/png",
            sizes: "512x512",
          },
        ],
        id: "/?source=pwa",
        start_url: "/?source=pwa",
        background_color: "#FFFFFF",
        display: "standalone",
        scope: "/",
        theme_color: "#FFFFFF",
        description: "Clio Muse Tour",
      },
    },
  },
};

// util

store.lastUid = Math.round(Math.random() * 10000);
store.generateUid = function () {
  return "" + store.lastUid++;
};

store.getJsonPath = function (json, path) {
  if (json === null || json === undefined || !path) {
    return null;
  }

  let parts = [];
  if (path) {
    if (typeof path !== "string") {
      path = "" + path;
    }
    parts = path.split(".");
  }

  let part;
  for (let i = 0; i < parts.length; i++) {
    part = parts[i];
    json = json[part];
    if (json === null || json === undefined) {
      return null;
    }
  }

  return json;
};

store.setJsonPath = function (json, path, value) {
  if (!json) {
    json = {};
  }

  let parts = [];
  if (path) {
    if (typeof path !== "string") {
      path = "" + path;
    }
    parts = path.split(".");
  } else {
    json = value;
  }

  let property = json;
  let part;
  for (let i = 0; i < parts.length; i++) {
    part = parts[i];
    if (part) {
      if (i < parts.length - 1) {
        if (property[part] === null || property[part] === undefined) {
          property = property[part] = {};
        } else {
          property = property[part];
        }
      } else {
        property = property[part] = value;
      }
    }
  }

  return json;
};

// clone

function copyBuffer(cur) {
  if (cur instanceof ArrayBuffer) {
    return ArrayBuffer.from(cur);
  }

  return new cur.constructor(cur.buffer.slice(), cur.byteOffset, cur.length);
}

function cloneArray(a, fn) {
  var keys = Object.keys(a);
  var a2 = new Array(keys.length);
  for (var i = 0; i < keys.length; i++) {
    var k = keys[i];
    var cur = a[k];
    if (typeof cur !== "object" || cur === null) {
      a2[k] = cur;
    } else if (cur instanceof Date) {
      a2[k] = new Date(cur);
    } else if (ArrayBuffer.isView(cur)) {
      a2[k] = copyBuffer(cur);
    } else {
      a2[k] = fn(cur);
    }
  }
  return a2;
}

function clone(o) {
  if (typeof o !== "object" || o === null) return o;
  if (o instanceof Date) return new Date(o);
  if (Array.isArray(o)) return cloneArray(o, clone);
  if (o instanceof Map) return new Map(cloneArray(Array.from(o), clone));
  if (o instanceof Set) return new Set(cloneArray(Array.from(o), clone));
  var o2 = {};
  for (var k in o) {
    if (Object.hasOwnProperty.call(o, k) === false) continue;
    var cur = o[k];
    if (typeof cur !== "object" || cur === null) {
      o2[k] = cur;
    } else if (cur instanceof Date) {
      o2[k] = new Date(cur);
    } else if (cur instanceof Map) {
      o2[k] = new Map(cloneArray(Array.from(cur), clone));
    } else if (cur instanceof Set) {
      o2[k] = new Set(cloneArray(Array.from(cur), clone));
    } else if (ArrayBuffer.isView(cur)) {
      o2[k] = copyBuffer(cur);
    } else {
      o2[k] = clone(cur);
    }
  }
  return o2;
}

store.clone = clone;

// Session store

store.getSession = (path, getClone) => {
  if (path) {
    path = path.replace(/'/g, "");
    path = path.replace(/"/g, "");
  }
  let value = path ? store.getJsonPath(store.state.session, path) : null;
  if (getClone) {
    value = store.clone(value);
  }
  return value;
};

let subscriptions = {};
store.subscribeSession = (path, callback) => {
  let id = store.generateUid();
  subscriptions[id] = { path, callback };

  return () => {
    delete subscriptions[id];
  };
};

store.setSession = (path, value) => {
  if (!path) {
    return;
  }

  store.setJsonPath(store.state.session, path, value);

  // setTimeout(() => {
  for (let id in subscriptions) {
    let subscription = subscriptions[id];
    if (subscription.path) {
      if (path.startsWith(subscription.path)) {
        subscription.callback(value, path);
      }
    }
  }
  // });
};

const useSessionValue = (path) => {
  function subscribe(listener) {
    return store.subscribeSession(path, listener);
  }

  return useSyncExternalStore(
    subscribe,
    () => store.getSession(path),
    () => null
  );
};

const useSessionState = (path) => {
  return [useSessionValue(path), (value) => store.setSession(path, value)];
};

// Dynamic assets

store.dynamicAsset = (name) => {
  if (typeof name == "string") {
    if (
      name &&
      (name.startsWith("http") ||
        name.startsWith("http") ||
        name.startsWith("data:") ||
        name.startsWith("blob:") ||
        name.startsWith("/"))
    ) {
      return name;
    } else if (name) {
      let asset = store.getSession("assets." + name);
      if (asset) {
        return asset.file || asset.url;
      }
    }
  }
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
};

// Persistent store

store.setPersistent = async function (name, value) {
  localStorage.setItem(name, value);
};

store.getPersistent = async function (name) {
  return localStorage.getItem(name);
};

store.deletePersistent = async function (name) {
  localStorage.removeItem(name);
};

const useStateRef = (initialValue) => {
  const [currentValue, _setCurrentValue] = useState(initialValue);

  const currentValueRef = useRef(currentValue);

  const setCurrentValue = (val) => {
    currentValueRef.current = val;
    _setCurrentValue(val);
  };

  return [currentValue, setCurrentValue, currentValueRef];
};

const useSessionStateRef = (path) => {
  const [currentValue, _setCurrentValue] = useSessionState(path);
  const currentValueRef = useRef(currentValue);
  const setCurrentValue = (val) => {
    currentValueRef.current = val;
    _setCurrentValue(val);
  };

  useEffect(() => {
    setCurrentValue(currentValue);
  }, [currentValue]);

  return [currentValue, setCurrentValue, currentValueRef];
};

export {
  store,
  useStateRef,
  useSessionValue,
  useSessionState,
  useSessionStateRef,
};
