

import { util } from "../../util/index";
import { store, useStateRef } from "../../util/store";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useCallback } from "react";
import {
  getTourMediaUrl,
  getTourData,
} from "../CallApi";

import "./OpenedTourItem.css";

const OpenedTourItem = (props) => {
  let navigate = useNavigate();

  const [tourThumbnail, setTourThumbnail] = useStateRef();

  const [OpenedTour, setOpenedTour, OpenedTourRef] = useStateRef();

  const openTour = useCallback(async () => {
    let tour = await getTourData(
      OpenedTourRef.current?.["id"],
      OpenedTourRef.current?.["langId"]
    );
    store.setSession("tour_data", tour);

    const code = tour?.["bookingCode"] ? `&code=${tour?.["bookingCode"]}` : "";
    navigate(
      "/tour" +
      [
        "?id=",
        OpenedTourRef.current?.["id"],
        "&lang=",
        OpenedTourRef.current?.["langId"],
        code
      ].join("")
    );
  }, []);

  useEffect(() => {
    const initialize = async () => {
      setOpenedTour(props?.["OpenedTour"]);
      setTourThumbnail(
        await getTourMediaUrl(OpenedTourRef.current, "thumbFile")
      );
    };
    initialize().then(() => { });
  }, [props]);

  return (
    <div className="opened-tour-item-start-container" onClick={openTour}>
      <img
        className="opened-tour-item-image"
        src={store.dynamicAsset(tourThumbnail)}
      />
      <span className="opened-tour-item-start-text">
        {util.getLocalizedMessage(OpenedTour?.name)}
      </span>
    </div>
  );
};

export default OpenedTourItem;
