import { useState, useEffect } from "react";
import { useWindowSize } from "@react-hook/window-size";

import { store } from "../../util/store";
import { isOnboarded, setOnboarded, playAudio } from "../CallApi";

const onboardingZindex = 10000;
const textWidth = 250;
const textHeight = 22 * 3;
const iconSize = 30;

const Onboarding = ({ page }) => {
  const [windowWidth] = useWindowSize();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [show, setShow] = useState(false);
  const [textPosition, setTextPosition] = useState({});
  const [iconPosition, setIconPosition] = useState({});

  const onboardings = {
    map: [
      {
        navigationBottom: "0px",
        targetSelector: ".mapboxgl-ctrl-top-right",
        textPosition: "below",
        text: "If you have enabled location services, tap here to see your current location in the tour.",
        borderRadius: 16,
        showPulse: false,
      },
      {
        navigationBottom: "0px",
        targetSelector: ".first-poi-marker",
        textPosition: "below",
        text: "Explore the tour route by zooming in and out, and tap on a location to see more details about the stop.",
        borderRadius: 32,
        showPulse: false,
      },
      {
        navigationBottom: "0px",
        targetSelector: ".swiper-slide-active .poi-carousel-playable div",
        textPosition: "above",
        text: "Tap on a stop to preview all the available stories.",
        borderRadius: 16,
        showPulse: true,
      }
    ],
    "map-indoor": [
      {
        navigationBottom: "0px",
        targetSelector: ".swiper-slide-active .poi-carousel-playable div",
        textPosition: "above",
        text: "Tap on a stop to preview all the available stories.",
        borderRadius: 16,
        showPulse: true,
      }
    ],
    items: [
      {
        navigationTop: "0px",
        targetSelector: ".swiper-slide-active",
        textPosition: "below",
        icon: store.dynamicAsset("OnboardingSwipe"),
        text: "swipe left or right to move into the previous or next stop.",
        borderRadius: 16,
        showPulse: true,
      },
      {
        navigationBottom: "0px",
        targetSelector: ".items-map-icon",
        textPosition: "below",
        text: "Tap here to navigate back to the tour's map.",
        borderRadius: 16,
        showPulse: true,
      },
      {
        navigationTop: "0px",
        targetSelector: ".player-control-mini-grab",
        textPosition: "above",
        text: "Tap here to view more details about the story.",
        borderRadius: 0,
        showPulse: true,
      },
      {
        navigationTop: "0px",
        beforeClickSelector: ".player-control-mini-grab",
        beforeClickWait: 1000,
        targetSelector: ".story-text-more-button",
        textPosition: "above",
        text: "Tap on More to view the full text of the story.",
        afterClickSelector: ".player-control-grab-2",
        borderRadius: 16,
        showPulse: true,
      }
    ],
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const setElementHighlight = (slide, highlight) => {
    let selector = slide.targetSelector;

    let element = document.querySelector(selector);
    if (element) {
      const cloneId = "onboarding-target";
      let lastHighlight = document.querySelector("#" + cloneId);
      if (lastHighlight) {
        lastHighlight.remove();
      }

      if (highlight) {
        let bounds = element.getBoundingClientRect();

        let container = document.querySelector(".onboarding-container");
        let clone = element.cloneNode(true);
        clone.id = cloneId;
        clone.style.position = "absolute";
        clone.style.width = bounds.witdh + "px";
        clone.style.height = bounds.height + "px";
        clone.style.top = bounds.top + "px";
        clone.style.left = bounds.left + "px";
        clone.style.transform = "none";
        clone.style["pointer-events"] = "none";
        clone.style["user-select"] = "none";
        if (slide.showPulse) {
          clone.style["border-radius"] = (slide.borderRadius ?? 0) + "px";
          clone.classList.add("pulse");
        }
        container.appendChild(clone);

        let pos = {
          left: bounds.left < windowWidth / 2 ? (bounds.right + 10) + "px" : (bounds.left - 10 - textWidth) + "px",
        };
        if (parseInt(pos.left) < 0) {
          pos.left = "30px";
        } else if (parseInt(pos.left) + textWidth > windowWidth) {
          pos.left = (windowWidth - textWidth - 30) + "px";
        }

        if (slide.textPosition == "above") {
          pos.top = (bounds.top - 10 - textHeight) + "px";
        } else if (slide.textPosition == "below") {
          pos.top = (bounds.bottom + 10) + "px";
        }

        if (slide.icon) {
          setIconPosition(pos);
          if (slide.textPosition == "above") {
            setTextPosition({ ...pos, top: parseInt(pos.top) - iconSize - 10 });
          } else if (slide.textPosition == "below") {
            setTextPosition({ ...pos, top: parseInt(pos.top) + iconSize + 10 });
          }
        } else {
          setIconPosition({});
          setTextPosition(pos);
        }
      }
    }
  }

  useEffect(() => {
    const initialize = async () => {
      let slides = onboardings[page];

      let actualPage = page?.startsWith("map") ? "map" : page;
      if (slides) {
        let onboarded = await isOnboarded(actualPage);
        if (!onboarded) {
          setShow(true);

          await sleep(250);
          setElementHighlight(slides[0], true);
        }
      }
    }
    initialize();
  }, [page]);

  const onNext = async () => {
    let slides = onboardings[page];

    let previousSlide = slides[currentSlide];
    await sleep(250);
    setElementHighlight(previousSlide, false);
    if (previousSlide.afterClickSelector) {
      let clickTarget = document.querySelector(previousSlide.afterClickSelector);
      if (clickTarget) {
        clickTarget.click();
        await sleep(250);
      }
    }

    if (currentSlide + 1 < onboardings[page]?.length) {
      setCurrentSlide(currentSlide + 1);
      let nextSlide = slides[currentSlide + 1];
      if (nextSlide.beforeClickSelector) {
        await sleep(250);
        let clickTarget = document.querySelector(nextSlide.beforeClickSelector);
        if (clickTarget) {
          clickTarget.click();
          await sleep(nextSlide.beforeClickWait ?? 250);
        }
      }
      await sleep(250);
      setElementHighlight(nextSlide, true);
    } else {
      let actualPage = page?.startsWith("map") ? "map" : page;
      await setOnboarded(actualPage);
      setShow(false);

      playAudio(store.getSession("current_item"));
    }
  }

  return show ? (
    <div className={"onboarding-container"} onClick={onNext} style={{
      position: "absolute",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
      background: "rgba(31, 34, 42,0.87)",
      zIndex: onboardingZindex,
    }}>
      <div style={{
        position: "absolute",
        top: onboardings?.[page]?.[currentSlide]?.navigationTop ?? null,
        bottom: onboardings?.[page]?.[currentSlide]?.navigationBottom ?? null,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "calc(100% - 60px)",
        padding: "30px",
      }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: (onboardings?.[page]?.length ?? 0) * 17 + "px" }}>
          {onboardings[page] ? onboardings[page].map((p, index) => <div key={index} style={{ background: index == currentSlide ? "red" : "white", width: "10px", height: "10px", borderRadius: "5px" }}></div>) : <></>}
        </div>
        <div style={{ color: "white", fontSize: "18px", fontWeight: "bold" }} onClick={onNext}>Next</div>
      </div>
      {onboardings?.[page]?.[currentSlide]?.icon && <img style={{
        position: "absolute",
        top: iconPosition.top,
        left: iconPosition.left,
        right: iconPosition.right,
        bottom: iconPosition.bottom,
        width: iconSize + "px",
        height: iconSize + "px"
      }} src={onboardings?.[page]?.[currentSlide]?.icon}></img>}
      <div style={{
        position: "absolute",
        textAlign: "center",
        color: "white",
        fontSize: "18px",
        fontWeight: "bold",
        width: textWidth + "px",
        top: textPosition.top,
        left: textPosition.left,
        right: textPosition.right,
        bottom: textPosition.bottom
      }}>{onboardings?.[page]?.[currentSlide]?.text ?? ""}</div>
    </div>
  ) : <></>
};

export default Onboarding;
