import { useRef, useLayoutEffect } from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

const ImageViewer = ({ src }) => {
  const transformComponentRef = useRef();

  useLayoutEffect(() => {
    setTimeout(() => {
      if (transformComponentRef.current) {
        const { zoomToElement } = transformComponentRef.current;
        zoomToElement("viewed-image");
      }
    }, 250);
  }, []);

  return (
    <TransformWrapper ref={transformComponentRef} limitToBounds={true} centerOnInit={true} centerZoomedOut={false} minScale={0} style={{ width: "100%", height: "100%", maxWidth: "100%", maxHeight: "100%" }}>
      <TransformComponent wrapperStyle={{ width: "100%", height: "100%", maxWidth: "100%", maxHeight: "100%" }}>
        <img id="viewed-image" src={src} />
      </TransformComponent>
    </TransformWrapper>
  );
}

export default ImageViewer;
