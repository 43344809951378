import { util } from "../../util";
import { useCallback } from "react";

import "./Modal.css";
import AssetInfo from "../../assets/Info.svg";
import { useSessionState } from "../../util/store";

const Modal = (props) => {
  const [globalStyles] = useSessionState("globalStyles");

  const onOkTapped = useCallback(async () => {
    (props?.["onOk"] ?? (() => { }))();
  }, []);

  const onCancelTapped = useCallback(async () => {
    (props?.["onCancel"] ?? (() => { }))();
  }, []);

  return (
    <div className="modal-backdrop-container">
      <div className="modal-box-container">
        <img className="modal-image" src={AssetInfo} />
        <span className="modal-text">
          {util.getLocalizedMessage(props?.message)}
        </span>

        <div className="modal-button-container">
          {props?.cancelLabel && (
            <div className="modal-cancel-button" style={{ borderColor: globalStyles?.modal?.cancelColor }} onClick={onCancelTapped}>
              <span className="modal-cancel-button-text" style={{ color: globalStyles?.modal?.cancelColor }}>
                {util.getLocalizedMessage(props?.cancelLabel)}
              </span>
            </div>
          )}

          <div className="modal-ok-button" style={{ backgroundColor: globalStyles?.modal?.okColor }} onClick={onOkTapped}>
            <span className="modal-ok-button-text" style={{ color: globalStyles?.modal?.okTextColor }}>
              {util.getLocalizedMessage(props?.okLabel ?? "OK")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
