import { Children, useState, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const ItemCarousel = ({ index, onIndexChange, children, ...containerProps }) => {
  const arrayChildren = Children.toArray(children);

  const [swiper, setSwiper] = useState();

  const onActiveIndexChange = (swiper) => {
    if (onIndexChange) {
      onIndexChange(swiper.activeIndex + 1);
    }
  };

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(index - 1, 0);
    }
  }, [index]);

  const createSlide = (child, index) => {
    return (<SwiperSlide key={index} style={{ width: "80%" }}>{child}</SwiperSlide>)
  }

  return (
    <div {...containerProps}>
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={30}
        edgeSwipeDetection={"prevent"}
        touchMoveStopPropagation={true}
        touchAngle={45}
        onActiveIndexChange={onActiveIndexChange}
        onSwiper={setSwiper}
      >
        {Children.map(arrayChildren, (child, index) => createSlide(child, index))}
      </Swiper>
    </div>
  );
};

export default ItemCarousel;
