import { useState, useEffect } from "react";

import Checkbox from '@mui/material/Checkbox';

const MuiCheckbox = ({ label, value, onChange }) => {
  const [theValue, setTheValue] = useState(value);

  const handleChange = (event) => {
    let newValue = event.target.checked;
    setTheValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  }

  useEffect(() => {
    setTheValue(value);
  }, [value]);

  return (
    <Checkbox
      checked={theValue ?? false}
      label={label}
      onChange={handleChange}
    />
  );
}

export default MuiCheckbox;
