import Color from "color";

import { store } from "./store";
import { getStyleConfig } from "../components/CallApi";

const clioBrandColor = "#e35056";

const updateFontStyle = (globalStyles, partner) => {
  let fontFamily = "Commissioner, sans-serif";
  let fontCssUrl = "";

  if (partner?.brand?.font_family) {
    fontFamily = partner.brand.font_family;
  }
  if (partner?.brand?.font_css_file) {
    fontCssUrl = partner.brand.font_css_file;
  }

  return {
    ...globalStyles,
    fontFamily,
    fontCssUrl,
  }
}

const updateLoaderStyle = (globalStyles, primaryColor) => {
  const loader = {
    progressColor: primaryColor ?? "#2fcc71",
    buttonColor: primaryColor ?? "#e35056",
  };

  return {
    ...globalStyles,
    loader
  }
}

const updateModalStyle = (globalStyles, primaryColor) => {
  const modal = {
    cancelColor: "#9b9b9b",
    okColor: primaryColor ?? clioBrandColor,
    okTextColor: "#ffffff",
  };

  return {
    ...globalStyles,
    modal
  }
}

const updateTourStyle = (globalStyles, primaryColor /*, partner */) => {
  const tour = {
    startPointIconColor: primaryColor ?? clioBrandColor,
    finishPointIconColor: "#232323",
    startContainerColor: primaryColor ?? "#0a1339",
    startContainerTextColor: "#ffffff",
    showPoweredBy: true, // !!partner,
  };

  return {
    ...globalStyles,
    tour
  }
}

const updateMapStyle = (globalStyles, primaryColor) => {
  const map = {
    markerColor: primaryColor ?? clioBrandColor,
    playButtonColor: {
      front: "#ffffff",
      back: primaryColor ?? clioBrandColor,
    },
    storiesBadgeColor: primaryColor ? "#161616" : "#1D2548",
  };

  return {
    ...globalStyles,
    map
  }
}

const updatePlayerStyle = (globalStyles, primaryColor, partner) => {
  const theme = partner ? partner?.brand?.theme ?? "light" : null;

  const player = {
    backgroundMini: partner ? "#161616" : "#1d2548",
    backgroundFull: "#0a1339",
    showPoweredBy: true, // !!partner,
    progressColor: primaryColor ?? clioBrandColor,
    buttonColor: "#cdcdcd",
    playButtonColor: {
      front: "#cdcdcd",
      back: Color("#cdcdcd").alpha(0.2).hexa()
    },
    titleColor: "#ffffff",
    textColor: "#cdcdcd",
  };

  if (theme != null) {
    if (theme == "dark") {
      player.backgroundFull = "#161616";
      player.buttonColor = "#cdcdcd";
      player.playButtonColor = {
        front: "#cdcdcd",
        back: Color("#cdcdcd").alpha(0.2).hexa()
      }
      player.titleColor = "#ffffff";
      player.textColor = "#cdcdcd";
    } else if (theme == "light") {
      player.backgroundFull = "#ffffff";
      player.buttonColor = primaryColor ?? "#cdcdcd";
      player.playButtonColor = {
        front: "#ffffff",
        back: Color(primaryColor ?? "#cdcdcd").alpha(0.2).hexa(),
      }
      player.titleColor = "#161616";
      player.textColor = "#161616";
    }
  }

  return {
    ...globalStyles,
    player
  }
}

const updateStoryTextStyle = (globalStyles, primaryColor, partner) => {
  const storyText = primaryColor
    ? {
      info: {
        iconColor: primaryColor,
        titleColor: "#161616",
      },
      themes: {
        iconColor: primaryColor,
        titleColor: "#161616",
      },
      tips: {
        iconColor: primaryColor,
        titleColor: "#161616",
      },
      actions: {
        iconColor: primaryColor,
        titleColor: "#161616",
      },
    }
    : {
      info: {
        iconColor: "#F2AF29",
        titleColor: "#F2AF29",
      },
      tips: {
        iconColor: "#AD343E",
        titleColor: "#AD343E",
      },
      actions: {
        iconColor: "#AD343E",
        titleColor: "#AD343E",
      },
      themes: {
        iconColor: "#5296A5",
        titleColor: "#5296A5",
      },
    }

  if (partner?.brand?.info_color_hex) {
    storyText.info = {
      iconColor: "#" + partner.brand.info_color_hex,
      titleColor: "#161616",
    };
  }

  if (partner?.brand?.tip_color_hex) {
    storyText.tips = {
      iconColor: "#" + partner.brand.tip_color_hex,
      titleColor: "#161616",
    };
    storyText.actions = {
      iconColor: "#" + partner.brand.tip_color_hex,
      titleColor: "#161616",
    };
  }

  for (const key in storyText) {
    storyText[key].backgroundColor = storyText[key].iconColor;
    storyText[key].moreColor = Color(storyText[key].iconColor).alpha(0.9).hexa();
  }

  return {
    ...globalStyles,
    storyText
  }
}

const updateFullStoryTextStyle = (globalStyles, primaryColor, partner) => {
  const theme = partner ? partner?.brand?.theme ?? "light" : null;
  globalStyles.theme = theme;

  const playAlpha = 0.2;

  const fullStoryText = primaryColor
    ? {
      info: {
        iconColor: primaryColor,
        titleColor: "#161616",
        playButtonColor: {
          front: "#ffffff",
          back: Color(primaryColor).alpha(playAlpha).hexa()
        },
      },
      themes: {
        iconColor: primaryColor,
        titleColor: "#161616",
        playButtonColor: {
          front: "#ffffff",
          back: Color(primaryColor).alpha(playAlpha).hexa()
        },
      },
      tips: {
        iconColor: primaryColor,
        titleColor: "#161616",
        playButtonColor: {
          front: "#ffffff",
          back: Color(primaryColor).alpha(playAlpha).hexa()
        },
      },
      actions: {
        iconColor: primaryColor,
        titleColor: "#161616",
        playButtonColor: {
          front: "#ffffff",
          back: Color(primaryColor).alpha(playAlpha).hexa()
        },
      },
    }
    : {
      info: {
        iconColor: "#F2AF29",
        titleColor: "#ffffff",
        textColor: "#161616",
        playButtonColor: {
          front: "#ffffff",
          back: Color("#000000").alpha(playAlpha).hexa()
        },
      },
      tips: {
        iconColor: "#AD343E",
        titleColor: "#ffffff",
        textColor: "#161616",
        playButtonColor: {
          front: "#ffffff",
          back: Color("#000000").alpha(playAlpha).hexa()
        },
      },
      actions: {
        iconColor: "#AD343E",
        titleColor: "#ffffff",
        textColor: "#161616",
        playButtonColor: {
          front: "#ffffff",
          back: Color("#000000").alpha(playAlpha).hexa()
        },
      },
      themes: {
        iconColor: "#5296A5",
        titleColor: "#ffffff",
        textColor: "#161616",
        playButtonColor: {
          front: "#ffffff",
          back: Color("#000000").alpha(playAlpha).hexa()
        },
      },
    };

  if (theme == null) {
    for (const key in fullStoryText) {
      fullStoryText[key].backgroundColor = fullStoryText[key].iconColor;
      fullStoryText[key].timeColor = fullStoryText[key].textColor;
    }
  } else if (theme == "light") {
    if (partner?.brand?.info_color_hex) {
      fullStoryText["info"].iconColor = "#" + partner.brand.info_color_hex;
      fullStoryText["info"].playButtonColor.back = Color(fullStoryText["info"].iconColor).alpha(playAlpha).hexa();
    }
    if (partner?.brand?.tip_color_hex) {
      fullStoryText["tips"].iconColor = "#" + partner.brand.tip_color_hex;
      fullStoryText["tips"].playButtonColor.back = Color(fullStoryText["tips"].iconColor).alpha(playAlpha).hexa();

      fullStoryText["actions"].iconColor = "#" + partner.brand.tip_color_hex;
      fullStoryText["actions"].playButtonColor.back = Color(fullStoryText["actions"].iconColor).alpha(playAlpha).hexa();
    }

    for (const key in fullStoryText) {
      fullStoryText[key].backgroundColor = fullStoryText[key].iconColor;
      fullStoryText[key].timeColor = Color(fullStoryText[key].iconColor);
    }
  } else if (theme == "dark") {
    if (partner?.brand?.info_color_hex) {
      fullStoryText["info"].iconColor = "#" + partner.brand.info_color_hex;
    }
    if (partner?.brand?.tip_color_hex) {
      fullStoryText["tips"].iconColor = "#" + partner.brand.tip_color_hex;
      fullStoryText["actions"].iconColor = "#" + partner.brand.tip_color_hex;
    }

    for (const key in fullStoryText) {
      fullStoryText[key].backgroundColor = "#161616";
      fullStoryText[key].textColor = "rgba(255, 255, 255, 0.7)";
      fullStoryText[key].titleColor = "#ffffff";
      fullStoryText[key].timeColor = fullStoryText[key].textColor;
      fullStoryText[key].playButtonColor = {
        front: "#cdcdcd",
        back: Color("#cdcdcd").alpha(playAlpha).hexa()
      };
    }
  }

  return {
    ...globalStyles,
    fullStoryText
  }
}

const updateStoryItemStyle = (globalStyles, primaryColor, partner) => {
  const storyItem = primaryColor
    ? {
      info: {
        iconColor: primaryColor,
        titleColor: "#161616",
        playAnimationColor: primaryColor,
        backgroundColor: "#ffffff",
        activeBorderColor: primaryColor,
        borderColor: "#b1b1b1"
      },
      tips: {
        iconColor: primaryColor,
        titleColor: "#161616",
        playAnimationColor: primaryColor,
        backgroundColor: "#ffffff",
        activeBorderColor: primaryColor,
        borderColor: "#b1b1b1"
      },
      actions: {
        iconColor: primaryColor,
        titleColor: "#161616",
        playAnimationColor: primaryColor,
        backgroundColor: "#ffffff",
        activeBorderColor: primaryColor,
        borderColor: "#b1b1b1"
      },
      themes: {
        iconColor: primaryColor,
        titleColor: "#161616",
        playAnimationColor: primaryColor,
        backgroundColor: "#ffffff",
        activeBorderColor: primaryColor,
        borderColor: "#b1b1b1"
      },
    }
    : {
      info: {
        iconColor: "#F2AF29",
        titleColor: "#F2AF29",
        playAnimationColor: clioBrandColor,
        backgroundColor: Color("#F2AF29").alpha(0.3).hexa(),
        activeBorderColor: "#F2AF29",
        borderColor: "#b1b1b1"
      },
      tips: {
        iconColor: "#AD343E",
        titleColor: "#AD343E",
        playAnimationColor: clioBrandColor,
        backgroundColor: Color("#AD343E").alpha(0.3).hexa(),
        activeBorderColor: "#AD343E",
        borderColor: "#b1b1b1"
      },
      actions: {
        iconColor: "#AD343E",
        titleColor: "#AD343E",
        playAnimationColor: clioBrandColor,
        backgroundColor: Color("#AD343E").alpha(0.3).hexa(),
        activeBorderColor: "#AD343E",
        borderColor: "#b1b1b1"
      },
      themes: {
        iconColor: "#5296A5",
        titleColor: "#5296A5",
        playAnimationColor: clioBrandColor,
        backgroundColor: '#ffffff',
        activeBorderColor: "#5296A5",
        borderColor: "#b1b1b1"
      },
    };

  if (partner?.brand?.info_color_hex) {
    storyItem["info"].iconColor = "#" + partner.brand.info_color_hex;
    storyItem["info"].activeBorderColor = "#" + partner.brand.info_color_hex;
    storyItem["info"].playAnimationColor = "#" + partner.brand.info_color_hex;
  }
  if (partner?.brand?.tip_color_hex) {
    storyItem["tips"].iconColor = "#" + partner.brand.tip_color_hex;
    storyItem["tips"].activeBorderColor = "#" + partner.brand.tip_color_hex;
    storyItem["tips"].playAnimationColor = "#" + partner.brand.tip_color_hex;

    storyItem["actions"].iconColor = "#" + partner.brand.tip_color_hex;
    storyItem["actions"].activeBorderColor = "#" + partner.brand.tip_color_hex;
    storyItem["actions"].playAnimationColor = "#" + partner.brand.tip_color_hex;
  }

  return {
    ...globalStyles,
    storyItem
  }
}

const updateOtherStyle = (globalStyles, primaryColor, partner) => {
  const other = {
    splash: partner?.brand?.type ?? "cliomuse",
    logos: {
      main: partner?.logos?.main ?? "",
      small: partner?.logos?.small ?? "",
      medium: partner?.logos?.medium ?? "",
    },
    skipLogin: partner?.authentication == false,
  }

  return {
    ...globalStyles,
    ...other
  }
}

const updateGlobalStyles = async (id, code) => {
  let globalStyles = store.getSession("globalStyles");
  if (globalStyles.loader && globalStyles.id == id && globalStyles.code == code) {
    return;
  }

  globalStyles = {
    id,
    code,
  }

  const config = await getStyleConfig(id, code);
  const partner = config?.partner;
  globalStyles.partner = partner;

  let primaryColor = partner?.brand?.primary_color_hex;
  if (primaryColor) {
    primaryColor = "#" + primaryColor;
  }

  globalStyles.primaryColor = primaryColor;
  globalStyles.navigationColor = primaryColor ?? clioBrandColor;
  globalStyles = updateFontStyle(globalStyles, partner);
  globalStyles = updateLoaderStyle(globalStyles, primaryColor);
  globalStyles = updateModalStyle(globalStyles, primaryColor);
  globalStyles = updateTourStyle(globalStyles, primaryColor, partner);
  globalStyles = updateMapStyle(globalStyles, primaryColor);
  globalStyles = updatePlayerStyle(globalStyles, primaryColor, partner);
  globalStyles = updateStoryTextStyle(globalStyles, primaryColor, partner);
  globalStyles = updateFullStoryTextStyle(globalStyles, primaryColor, partner);
  globalStyles = updateStoryItemStyle(globalStyles, primaryColor, partner);
  globalStyles = updateOtherStyle(globalStyles, primaryColor, partner);

  store.setSession("globalStyles", globalStyles);

  return globalStyles;
};

const determineCategory = (story) => {
  let category = null;
  let categoryValues = story?.["category"]?.["values"];
  if (categoryValues && categoryValues.length > 0 && categoryValues[0]) {
    let firstCategory = categoryValues[0];
    category = firstCategory
      ? String(firstCategory.split(" ")[0]).toLowerCase()
      : "";
    if (category === "introduction" || category === "closure") {
      category = "info";
    }
  }
  return category;
};

const getStartIconSvg = (globalStyles) => {
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 40 40" fill="none">
      <path d="M19.9984 39.6004C17.3523 39.6004 14.7855 39.0822 12.3694 38.0603C10.0354 37.0731 7.9393 35.6598 6.13914 33.8597C4.33898 32.0595 2.92569 29.9634 1.93854 27.6295C0.916605 25.2133 0.398438 22.6466 0.398438 20.0004C0.398438 17.3542 0.916605 14.7874 1.93854 12.3713C2.92569 10.0374 4.33898 7.94125 6.13914 6.14109C7.9393 4.34093 10.0354 2.92765 12.3694 1.94049C14.7855 0.918559 17.3523 0.400391 19.9984 0.400391C22.6446 0.400391 25.2114 0.918559 27.6275 1.94049C29.9614 2.92765 32.0576 4.34093 33.8577 6.14109C35.6579 7.94125 37.0712 10.0374 38.0583 12.3713C39.0803 14.7874 39.5984 17.3542 39.5984 20.0004C39.5984 22.6466 39.0803 25.2133 38.0583 27.6295C37.0712 29.9634 35.6579 32.0595 33.8577 33.8597C32.0576 35.6598 29.9614 37.0731 27.6275 38.0603C25.2114 39.0822 22.6446 39.6004 19.9984 39.6004Z" fill="${globalStyles.tour?.startPointIconColor}" />
      <path d="M20 0.8C17.4077 0.8 14.8933 1.30753 12.5267 2.30851C10.2405 3.2755 8.1871 4.66 6.42355 6.42355C4.66 8.1871 3.2755 10.2405 2.30851 12.5267C1.30753 14.8933 0.8 17.4077 0.8 20C0.8 22.5923 1.30753 25.1067 2.30851 27.4733C3.2755 29.7595 4.66 31.8129 6.42355 33.5764C8.1871 35.34 10.2405 36.7245 12.5267 37.6915C14.8933 38.6925 17.4077 39.2 20 39.2C22.5923 39.2 25.1067 38.6925 27.4733 37.6915C29.7595 36.7245 31.8129 35.34 33.5764 33.5764C35.34 31.8129 36.7245 29.7595 37.6915 27.4733C38.6925 25.1067 39.2 22.5923 39.2 20C39.2 17.4077 38.6925 14.8933 37.6915 12.5267C36.7245 10.2405 35.34 8.1871 33.5764 6.42355C31.8129 4.66 29.7595 3.2755 27.4733 2.30851C25.1067 1.30753 22.5923 0.8 20 0.8ZM20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0Z" fill="#ffffff" />
      <path d="M19.9987 22.1591C19.2369 22.158 18.4925 21.931 17.8597 21.5068C17.2269 21.0827 16.734 20.4804 16.4435 19.7762C16.1529 19.0719 16.0777 18.2974 16.2274 17.5504C16.377 16.8034 16.7448 16.1176 17.2842 15.5796C17.8237 15.0417 18.5105 14.6757 19.2579 14.5281C20.0052 14.3805 20.7796 14.4578 21.4831 14.7502C22.1865 15.0427 22.7874 15.5371 23.2099 16.1711C23.6323 16.8051 23.8573 17.5501 23.8563 18.3119C23.853 19.333 23.4451 20.3111 22.7221 21.0322C21.9991 21.7532 21.0198 22.1584 19.9987 22.1591ZM19.9987 15.9503C19.5315 15.9503 19.0747 16.0888 18.6862 16.3485C18.2977 16.6081 17.9949 16.9771 17.8161 17.4088C17.6373 17.8405 17.5906 18.3156 17.6818 18.7739C17.773 19.2321 17.9981 19.6531 18.3286 19.9834C18.659 20.3138 19.08 20.5387 19.5384 20.6298C19.9967 20.7208 20.4717 20.6739 20.9033 20.495C21.335 20.3161 21.7039 20.0132 21.9634 19.6246C22.2229 19.236 22.3613 18.7791 22.3611 18.3119C22.3597 17.6859 22.1103 17.0859 21.6675 16.6433C21.2248 16.2007 20.6248 15.9515 19.9987 15.9503Z" fill="#ffffff" />
      <path d="M19.998 30.722C18.4626 30.7189 16.9878 30.1224 15.882 29.0572C12.9412 26.2252 9.6924 21.71 10.918 16.338C11.3855 14.2952 12.5449 12.4767 14.1997 11.1908C15.8545 9.90485 17.9029 9.23052 19.998 9.28204H20.0076C22.104 9.23092 24.1536 9.90669 25.8085 11.1947C27.4634 12.4827 28.6217 14.3036 29.0868 16.3484C30.3028 21.7204 27.054 26.2252 24.1132 29.0556C23.0078 30.1212 21.5333 30.7183 19.998 30.722ZM19.998 10.7788C18.241 10.7165 16.5175 11.2712 15.1267 12.3467C13.7358 13.4222 12.7654 14.9506 12.3836 16.6668C11.3068 21.362 14.2572 25.4076 16.9276 27.9692C17.7509 28.7699 18.854 29.2178 20.0024 29.2178C21.1508 29.2178 22.2539 28.7699 23.0772 27.9692C25.738 25.4092 28.6884 21.3612 27.6316 16.6676C27.2449 14.9502 26.2705 13.4217 24.8768 12.3462C23.483 11.2707 21.7574 10.7158 19.998 10.7772V10.7788Z" fill="#ffffff" />
    </svg>
  `;
  return svg;
};

const getMarkerSvg = (globalStyles, style) => {
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" style="${style}">
      <g data-name="poi" fill="${globalStyles?.map?.markerColor}">
        <path data-name="poi" d="M16 40c3.532 0 16-15.853 16-24.444a16.006 16.006 0 0 0-32 0C0 24.147 12.468 40 16 40Zm0-17.778a6.669 6.669 0 1 0-6.857-6.667A6.764 6.764 0 0 0 16 22.222Z" fill-rule="evenodd"/>
        <circle data-name="Ellipse 592" cx="10.5" cy="10.5" r="10.5" transform="translate(5.558 6.37)"/>
      </g>
    </svg>
  `;
  return svg;
}

const hexToRgb = (hex) => {
  if (!hex) {
    return [255, 255, 255];
  }

  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return [r, g, b];
}

const rgbToHex = (r, g, b) => {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
}

const blendColors = (fgColor, bgColor, alpha) => {
  let [r_fg, g_fg, b_fg] = hexToRgb(fgColor);
  let [r_bg, g_bg, b_bg] = hexToRgb(bgColor);

  let r_result = Math.round(r_fg * alpha + r_bg * (1 - alpha));
  let g_result = Math.round(g_fg * alpha + g_bg * (1 - alpha));
  let b_result = Math.round(b_fg * alpha + b_bg * (1 - alpha));

  return rgbToHex(r_result, g_result, b_result);
}

const fullStoryTextBackground = (theme, fgColor) => {
  if (theme == 'dark') {
    return fgColor;
  }
  else {
    let alpha = 0.3;
    return blendColors(fgColor, "#ffffff", alpha);
  }
}

const storyTextBackground = (fgColor) => {
  let alpha = 0.3;
  return blendColors(fgColor, "#ffffff", alpha);
}

const getPlayingAnimation = (color, alpha = 1) => {
  color = Color(color).color;
  let lottieColor = [color[0] / 255.0, color[1] / 255, color[2] / 255, alpha];

  return {
    v: "5.5.2",
    fr: 60,
    ip: 0,
    op: 120,
    w: 48,
    h: 48,
    nm: "Artboard 10",
    ddd: 0,
    assets: [],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 4,
        nm: "Path 7",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [13, 23.5, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0, -12],
                      [0, 12.5],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: "路径 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: lottieColor,
                  ix: 3,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 4,
                },
                w: {
                  a: 0,
                  k: 5,
                  ix: 5,
                },
                lc: 2,
                lj: 2,
                bm: 0,
                nm: "描边 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [100, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "变换",
              },
            ],
            nm: "Path 6",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "tm",
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 0,
                  s: [95],
                  e: [30],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 14,
                  s: [30],
                  e: [95],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 35,
                  s: [95],
                  e: [0],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 56,
                  s: [0],
                  e: [95],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 79,
                  s: [95],
                  e: [40],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 100,
                  s: [40],
                  e: [95],
                },
                {
                  t: 119,
                },
              ],
              ix: 1,
            },
            e: {
              a: 0,
              k: 100,
              ix: 2,
            },
            o: {
              a: 0,
              k: 0,
              ix: 3,
            },
            m: 1,
            ix: 2,
            nm: "修剪路径 1",
            mn: "ADBE Vector Filter - Trim",
            hd: false,
          },
        ],
        ip: 0,
        op: 120,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 4,
        nm: "Path 6",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [24, 23.5, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0, -12],
                      [0, 12.5],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: "路径 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: lottieColor,
                  ix: 3,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 4,
                },
                w: {
                  a: 0,
                  k: 5,
                  ix: 5,
                },
                lc: 2,
                lj: 2,
                bm: 0,
                nm: "描边 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [100, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "变换",
              },
            ],
            nm: "Path 6",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "tm",
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 0,
                  s: [95],
                  e: [0],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 21,
                  s: [0],
                  e: [95],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 46,
                  s: [95],
                  e: [0],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 71,
                  s: [0],
                  e: [95],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 90,
                  s: [95],
                  e: [50],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 105,
                  s: [50],
                  e: [95],
                },
                {
                  t: 119,
                },
              ],
              ix: 1,
            },
            e: {
              a: 0,
              k: 100,
              ix: 2,
            },
            o: {
              a: 0,
              k: 0,
              ix: 3,
            },
            m: 1,
            ix: 2,
            nm: "修剪路径 1",
            mn: "ADBE Vector Filter - Trim",
            hd: false,
          },
        ],
        ip: 0,
        op: 120,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: "Path 8",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [35, 23.5, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0, -12],
                      [0, 12.5],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: "路径 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: lottieColor,
                  ix: 3,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 4,
                },
                w: {
                  a: 0,
                  k: 5,
                  ix: 5,
                },
                lc: 2,
                lj: 2,
                bm: 0,
                nm: "描边 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [100, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "变换",
              },
            ],
            nm: "Path 6",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "tm",
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 0,
                  s: [96],
                  e: [60],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 15,
                  s: [60],
                  e: [80],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 30,
                  s: [80],
                  e: [98],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 35,
                  s: [98],
                  e: [20],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 50,
                  s: [20],
                  e: [0],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 55,
                  s: [0],
                  e: [80],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 70,
                  s: [80],
                  e: [94],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 75,
                  s: [94],
                  e: [20],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 90,
                  s: [20],
                  e: [0],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 94,
                  s: [0],
                  e: [80],
                },
                {
                  i: {
                    x: [0.833],
                    y: [0.833],
                  },
                  o: {
                    x: [0.167],
                    y: [0.167],
                  },
                  t: 115,
                  s: [80],
                  e: [95],
                },
                {
                  t: 119,
                },
              ],
              ix: 1,
            },
            e: {
              a: 0,
              k: 100,
              ix: 2,
            },
            o: {
              a: 0,
              k: 0,
              ix: 3,
            },
            m: 1,
            ix: 2,
            nm: "修剪路径 1",
            mn: "ADBE Vector Filter - Trim",
            hd: false,
          },
        ],
        ip: 0,
        op: 120,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  };
};

export {
  updateGlobalStyles,
  determineCategory,
  getStartIconSvg,
  getMarkerSvg,
  blendColors,
  fullStoryTextBackground,
  storyTextBackground,
  getPlayingAnimation,
};
