import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./assets/Commissioner.ttf";
import "./App.css";
import "./assets/CommonStyles.css";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
Bugsnag.start({
  apiKey: "b7ed35e7e11e1a05db0a761ae7219db9",
  plugins: [new BugsnagPluginReact()],
});
import React from "react";
const BugSnagErrorBoundary =
  Bugsnag.getPlugin("react").createErrorBoundary(React);

import Items from "./pages/Items";
import Map from "./pages/Map";
import Splash from "./pages/Splash";
import Login from "./pages/Login";
import Tour from "./pages/Tour";
import SignUp from "./pages/SignUp";

import { useSessionState } from "./util/store";

function App() {
  const [globalStyles] = useSessionState("globalStyles");

  useEffect(() => {
    if (globalStyles?.fontCssUrl) {
      const head = document.getElementsByTagName('head')[0]

      const style = document.createElement('link')
      style.href = globalStyles?.fontCssUrl
      // style.type = 'text/css'
      style.rel = 'stylesheet'
      head.append(style);
    }
  }, [globalStyles]);

  return (
    <BugSnagErrorBoundary>
      <Router basename="/">
        <div className="App" style={{ fontFamily: globalStyles?.fontFamily }}>
          <Routes>
            <Route path="/items" element={<Items />} />

            <Route path="/map" element={<Map />} />

            <Route path="/" element={<Splash />} />

            <Route path="/splash" element={<Splash />} />

            <Route path="/login" element={<Login />} />

            <Route path="/tour" element={<Tour />} />

            <Route path="/signup" element={<SignUp />} />
          </Routes>
          <ToastContainer />
        </div>
      </Router>
    </BugSnagErrorBoundary>
  );
}

export default App;
