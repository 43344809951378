

import { util } from "../../util/index";
import { store, useStateRef } from "../../util/store";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import ItemStory from "./ItemStory";



import "./ItemCardBody.css";

const ItemCardBody = (props) => {
  const [itemTitle, setItemTitle] = useStateRef();

  const [storyList, setStoryList] = useStateRef();

  const theItemRef = useRef();
  const iRef = useRef();



  const getStoryList = useCallback(async () => {
    let theStories = theItemRef.current?.["stories"];
    if (theStories) {
      let i_inc = 1;
      if (1 > theStories.length) {
        i_inc = -i_inc;
      }
      for (
        iRef.current = 1;
        i_inc >= 0
          ? iRef.current <= theStories.length
          : iRef.current >= theStories.length;
        iRef.current += i_inc
      ) {
        let story = theStories[iRef.current - 1];
        story = store.setJsonPath(story, "index", iRef.current);
        if (story?.["category"]?.["type"] === "Themes") {
          if (!story?.["difficulty"]) {
            story = store.setJsonPath(story, "difficulty", "Easy");
          }
        }
      }
    }
    return theStories;
  }, []);

  useEffect(() => {
    const initialize = async () => {
      theItemRef.current = props?.["item"];
      setItemTitle(
        [
          theItemRef.current?.["index"],
          ". ",
          theItemRef.current?.["name"],
        ].join("")
      );
      setStoryList(await getStoryList());
    };
    initialize().then(() => { });
  }, [props]);

  return (
    <div
      className="item-card-body-main-container"
      style={{ animation: (props?.showAnimation) }}
    >
      <div className="item-card-body-title-container">
        <span className="item-card-body-item-title">
          {util.getLocalizedMessage(itemTitle)}
        </span>
      </div>

      <div className="item-card-body-description-container">
        <span className="item-card-body-item-description">
          {util.getLocalizedMessage(props?.item?.secret)}
        </span>
      </div>

      <div className="item-card-body-stories-container">
        {storyList?.map ? (
          storyList.map((item) => (
            <ItemStory
              key={store.getJsonPath(item, "id") || util.generateUid()}
              story={item}
            />
          ))
        ) : (
          <></>
        )}
      </div>

      <div className="item-card-body-container"></div>
    </div>
  );
};

export default ItemCardBody;
