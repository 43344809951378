import { useCallback, useState, useEffect } from "react";
import { store, useSessionState } from "../../util/store";

import { useWindowWidth } from "@react-hook/window-size";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

import { playAudio, pauseAudio } from "../CallApi";
import { PauseIcon, PlayIcon } from "../../util/stylingComponents";

const PoiThumbnail = ({ poi, type, playingItem, onPoiTapped }) => {
  const [globalStyles] = useSessionState("globalStyles");
  const [playStatus, setPlayStatus] = useState("play"); // "pause"

  const getImageUrl = (url) => {
    if (url.startsWith("http") || url.startsWith("data:") || url.startsWith("blob:")) {
      return url;
    } else {
      return store.dynamicAsset(url);
    }
  };

  const onThumbnailTapped = () => {
    if (onPoiTapped) {
      onPoiTapped(poi);
    }
  };

  const onPlayPause = (e) => {
    e.stopPropagation();
    store.setSession("current_item", poi);
    if (store.getSession("playing_item.id") == poi?.id) {
      pauseAudio();
    } else {
      playAudio(poi);
    }
  }

  useEffect(() => {
    if (playingItem?.id && playingItem?.id == poi?.id) {
      setPlayStatus("pause");
    } else {
      setPlayStatus("play");
    }
  }, [playingItem]);

  if (poi) {
    if (type == "player") {
      return (
        <div style={{ display: "flex", flexDirection: "column", width: "180px", position: "relative", cursor: "pointer" }} className={"poi-carousel-playable"} onClick={onThumbnailTapped}>
          <div
            style={{
              width: "180px",
              height: "100px",
              backgroundImage: `url(${getImageUrl(poi.imageUrl)})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "8px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "34px",
              height: "30px",
              borderRadius: "16px",
              backgroundColor: globalStyles?.map?.storiesBadgeColor,
              padding: "0px 12px 0px 12px",
              color: "white",
              position: "absolute",
              top: 100 - 30 - 10 + "px",
              left: "10px",
            }}
          >
            <img src={getImageUrl("Stories")} width="12" height="12" />
            <div style={{ fontSize: "12px" }}>{poi.storiesCount}</div>
          </div>
          <div
            width="45"
            height="45"
            style={{
              position: "absolute",
              top: 100 - 44 + "px",
              right: "10px",
            }}
            onClick={onPlayPause}
          >
            {playStatus == "play" ? <PlayIcon globalStyles={globalStyles} size={35} onCarousel={true} /> : <PauseIcon globalStyles={globalStyles} size={35} onCarousel={true} />}
          </div>
          <div style={{ fontSize: "14px", fontWeight: "bold", marginTop: "4px", marginLeft: "8px" }}>{poi.title}</div>
        </div>
      );
    } else if (type == "player-min") {
      return (
        <div style={{ display: "flex", flexDirection: "row", cursor: "pointer" }} onClick={onThumbnailTapped}>
          <div
            style={{
              width: "40px",
              height: "40px",
              backgroundImage: `url(${getImageUrl(poi.imageUrl)})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "8px",
              marginRight: "15px",
            }}
          />
          <div style={{ fontSize: "14px", fontWeight: "bold", marginTop: "4px", flex: 1 }}>{poi.title}</div>
        </div>
      );
    } else if (type == "player-control") {
      return (
        <div style={{ display: "flex", flexDirection: "column", width: "125px", cursor: "pointer" }} onClick={onThumbnailTapped}>
          <div
            style={{
              width: "125px",
              height: "180px",
              backgroundImage: `url(${getImageUrl(poi.imageUrl)})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "8px",
            }}
          />
          <div style={{ fontSize: "14px", fontWeight: "bold", marginTop: "4px" }}>{poi.title}</div>
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", flexDirection: "column", width: "125px" }}>
          <div
            style={{
              width: "125px",
              height: "180px",
              backgroundImage: `url(${getImageUrl(poi.imageUrl)})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "8px",
            }}
          />
          <div style={{ fontSize: "14px", fontWeight: "bold", marginTop: "4px" }}>{poi.title}</div>
        </div>
      );
    }
  } else {
    return <div></div>;
  }
};

const PoiCarousel = ({ pois, type, index, onIndexChange, playingItem, onPoiTapped, ...containerProps }) => {
  const windoWidth = useWindowWidth();

  const [swiper, setSwiper] = useState();
  const [centerSlides, setCenterSlides] = useState(false);

  const slidesPerView = useCallback(() => {
    if (type == "player") {
      return windoWidth / (180 + 30);
    } else if (type == "player-min") {
      return 1;
    } else {
      return windoWidth / (125 + 30);
    }
  }, [windoWidth, type]);

  const onActiveIndexChange = (swiper) => {
    if (onIndexChange) {
      onIndexChange(swiper.activeIndex + 1);
    }
  };

  useEffect(() => {
    if (swiper) {
      setTimeout(() => swiper.slideTo(index - 1), 100);
    }
  }, [index]);

  useEffect(() => {
    setTimeout(() => {
      let isCentered = type == "player" && pois && index > 1 && index < pois.length - 1;
      // console.log(type, isCentered);
      setCenterSlides(isCentered);
    });
  }, [index, pois, type]);

  return (
    <div {...containerProps}>
      <Swiper
        slidesPerView={slidesPerView()}
        centeredSlides={centerSlides}
        edgeSwipeDetection={"prevent"}
        touchMoveStopPropagation={true}
        touchAngle={45}
        onActiveIndexChange={onActiveIndexChange}
        onSwiper={setSwiper}
      >
        {pois &&
          pois.map((p, index) => {
            return (
              <SwiperSlide key={"" + index}>
                <PoiThumbnail type={type} poi={p} onPoiTapped={onPoiTapped} playingItem={playingItem} />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default PoiCarousel;
