

import { util } from "../../util/index";
import { store, useSessionState, useStateRef } from "../../util/store";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import {
  playAudio,
  msToMinSec,
} from "../CallApi";
import PlayingAnimation from "./PlayingAnimation";
import { StoryCategoryIcon } from "../../util/stylingComponents";
import { determineCategory } from "../../util/styling";

import "./ItemStory.css";

const ItemStory = (props) => {
  const [globalStyles] = useSessionState("globalStyles");

  const [storyNumber, setStoryNumber] = useStateRef();

  const [isPlaying, setIsPlaying, isPlayingRef] = useStateRef();

  const [isNowPlaying, setIsNowPlaying] = useStateRef();

  const [story, setStory, storyRef] = useStateRef();

  const [category, setCategory] = useStateRef();

  const [typeName, setTypeName] = useStateRef();

  const [typeColor, setTypeColor] = useStateRef();

  const [duration, setDuration] = useStateRef();

  const [hasDifficulty, setHasDifficulty] = useStateRef();

  const [storyBackground, setStoryBackground] =
    useStateRef();

  const [storyBorderColor, setStoryBorderColor] =
    useStateRef();
  const [activeStoryBorderColor, setActiveStoryBorderColor] =
    useStateRef();
  const [playAnimationColor, setPlayAnimationColor] = useStateRef();

  const difficultyMapRef = useRef();
  const difficultyLevelRef = useRef();

  const onPlayStory = useCallback(async () => {
    store.setSession("current_story", storyRef.current);
    await playAudio(store.getSession("current_item"), storyRef.current);
  }, []);

  const setPlaying = useCallback(async () => {
    setIsPlaying(
      storyRef.current?.["id"] === store.getSession("current_story.id")
    );
    setIsNowPlaying(isPlayingRef.current && store.getSession("playing_item"));
  }, []);

  const getTypeName = useCallback(async () => {
    let name = null;
    let categoryValues = storyRef.current?.["category"]?.["values"];
    if (categoryValues && categoryValues.length > 0) {
      name = categoryValues[0];
    }
    return name;
  }, []);

  useEffect(() => store.subscribeSession("playing_item", setPlaying), []);
  useEffect(() => store.subscribeSession("current_story", setPlaying), []);

  useEffect(() => {
    const initialize = async () => {
      difficultyMapRef.current = {
        Easy: 1,
        Mild: 2,
        Difficult: 3,
        Hard: 4,
      };
      setStory(props?.["story"]);
      setTimeout(() => {
        setPlaying().then(() => { });
      });
      setStoryNumber(
        storyRef.current?.["index"] < 10
          ? "0" + String(storyRef.current?.["index"])
          : String(storyRef.current?.["index"])
      );

      setCategory(determineCategory(props?.["story"]));
      setTypeName(await getTypeName());
      setDuration(
        String(await msToMinSec(storyRef.current?.["duration"])) + " min"
      );

      setStoryBackground(globalStyles?.storyItem?.themes?.backgroundColor);
      setStoryBorderColor(globalStyles?.storyItem?.themes?.borderColor);
      setActiveStoryBorderColor(globalStyles?.storyItem?.themes?.activeBorderColor);
      setPlayAnimationColor(globalStyles?.storyItem?.themes?.playAnimationColor);
      setTypeColor(globalStyles?.storyItem?.themes?.titleColor);
      setHasDifficulty(false);

      if (storyRef.current?.["category"]) {
        switch (storyRef.current?.["category"]?.["type"]) {
          case "Info":
            setStoryBackground(globalStyles?.storyItem?.info?.backgroundColor);
            setStoryBorderColor(globalStyles?.storyItem?.info?.borderColor);
            setActiveStoryBorderColor(globalStyles?.storyItem?.info?.activeBorderColor);
            setPlayAnimationColor(globalStyles?.storyItem?.info?.playAnimationColor);
            setTypeColor(globalStyles?.storyItem?.info?.titleColor);
            break;
          case "Themes":
            if (storyRef.current?.["difficulty"]) {
              difficultyLevelRef.current = store.getJsonPath(
                difficultyMapRef.current,
                storyRef.current?.["difficulty"]
              );
              setHasDifficulty(true);
            }
            break;
          case "Tips":
            setStoryBackground(globalStyles?.storyItem?.tips?.backgroundColor);
            setStoryBorderColor(globalStyles?.storyItem?.tips?.borderColor);
            setActiveStoryBorderColor(globalStyles?.storyItem?.tips?.activeBorderColor);
            setPlayAnimationColor(globalStyles?.storyItem?.tips?.playAnimationColor);
            setTypeColor(globalStyles?.storyItem?.tips?.titleColor);
            break;
          case "Actions":
            setStoryBackground(globalStyles?.storyItem?.actions?.backgroundColor);
            setStoryBorderColor(globalStyles?.storyItem?.actions?.borderColor);
            setActiveStoryBorderColor(globalStyles?.storyItem?.actions?.activeBorderColor);
            setPlayAnimationColor(globalStyles?.storyItem?.actions?.playAnimationColor);
            setTypeColor(globalStyles?.storyItem?.actions?.titleColor);
            break;
        }
      }
    };

    initialize().then(() => { });
  }, [props, globalStyles]);

  const getDifficultyStyle = useCallback((difficultyLevel) => {
    const borderColor = isPlaying ? activeStoryBorderColor : storyBorderColor
    return {
      backgroundColor: difficultyLevelRef.current >= difficultyLevel ? borderColor : storyBackground,
      borderColor
    }
  }, [isPlaying, activeStoryBorderColor, storyBorderColor, storyBackground]);

  return (
    <div
      className="item-story-container"
      style={{
        backgroundColor: (storyBackground),
        borderColor: isPlaying ? activeStoryBorderColor : storyBorderColor,
      }}
      onClick={onPlayStory}
    >
      <div className="item-story-header-container">
        {!isPlaying && (
          <div className="item-story-number-container">
            <span className="item-story-story-number">
              {util.getLocalizedMessage(storyNumber)}
            </span>
          </div>
        )}
        {isPlaying && (
          <div className="item-story-playing-container">
            <PlayingAnimation playing={isNowPlaying} color={playAnimationColor} />
          </div>
        )}

        <div className="item-story-title-container">
          <span className="item-story-story-title">
            {util.getLocalizedMessage(story?.title)}
          </span>
        </div>
      </div>

      <div className="item-story-type-container">
        <StoryCategoryIcon category={category} categoryType={storyRef.current?.["category"]?.["type"]?.toLowerCase()} globalStyles={globalStyles} section={"storyItem"} size={26} />

        <span
          className="item-story-story-type"
          style={{ color: (typeColor) }}
        >
          {util.getLocalizedMessage(typeName)}
        </span>

        <div className="item-story-filler"></div>

        <div className="item-story-duration-container">
          <span className="item-story-story-duration">
            {util.getLocalizedMessage(duration)}
          </span>
        </div>
      </div>
      {hasDifficulty && (
        <div className="item-story-difficulty-container">
          <div
            className="item-story-difficulty-1"
            style={getDifficultyStyle(1)}
          ></div>

          <div
            className="item-story-difficulty-2"
            style={getDifficultyStyle(2)}
          ></div>

          <div
            className="item-story-difficulty-3"
            style={getDifficultyStyle(3)}
          ></div>

          <div
            className="item-story-difficulty-4"
            style={getDifficultyStyle(4)}
          ></div>
        </div>
      )}
    </div>
  );
};

export default ItemStory;
