import svg64 from 'svg64';

import { useRef, useState, useEffect } from "react";
import { store, useSessionState } from "../../util/store";

import { useWindowSize } from "@react-hook/window-size";

// https://docs.mapbox.com/mapbox-gl-js/guides/
import mapboxgl from "mapbox-gl";
mapboxgl.prewarm();
import "mapbox-gl/dist/mapbox-gl.css";
import { getStartIconSvg } from '../../util/styling';

const ACCESS_TOKEN = "pk.eyJ1IjoiY2xpb211c2VhcHAiLCJhIjoiY2xlc3Ewa2tpMThyazNycnZqdG93eTNoMSJ9.dTRr6i3NKnE7dbK7CZfkdQ";

const StartingPointMap = ({ markers, ...containerProps }) => {
  const [globalStyles] = useSessionState("globalStyles");

  const [windowWidth, windowHeight] = useWindowSize();

  const mapContainerRef = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [bounds, setBounds] = useState(null);
  const boundsPadding = 40; // pixels

  const calculateBounds = (markers) => {
    let bounds = null;

    for (let marker of markers) {
      if (isNaN(marker.lat) || isNaN(marker.lng)) {
        continue;
      }

      if (!bounds) {
        bounds = new mapboxgl.LngLatBounds([marker.lng, marker.lat], [marker.lng, marker.lat]);
      } else {
        bounds.extend([marker.lng, marker.lat]);
      }
    }

    return bounds;
  };

  const applyBounds = (map, bounds, fit) => {
    if (!map || !bounds) {
      return;
    }

    // let maxBounds = mapboxgl.LngLatBounds.convert(bounds);
    // map.setMaxBounds(maxBounds);

    if (fit) {
      map.fitBounds(bounds, { padding: boundsPadding });
    }
  };

  const addMarkers = (map, markers) => {
    for (let marker of markers) {
      if (isNaN(marker.lat) || isNaN(marker.lng)) {
        continue;
      }

      const image = new Image(32, 32);
      if (marker.type == "start") {
        const svg = getStartIconSvg(globalStyles);
        image.src = svg64(svg.replace(/\n/g, "").replace(/\s+/g, " "));
      } else {
        image.src = store.dynamicAsset("FinishMarker")
      }

      image.onload = () => {
        let mapMarker = new mapboxgl.Marker(image);
        mapMarker.setLngLat([marker.lng, marker.lat]);
        mapMarker.addTo(map);
      }
    }
  };

  useEffect(() => {
    if (!markers) {
      return;
    }

    let bounds = calculateBounds(markers);

    let map = mapInstance;
    if (!map) {
      setBounds(bounds);

      let config = {
        accessToken: ACCESS_TOKEN,
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v12",
        bounds,
        fitBoundsOptions: { padding: boundsPadding },
        minZoom: 12,
        maxZoom: 22,
        cooperativeGestures: true,
      };

      map = new mapboxgl.Map(config);

      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
          showUserHeading: true,
          showAccuracyCircle: true,
        }),
        "top-right"
      );

      setMapInstance(map);
    }

    map.once("load", () => {
      addMarkers(map, markers);
      applyBounds(map, bounds);
    });

  }, [markers]);

  useEffect(() => {
    if (mapInstance) {
      mapInstance.resize();
      applyBounds(mapInstance, bounds);
    }
  }, [windowWidth, windowHeight]);

  return <div {...containerProps} ref={mapContainerRef}></div>;
};

export default StartingPointMap;
