

import { util } from "../util/index";
import { store, useSessionState, useStateRef } from "../util/store";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import PoiCarousel from "../components/common/PoiCarousel";
import {
  getTourMediaUrl,
  getItemMediaUrl,
  playAudio,
  isOnboarded,
  getLanguageCode,
  sendGTMEvent,
} from "../components/CallApi";
import ImageViewer from "../components/map/ImageViewer";
import PlayerControl from "../components/player/PlayerControl";
import StoryTextFull from "../components/player/StoryTextFull";
import StoryImageFull from "../components/player/StoryImageFull";
import Onboarding from "../components/common/Onboarding";
import MapBox from "../components/map/MapBox";

import "./Map.css";
import AssetMinimizeDown from "../assets/MinimizeDown.svg";
import { updateGlobalStyles } from "../util/styling";
import { BackIcon } from "../util/stylingComponents";

const Map = (props) => {
  const [globalStyles] = useSessionState("globalStyles");

  let navigate = useNavigate();

  let location = useLocation();

  const [groundImage, setGroundImage] = useStateRef();

  const [showGroundImage, setShowGroundImage] =
    useStateRef();

  const [theStops, setTheStops, theStopsRef] = useStateRef();

  const [tourData, setTourData, tourDataRef] = useStateRef();

  const [carouselType, setCarouselType, carouselTypeRef] = useStateRef();

  const [showMap, setShowMap] = useStateRef();

  const [minimizeButtonTrans, setMinimizeButtonTrans] =
    useStateRef();

  const [poiIndex, setPoiIndex, poiIndexRef] = useStateRef();

  const [playingItem, setPlayingItem, playingItemRef] = useStateRef();

  const [showMiniPlayer, setShowMiniPlayer] = useStateRef();

  const [showFullStory, setShowFullStory] = useStateRef();

  const [showFullStoryImage, setShowFullStoryImage] =
    useStateRef();

  const [onboardingPage, setOnboardingPage] = useStateRef();

  const theParamsRef = useRef();
  const tourIdRef = useRef();
  const bookingCodeRef = useRef();
  const langIdRef = useRef();
  const tourImageRef = useRef();
  const tourThumbnailRef = useRef();
  const authorImageRef = useRef();

  util.configureLocalization("messages", store.getSession("messages"));
  util.configureLocalization("language", getLanguageCode(location));

  const setFirstItem = useCallback(async () => {
    if (!store.getSession("playing_item") && (await isOnboarded("map"))) {
      store.setSession("current_item", theStopsRef.current[0]);
    }
  }, []);

  const toggleCarouselType = useCallback(async () => {
    if (carouselTypeRef.current === "player") {
      await setThePlayingItem();
    }
    setCarouselType(
      carouselTypeRef.current === "player" ? "player-min" : "player"
    );
    setMinimizeButtonTrans(
      carouselTypeRef.current === "player" ? "rotate(0deg)" : "rotate(180deg)"
    );
  }, []);

  const onPoiIndexChange = useCallback(async (index) => {
    let fromUser = poiIndexRef.current !== index;
    setPoiIndex(index);
    if (
      fromUser &&
      carouselTypeRef.current === "player-min" &&
      index !== (await getPlayingIndex())
    ) {
      let items = tourDataRef.current?.["items"];
      let nextItem = util.findInObjectArray(items, "index", index);
      setPlayingItem(store.getSession("playing_item"));
      if (nextItem?.["id"] !== playingItemRef.current?.["id"]) {
        await playAudio(nextItem, null);
      }
    }
  }, []);

  const getPlayingIndex = useCallback(async () => {
    setPlayingItem(store.getSession("playing_item"));
    return playingItemRef.current ? playingItemRef.current?.["index"] : -1;
  }, []);

  const onMarkerTapped = useCallback(async (index) => {
    setPoiIndex(index);
  }, []);

  const onPoiTapped = useCallback(async (poi) => {
    store.setSession("current_item", poi);
    navigate(
      "/items" +
      [
        "?id=",
        tourIdRef.current,
        "&code=",
        bookingCodeRef.current,
        "&lang=",
        langIdRef.current,
      ].join("")
    );
  }, []);

  const setThePlayingItem = useCallback(async () => {
    setPlayingItem(store.getSession("playing_item"));
    if (playingItemRef.current) {
      setPoiIndex(playingItemRef.current?.["index"]);
    }
  }, []);

  const onBack = useCallback(async () => {
    navigate(
      "/tour" +
      [
        "?id=",
        tourIdRef.current,
        "&code=",
        bookingCodeRef.current,
        "&lang=",
        langIdRef.current,
      ].join("")
    );
  }, []);

  const manageFullStory = useCallback(async () => {
    if (store.getSession("show_full_story")) {
      setShowFullStory(true);
    } else {
      setShowFullStory(false);
    }
  }, []);

  const manageFullStoryImage = useCallback(async () => {
    if (store.getSession("show_full_story_image")) {
      setShowFullStoryImage(true);
    } else {
      setShowFullStoryImage(false);
    }
  }, []);

  const initializePlayingIndex = useCallback(async () => {
    setPlayingItem(store.getSession("playing_item"));
    let items = tourDataRef.current?.["items"];
    if (items) {
      if (playingItemRef.current) {
        let item;
        let i;
        let i_inc = 1;
        if (0 > items.length) {
          i_inc = -i_inc;
        }
        for (
          i = 0;
          i_inc >= 0 ? i <= items.length : i >= items.length;
          i += i_inc
        ) {
          item = items[i];
          if (store.getSession("playing_item.id") === item?.["id"]) {
            setPoiIndex(i);
            break;
          }
        }
      } else {
        if (await isOnboarded("map")) {
          await playAudio(items[0], null);
        }
      }
    }
  }, []);

  const loadItems = useCallback(async (tour) => {
    let items = tour?.["items"];
    let tempStops = [];
    let item;
    for (item of items) {
      tempStops.push({
        id: item?.["id"],
        index: item?.["index"],
        title: [item?.["index"], ". ", item?.["name"]].join(""),
        imageUrl: await getItemMediaUrl(item, "thumbFile"),
        lat: parseFloat(item?.["lat"]),
        lng: parseFloat(item?.["lon"]),
        storiesCount: (item?.["stories"])?.length ?? 0,
        stories: item?.["stories"],
        name: item?.["name"],
      });
    }
    setTheStops(tempStops);
    setTimeout(() => {
      setFirstItem().then(() => { });
    }, 100);
  }, []);

  useEffect(
    () => store.subscribeSession("playing_item", setThePlayingItem),
    []
  );
  useEffect(
    () => store.subscribeSession("show_full_story_image", manageFullStoryImage),
    []
  );
  useEffect(
    () => store.subscribeSession("show_full_story", manageFullStory),
    []
  );

  useEffect(() => {
    const initialize = async () => {
      store.setSession(
        "queryParams",
        Object.fromEntries(new URLSearchParams(location.search))
      );
      store.setSession("current_page", "Map");
      theParamsRef.current = store.getSession("queryParams");
      tourIdRef.current = theParamsRef.current?.["id"];
      bookingCodeRef.current = theParamsRef.current?.["code"];
      langIdRef.current = theParamsRef.current?.["lang"];
      await updateGlobalStyles(tourIdRef.current, bookingCodeRef.current);

      sendGTMEvent("screen_view", { page_name: "Map", partner_name: store.getSession("globalStyles.partner.name"), tour_id: tourIdRef.current, lang_id: langIdRef.current });

      setPlayingItem(store.getSession("playing_item"));
      setShowMiniPlayer(true);
      setMinimizeButtonTrans("rotate(0deg)");
      setCarouselType("player");
      setTourData(store.getSession("tour_data"));
      if (!tourDataRef.current) {
        // to get user action for audio (iOS)
        navigate(
          "/" +
          [
            "?id=",
            tourIdRef.current,
            "&code=",
            bookingCodeRef.current,
            "&lang=",
            langIdRef.current,
          ].join("")
        );
      }
      if (tourDataRef.current) {
        setOnboardingPage(
          tourDataRef.current?.["isIndoors"] ? "map-indoor" : "map"
        );
        setTimeout(() => {
          initializePlayingIndex().then(() => { });
        }, 100);
        tourImageRef.current = await getTourMediaUrl(
          tourDataRef.current,
          "imageFile"
        );
        tourThumbnailRef.current = await getTourMediaUrl(
          tourDataRef.current,
          "thumbFile"
        );
        authorImageRef.current = await getTourMediaUrl(
          tourDataRef.current,
          "authorImage"
        );
        setGroundImage(
          await getTourMediaUrl(tourDataRef.current, "groundImageFile")
        );
        setShowMap(!tourDataRef.current?.["isIndoors"]);
        setShowGroundImage(tourDataRef.current?.["isIndoors"]);
        await loadItems(tourDataRef.current);
      }
    };
    initialize().then(() => { });
  }, []);

  return (
    <div className="map-main-container main-container">
      {showGroundImage && (
        <div className="map-ground-image-container">
          <ImageViewer src={groundImage} />
        </div>
      )}
      {showMap && (
        <div className="map-maxbox-container">
          <MapBox
            className="map-map-box"
            onPoiMarkerTapped={onMarkerTapped}
            stops={theStops}
            mapStyle={tourData?.mapStyle}
            mapBounds={tourData?.mapBounds}
            minZoom={tourData?.minZoom}
            maxZoom={tourData?.maxZoom}
            carouselType={carouselType}
            showRoute={tourData?.showRoute}
          />
        </div>
      )}

      <div className="map-poi-container">
        <img
          className="map-minimize-button"
          style={{ transform: (minimizeButtonTrans) }}
          onClick={toggleCarouselType}
          src={AssetMinimizeDown}
        />
        <PoiCarousel
          className="map-poi-carousel"
          onIndexChange={onPoiIndexChange}
          onPoiTapped={onPoiTapped}
          type={carouselType}
          pois={theStops}
          index={poiIndex}
          playingItem={playingItem}
        />
      </div>

      <div className="map-player-container">
        <PlayerControl
          className="map-player-control"
          constants={props.constants}
          showMini={showMiniPlayer}
          tour={tourData}
          tourId={tourIdRef.current}
          bookingCode={bookingCodeRef.current}
          langId={langIdRef.current}
        />
      </div>

      <div className="map-back-button" onClick={onBack}>
        <div className="map-back-icon">
          <BackIcon globalStyles={globalStyles} size={35} />
        </div>
      </div>

      {(showFullStory ?? false) && (
        <div className="map-full-story-container">
          <StoryTextFull
            className="map-story-text-full"
            constants={props.constants}
            tourId={tourIdRef.current}
            bookingCode={bookingCodeRef.current}
            langId={langIdRef.current}
          />
        </div>
      )}
      {(showFullStoryImage ?? false) && (
        <div className="map-full-story-image-container">
          <StoryImageFull
            className="map-story-image-full"
            constants={props.constants}
          />
        </div>
      )}
      <Onboarding className="map-onboarding" page={onboardingPage} />
    </div>
  );
};

export default Map;
