

import { util } from "../util/index";
import { store, useStateRef } from "../util/store";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useRef } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import _ from "lodash";
import MuiTextField from "../components/common/MuiTextField";
import {
  playAudio,
  doLogin,
  getLanguageCode,
} from "../components/CallApi";

import "./Login.css";
import AssetLoginLogo from "../assets/LoginLogo.svg";
import { useSessionState } from "../util/store";
import { updateGlobalStyles } from "../util/styling";

const Login = () => {
  const [globalStyles] = useSessionState("globalStyles");

  let navigate = useNavigate();

  let location = useLocation();

  const [email, setEmail, emailRef] = useStateRef();

  const [formMode, setFormMode] = useStateRef();

  const [password, setPassword, passwordRef] = useStateRef();

  const isEmailValidRef = useRef();
  const isPasswordValidRef = useRef();
  const tourDataRef = useRef();
  const theParamsRef = useRef();
  const firstItemRef = useRef();
  const tourIdRef = useRef();
  const bookingCodeRef = useRef();
  const langIdRef = useRef();

  util.configureLocalization("messages", store.getSession("messages"));
  util.configureLocalization("language", getLanguageCode(location));

  const onEmailChanged = useCallback(async (event) => {
    isEmailValidRef.current = event?.["isValid"];
    if (isEmailValidRef.current) {
      setEmail(event?.["value"]);
    }
  }, []);

  const onPasswordChanged = useCallback(async (event) => {
    isPasswordValidRef.current = event?.["isValid"];
    if (isPasswordValidRef.current) {
      setPassword(event?.["value"]);
    }
  }, []);

  const onSkip = useCallback(async () => {
    navigate(
      "/tour" +
      [
        "?id=",
        tourIdRef.current,
        "&code=",
        bookingCodeRef.current,
        "&lang=",
        langIdRef.current,
        "&skipLogin=true",
      ].join("")
    );
  }, []);

  const onLogin = useCallback(async () => {
    if (isEmailValidRef.current && isPasswordValidRef.current) {
      let user = await doLogin(emailRef.current, passwordRef.current);
      if (user?.["error"]) {
        util.showError(user?.["error"], 4000);
      } else {
        await startTour();
      }
    } else {
      setFormMode("validate");
    }
  }, []);

  const onForgotPassword = useCallback(async () => { }, []);

  const onSignUp = useCallback(async () => {
    navigate(
      "/signup" +
      [
        "?id=",
        tourIdRef.current,
        "&code=",
        bookingCodeRef.current,
        "&lang=",
        langIdRef.current,
      ].join("")
    );
  }, []);

  const startTour = useCallback(async () => {
    tourDataRef.current = store.getSession("tour_data");
    firstItemRef.current = (tourDataRef.current?.["items"])?.[0];
    store.setSession("current_item", firstItemRef.current);
    await playAudio(firstItemRef.current, null);
    navigate(
      "/map" +
      [
        "?id=",
        tourIdRef.current,
        "&code=",
        bookingCodeRef.current,
        "&lang=",
        langIdRef.current,
      ].join("")
    );
  }, []);

  useEffect(() => {
    const initialize = async () => {
      store.setSession(
        "queryParams",
        Object.fromEntries(new URLSearchParams(location.search))
      );
      theParamsRef.current = store.getSession("queryParams");
      tourIdRef.current = theParamsRef.current?.["id"];
      bookingCodeRef.current = theParamsRef.current?.["code"];
      langIdRef.current = theParamsRef.current?.["lang"];
      await updateGlobalStyles(tourIdRef.current, bookingCodeRef.current);

      if (store.getSession("authentication_error")) {
        util.showError(store.getSession("authentication_error"), 6000);
        store.setSession("authentication_error", null);
      }
      setEmail("");
      isEmailValidRef.current = false;
      setPassword("");
      isPasswordValidRef.current = false;
      setFormMode("type");
    }
    initialize().then(() => { });
  }, []);

  return (
    <div className="login-container">
      <div className="login-header-container">
        {globalStyles?.splash == "cliomuse" &&
          <img className="login-image" src={AssetLoginLogo} />
        }
        {globalStyles?.splash == "cobrand" &&
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
            <img className="login-image" src={globalStyles?.logos?.small ?? ''} />
            <img className="login-image" src={AssetLoginLogo} />
          </div>
        }
        {globalStyles?.splash == "whitelabel" &&
          <img className="login-image" src={globalStyles?.logos?.small ?? ''} />
        }

        <div className="login-title-container">
          <span className="login-title">
            {util.getLocalizedMessage("$=login_title")}
          </span>
          <span className="login-subtitle" style={{ color: globalStyles?.modal?.cancelColor }}>
            {util.getLocalizedMessage("$=login_subtitle")}
          </span>
        </div>
      </div>

      <div className="login-form-container">
        <div className="login-email-container">
          <MuiTextField
            className="login-mui-text-field"
            onChange={_.throttle((value) => onEmailChanged(value), 500)}
            label={util.getLocalizedMessage("$=email")}
            type={"email"}
            value={email}
            mode={formMode}
          />
        </div>

        <div className="login-password-container">
          <MuiTextField
            className="login-mui-text-field-2"
            onChange={_.throttle((value) => onPasswordChanged(value), 500)}
            label={util.getLocalizedMessage("$=password")}
            type={"password-login"}
            value={password}
            mode={formMode}
          />
        </div>
      </div>
      {false && (
        <div className="login-forgot-container">
          <span className="login-text" onClick={onForgotPassword} style={{ color: globalStyles?.modal?.okColor }}>
            {util.getLocalizedMessage("$=login_forgot_text")}
          </span>
        </div>
      )}

      <div className="login-button-container">
        <div className="login-skip-button" onClick={onSkip} style={{ borderColor: globalStyles?.modal?.cancelColor }}>
          <span className="login-skip-text">
            {util.getLocalizedMessage("$=login_skip_text")}
          </span>
        </div>

        <div className="login-login-button" onClick={onLogin} style={{ backgroundColor: globalStyles?.modal?.okColor, borderColor: globalStyles?.modal?.cancelColor }}>
          <span className="login-login-text" style={{ color: globalStyles?.modal?.okTextColor }}>
            {util.getLocalizedMessage("$=login_login_text")}
          </span>
        </div>
      </div>

      <div className="login-footer-container">
        <span className="login-no-account-text">
          {util.getLocalizedMessage("$=login_no_account_text")}
        </span>
        <span className="login-signup-text" onClick={onSignUp} style={{ color: globalStyles?.modal?.okColor }}>
          {util.getLocalizedMessage("$=login_signup_text")}
        </span>
      </div>
    </div>
  );
};

export default Login;
