import { util } from "../util/index";
import { store, useSessionState, useStateRef } from "../util/store";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import StartingPointMap from "../components/tour/StartingPointMap";
import PoiCarousel from "../components/common/PoiCarousel";
import {
  getTourMediaUrl,
  getItemMediaUrl,
  playAudio,
  loadMedia,
  mediaAlreadyLoaded,
  getGeolocationState,
  getGeolocation,
  getUser,
  initAudio,
  isOnline,
  getLanguageCode,
  sendGTMEvent,
} from "../components/CallApi";
import { StartIcon, FinishIcon } from "../util/stylingComponents";

import Loader from "../components/tour/Loader";
import Modal from "../components/common/Modal";
import MapBox from "../components/map/MapBox";

import "./Tour.css";
import AssetBack from "../assets/Back.svg";
import AssetLoginLogo from "../assets/LoginLogo.svg";
import { updateGlobalStyles } from "../util/styling";

const Tour = (props) => {
  const [globalStyles] = useSessionState("globalStyles");

  let navigate = useNavigate();

  let location = useLocation();

  const [tourData, setTourData, tourDataRef] = useStateRef();

  const [tourImage, setTourImage] = useStateRef();

  const [authorImage, setAuthorImage] = useStateRef();

  const [hasStart, setHasStart, hasStartRef] = useStateRef();

  const [theMarkers, setTheMarkers, theMarkersRef] = useStateRef();

  const [hasFinish, setHasFinish, hasFinishRef] = useStateRef();

  const [thePois, setThePois] = useStateRef();

  const [sponsorImage, setSponsorImage] = useStateRef();

  const [hasSponsor, setHasSponsor, hasSponsorRef] = useStateRef();

  const [tourThumbnail, setTourThumbnail] = useStateRef();

  const [showLoader, setShowLoader, showLoaderRef] = useStateRef();

  const [showGeoPrompt, setShowGeoPrompt] = useStateRef();

  const [showGeoSettings, setShowGeoSettings] =
    useStateRef();

  const [showLoginPrompt, setShowLoginPrompt] =
    useStateRef();

  const [isOutdoor, setIsOutdoor, isOutdoorRef] = useStateRef();

  const theParamsRef = useRef();
  const tourIdRef = useRef();
  const firstItemRef = useRef();
  const startMarkerRef = useRef();
  const bookingCodeRef = useRef();
  const langIdRef = useRef();
  const itemRef = useRef();
  const skipLoginRef = useRef();
  const finishMarkerRef = useRef();

  util.configureLocalization("messages", store.getSession("messages"));
  util.configureLocalization("language", getLanguageCode(location));

  const openMapDirections = useCallback(async () => {
    if (startMarkerRef.current) {
      window.open(
        [
          "https://maps.google.com/maps?daddr=",
          startMarkerRef.current?.["lat"],
          ",",
          startMarkerRef.current?.["lng"],
          "&ll=",
        ].join(""),
        "_blank"
      );
    }
  }, []);

  const onLoadProgress = useCallback(async () => {
    let percent = store.getSession("loading_percent");
    if (percent === 100 && !store.getSession("loading_error")) {
      await onLoadOk();
    }
  }, []);

  const onLoadOk = useCallback(async () => {
    setShowLoader(false);
  }, []);

  const onSkipLogin = useCallback(async () => {
    setShowLoginPrompt(false);
    await startTour();
  }, []);

  const startTour = useCallback(async () => {
    store.setSession("prompted_once", true);
    firstItemRef.current = (tourDataRef.current?.["items"])?.[0];
    store.setSession("current_item", firstItemRef.current);
    await playAudio(firstItemRef.current, null);
    navigate(
      "/map" +
      [
        "?id=",
        tourIdRef.current,
        "&code=",
        bookingCodeRef.current,
        "&lang=",
        langIdRef.current,
      ].join("")
    );
  }, []);

  const askForLogin = useCallback(async () => {
    let user = await getUser();
    if (user?.["role_id"] === 4 && !skipLoginRef.current) {
      setShowLoginPrompt(true);
    } else {
      await startTour();
    }
  }, []);

  const onLogin = useCallback(async () => {
    setShowLoginPrompt(false);
    navigate(
      "/login" +
      [
        "?id=",
        tourIdRef.current,
        "&code=",
        bookingCodeRef.current,
        "&lang=",
        langIdRef.current,
      ].join("")
    );
  }, []);

  const onGeoSettingsOK = useCallback(async () => {
    setShowGeoSettings(false);
    await askForLogin();
  }, []);

  const onGeoPromptOK = useCallback(async () => {
    setShowGeoPrompt(false);
    let geolocation = await getGeolocation();
    if (geolocation?.["error"]) {
      setShowGeoSettings(true);
    } else {
      await askForLogin();
    }
  }, []);

  const onStart = useCallback(async () => {
    await initAudio();
    if (!showLoaderRef.current) {
      let promptedOnce = store.getSession("prompted_once");
      if (!promptedOnce) {
        if (isOutdoorRef.current) {
          let geoState = await getGeolocationState();
          if (geoState === "granted") {
            await askForLogin();
          } else if (geoState === "prompt") {
            setShowGeoPrompt(true);
          } else if (geoState === "denied") {
            setShowGeoSettings(true);
          } else {
            await askForLogin();
          }
        } else {
          await askForLogin();
        }
      } else {
        await startTour();
      }
    }
  }, []);

  const loadLocation = useCallback(async (tour) => {
    setTheMarkers([]);
    setHasStart(
      tour?.["starting_point"]?.["coordinates"]?.["lat"] &&
      tour?.["starting_point"]?.["coordinates"]?.["lon"]
    );
    if (hasStartRef.current) {
      startMarkerRef.current = {
        type: "start",
        lat: parseFloat(tour?.["starting_point"]?.["coordinates"]?.["lat"]),
        lng: parseFloat(tour?.["starting_point"]?.["coordinates"]?.["lon"]),
      };
      let tmpList = [...theMarkersRef.current];
      tmpList.push(startMarkerRef.current);
      setTheMarkers(tmpList);
    }
    setHasFinish(
      !tour?.["isIndoors"] &&
      tour?.["finishing_point"]?.["coordinates"]?.["lat"] &&
      tour?.["finishing_point"]?.["coordinates"]?.["lon"]
    );
    if (hasFinishRef.current) {
      finishMarkerRef.current = {
        type: "finish",
        lat: parseFloat(tour?.["finishing_point"]?.["coordinates"]?.["lat"]),
        lng: parseFloat(tour?.["finishing_point"]?.["coordinates"]?.["lon"]),
      };
      let tmpList2 = [...theMarkersRef.current];
      tmpList2.push(finishMarkerRef.current);
      setTheMarkers(tmpList2);
    }
  }, []);

  const loadItems = useCallback(async (tour) => {
    let items = tour?.["items"];
    let tempPois = [];
    if (!Array.isArray(items)) {
      setThePois(tempPois);
      return;
    }
    for (itemRef.current of items) {
      tempPois.push({
        title: itemRef.current?.["name"],
        imageUrl: await getItemMediaUrl(itemRef.current, "imageFile"),
        id: itemRef.current?.["id"],
        index: itemRef.current?.["index"],
        lat: parseFloat(itemRef.current?.["lat"]),
        lng: parseFloat(itemRef.current?.["lon"]),
        storiesCount: (itemRef.current?.["stories"])?.length ?? 0,
        stories: itemRef.current?.["stories"],
        name: itemRef.current?.["name"],
      });
    }
    setThePois(tempPois);
  }, []);

  const loadSponsor = useCallback(async (tour) => {
    setHasSponsor(tour?.["hasSponsor"] !== 0 || tour?.["sponsor"]);
    if (hasSponsorRef.current) {
      setSponsorImage(await getTourMediaUrl(tour, "sponsorImage"));
    }
  }, []);

  const startLoader = useCallback(async () => {
    await loadMedia(tourDataRef.current);
  }, []);

  useEffect(
    () => store.subscribeSession("loading_percent", onLoadProgress),
    []
  );
  useEffect(() => store.subscribeSession("loading_ok", onLoadOk), []);

  useEffect(() => {
    const initialize = async () => {
      store.setSession(
        "queryParams",
        Object.fromEntries(new URLSearchParams(location.search))
      );
      store.setSession("current_page", "Tour");
      theParamsRef.current = store.getSession("queryParams");
      tourIdRef.current = theParamsRef.current?.["id"];
      bookingCodeRef.current = theParamsRef.current?.["code"];
      langIdRef.current = theParamsRef.current?.["lang"];
      await updateGlobalStyles(tourIdRef.current, bookingCodeRef.current);

      sendGTMEvent("screen_view", { page_name: "Tour", partner_name: store.getSession("globalStyles.partner.name"), tour_id: tourIdRef.current, lang_id: langIdRef.current });

      skipLoginRef.current =
        theParamsRef.current?.["skipLogin"] === "true" || !(await isOnline());
      if (globalStyles?.skipLogin) {
        skipLoginRef.current = true
      }
      setTourData(store.getSession("tour_data"));
      if (!tourDataRef.current) {
        navigate(
          "/" +
          [
            "?id=",
            tourIdRef.current,
            "&code=",
            bookingCodeRef.current,
            "&lang=",
            langIdRef.current,
          ].join("")
        );
      }
      if (tourDataRef.current) {
        setIsOutdoor(!tourDataRef.current?.["isIndoors"]);
        setTourImage(await getTourMediaUrl(tourDataRef.current, "imageFile"));
        setTourThumbnail(
          await getTourMediaUrl(tourDataRef.current, "thumbFile")
        );
        setAuthorImage(
          await getTourMediaUrl(tourDataRef.current, "authorImage")
        );
        await loadLocation(tourDataRef.current);
        await loadItems(tourDataRef.current);
        await loadSponsor(tourDataRef.current);
        if (!(await mediaAlreadyLoaded())) {
          setShowLoader(true);
          setTimeout(() => {
            startLoader().then(() => { });
          }, 250);
        }
      }
    };
    initialize().then(() => { });
  }, []);

  return (
    <div className="tour-main-container main-container">
      <div
        className="tour-header-container"
        style={{ backgroundImage: "url(" + (tourImage) + ")" }}
      >
        <div className="tour-gradient tour-gradient"></div>

        <div className="tour-filler"></div>

        <div className="tour-title-container">
          <div className="tour-tag">
            <span className="tour-tag-text">Virtual Tours</span>
          </div>

          <div className="tour-author">
            <span className="tour-author-label">
              {util.getLocalizedMessage("$=tour_authorTitle")}
            </span>
            <span className="tour-author-name">
              {util.getLocalizedMessage(tourData?.author)}
            </span>
          </div>

          <div className="tour-the-title">
            <span className="tour-title-text">
              {util.getLocalizedMessage(tourData?.name)}
            </span>
          </div>
        </div>
      </div>

      <div className="tour-description-container">
        <span className="tour-description">
          {util.getLocalizedMessage(tourData?.description)}
        </span>
      </div>

      <div className="tour-author-container">
        <div className="tour-author-header">
          <img
            className="tour-author-avatar"
            src={store.dynamicAsset(authorImage)}
          />

          <div className="tour-author-title-container">
            <span className="tour-author-subtitle">
              {util.getLocalizedMessage("$=tour_author_subtitle")}
            </span>
            <span className="tour-author-title">
              {util.getLocalizedMessage(tourData?.author)}
            </span>
          </div>
        </div>

        <div className="tour-author-text">
          <span className="tour-author-description">
            {util.getLocalizedMessage(tourData?.authorDescription)}
          </span>
        </div>
      </div>

      <div className="tour-divider"></div>

      <div className="tour-location-container">
        {(hasStart ?? false) && (
          <span className="starting-point-title">
            {util.getLocalizedMessage("$=tour_startingPoint")}
          </span>
        )}
        {(hasStart ?? false) && (
          <StartingPointMap className="tour-google-map" markers={theMarkers} />
        )}
        {hasFinish && <div className="tour-line"></div>}
        {hasStart && (
          <div className="tour-start-point" onClick={openMapDirections}>
            <div className="tour-start-point-icon-container">
              <StartIcon globalStyles={globalStyles} />
            </div>

            <div className="tour-start-point-location">
              <span className="tour-start-point-name">
                {util.getLocalizedMessage(tourData?.starting_point?.name)}
              </span>
              <span className="tour-start-point-address">
                {util.getLocalizedMessage(tourData?.starting_point?.address)}
              </span>
            </div>
          </div>
        )}
        {hasFinish && (
          <div className="tour-finish-point">
            <div className="tour-finish-point-icon-container">
              <FinishIcon globalStyles={globalStyles} />
            </div>

            <div className="tour-finish-point-location">
              <span className="tour-finish-point-name">
                {util.getLocalizedMessage(tourData?.finishing_point?.name)}
              </span>
              <span className="tour-finish-point-address">
                {util.getLocalizedMessage(tourData?.finishing_point?.address)}
              </span>
            </div>
          </div>
        )}
      </div>

      <div className="tour-poi-container">
        <span className="tour-pois-title">
          {util.getLocalizedMessage("$=tour_pointsOfInterest")}
        </span>
        <PoiCarousel className="tour-poi-carousel" pois={thePois} />
      </div>
      {hasSponsor && (
        <div className="tour-sponsor-container">
          <span className="tour-sponsor-container-title">
            {util.getLocalizedMessage(tourData?.sponsorTitle)}
          </span>

          <div className="tour-sponsor-box">
            <div className="tour-sponsor-box-header">
              <img
                className="tour-sponsor-logo"
                src={store.dynamicAsset(sponsorImage)}
              />

              <div className="tour-sponsor-title-container">
                <span className="tour-sponsor-subtitle">
                  {util.getLocalizedMessage(tourData?.sponsorTitle)}
                </span>
                <span className="tour-sponsor-title">
                  {util.getLocalizedMessage(tourData?.sponsor)}
                </span>
              </div>
            </div>

            <div className="tour-sponsor-box-text">
              <span className="tour-sponsor-description">
                {util.getLocalizedMessage("$=tour_sponsor_description")}
              </span>
            </div>
          </div>
        </div>
      )}

      {globalStyles?.tour?.showPoweredBy && (
        <>
          <div className="tour-divider"></div>
          <div className="tour-powered-by-container">
            <img className="tour-powered-by-logo" src={AssetLoginLogo} />
            <div>
              Powered by&nbsp;
            </div>
            <div style={{ fontWeight: 'bold' }}>
              Clio Muse Tours
            </div>
          </div>
        </>
      )}

      <div className="tour-start-container" style={{ backgroundColor: globalStyles?.tour?.startContainerColor }} onClick={onStart}>
        <img className="tour-image" src={store.dynamicAsset(tourThumbnail)} />
        <span className="tour-start-text" style={{ color: globalStyles?.tour?.startContainerTextColor }}>
          {util.getLocalizedMessage("$=tour_start_text")}
        </span>

        <div className="tour-filler-2"></div>

        <div className="tour-start-button">
          <img className="tour-arrow-icon" src={AssetBack} />
        </div>
      </div>
      {(showLoader ?? false) && (
        <div className="tour-loader-container">
          <Loader className="tour-loader" constants={props.constants} />
        </div>
      )}
      {showGeoPrompt && (
        <div className="tour-geo-prompt-container">
          <Modal
            className="tour-geo-prompt-modal"
            onOk={onGeoPromptOK}
            constants={props.constants}
            message={
              "Please grant current location access to the app to improve your experience during the tour."
            }
          />
        </div>
      )}
      {showGeoSettings && (
        <div className="tour-geo-settings-container">
          <Modal
            className="tour-geo-settings-modal"
            onOk={onGeoSettingsOK}
            constants={props.constants}
            message={
              "You have denied current location access to the app. If you prefer a better experience during the tour, please go to settings to grant access."
            }
          />
        </div>
      )}
      {showLoginPrompt && (
        <div className="tour-login-prompt-container">
          <Modal
            className="tour-login-prompt-modal"
            onOk={onLogin}
            onCancel={onSkipLogin}
            constants={props.constants}
            message={
              "Log in with your account to keep the access to this tour."
            }
            okLabel={"Login"}
            cancelLabel={"Skip it"}
          />
        </div>
      )}
      {(isOutdoor ?? false) && (
        <div className="tour-hidden-maxbox-container">
          <MapBox
            className="tour-map-box"
            stops={thePois}
            mapStyle={tourData?.mapStyle}
            mapBounds={tourData?.mapBounds}
            minZoom={tourData?.minZoom}
            maxZoom={tourData?.maxZoom}
            carouselType={"cache"}
            showRoute={tourData?.showRoute}
          />
        </div>
      )}
    </div>
  );
};

export default Tour;
