import * as React from 'react';
import { useState, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import isEmail from 'validator/lib/isEmail';
import isStrongPassword from 'validator/lib/isStrongPassword';

const MuiTextField = ({ label, mode, type, value, onChange }) => {
  const [internalValue, setInternalValue] = useState(value);

  const [showPassword, setShowPassword] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const isValid = (newValue) => {
    let isValidValue = true;

    switch (type) {
      case 'email':
        isValidValue = isEmail(newValue ?? '');
        break;
      case 'password':
        isValidValue = isStrongPassword(newValue ?? '', {
          minLength: 4,
          // minLowercase: 1,
          // minUppercase: 1,
          // minNumbers: 1,
          // minSymbols: 0,
          // returnScore: false,
          // pointsPerUnique: 1,
          // pointsPerRepeat: 0.5,
          // pointsForContainingLower: 10,
          // pointsForContainingUpper: 10,
          // pointsForContainingNumber: 10,
          // pointsForContainingSymbol: 1,
        });
        break;
    }

    return isValidValue;
  };

  const validateValue = (newValue) => {
    let isValidValue = true;
    switch (type) {
      case 'email':
        isValidValue = isValid(newValue);
        if (!isValidValue) {
          setValidationMessage('Not a valid email address');
        } else {
          setValidationMessage('');
        }
        break;
      case 'password':
        isValidValue = isValid(newValue);
        if (!isValidValue) {
          setValidationMessage(
            'The password is not strong enough (at least 8 characters, upper case and lower case letters and numbers)'
          );
        } else {
          setValidationMessage('');
        }
        break;

      default:
        setValidationMessage('');
    }
    setHasError(!isValidValue);

    return isValidValue;
  };

  const onValueChanged = (e) => {
    let newValue = e.target.value;
    setInternalValue(newValue);
    let isValidValue = mode == 'validate' ? validateValue(newValue) : true;

    if (isValidValue && onChange) {
      onChange({ value: newValue, isValid: isValid(newValue) });
    }
  };

  useEffect(() => {
    setInternalValue(value);
    if (mode == 'validate') {
      validateValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (mode == 'validate') {
      validateValue(value);
    }
  }, [mode]);

  return (
    <>
      {type == 'email' && (
        <TextField
          error={hasError}
          type="email"
          fullWidth
          label={label}
          value={internalValue ?? ''}
          onChange={onValueChanged}
          helperText={validationMessage}
        />
      )}
      {(type == 'password' || type == 'password-login') && (
        <TextField
          error={hasError}
          type={showPassword ? 'text' : 'password'}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={label}
          value={internalValue ?? ''}
          onChange={onValueChanged}
          helperText={validationMessage}
        />
      )}
    </>
  );
};

export default MuiTextField;
