import { util } from "../../util";
import { store, useSessionState, useStateRef } from "../../util/store";
import { useEffect } from "react";
import { useCallback } from "react";



import "./Loader.css";
import AssetDownloading from "../../assets/Downloading.svg";

const Loader = () => {
  const [globalStyles] = useSessionState("globalStyles");

  const [message, setMessage] = useStateRef();

  const [progressWidth, setProgressWidth] = useStateRef();

  const [loadingError, setLoadingError] = useStateRef();

  const [showOK, setShowOk] = useStateRef();




  const updateLoadProgress = useCallback(async () => {
    let percent = store.getSession("loading_percent");
    if (percent === null) {
      percent = 0;
    }
    setProgressWidth(
      String(Math.round(((window.innerWidth - 60) * percent) / 100)) + "px"
    );
    if (percent === 100) {
      await onLoadingError();
    }
  }, []);

  const onLoadingError = useCallback(async () => {
    if (store.getSession("loading_error")) {
      setMessage("Downloaded");
      setShowOk(true);
      setLoadingError(store.getSession("loading_error"));
    } else {
      setLoadingError("");
    }
  }, []);

  const onOkTapped = useCallback(async () => {
    store.setSession("loading_ok", true);
  }, []);

  useEffect(
    () => store.subscribeSession("loading_percent", updateLoadProgress),
    []
  );

  useEffect(() => {
    setMessage("Downloading...");
    setShowOk(false);
  }, []);

  return (
    <div className="loader-backdrop-container">
      <div className="loader-box-container">
        <img className="loader-image" src={AssetDownloading} />
        <span className="loader-text">{util.getLocalizedMessage(message)}</span>

        <div className="loader-mini-progress-bar-container">
          <div
            className="loader-mini-current-progress"
            style={{ width: (progressWidth ?? "0px"), backgroundColor: globalStyles?.loader?.progressColor }}
          ></div>
        </div>
        {loadingError && (
          <div className="loader-error-container">
            <span className="loader-error-text">
              {util.getLocalizedMessage(loadingError)}
            </span>
          </div>
        )}

        <div className="loader-button-container">
          {(showOK ?? false) && (
            <div className="loader-ok-button" style={{ backgroundColor: globalStyles?.loader?.buttonColor }} onClick={onOkTapped}>
              <span className="loader-ok-button-text">
                {util.getLocalizedMessage("$=loader_ok_button_text")}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Loader;
