

import { util } from "../util/index";
import { store, useStateRef } from "../util/store";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useRef } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import _ from "lodash";
import MuiTextField from "../components/common/MuiTextField";
import MuiCheckbox from "../components/common/MuiCheckbox";
import {
  doSignup,
  getLanguageCode
} from "../components/CallApi";

import "./SignUp.css";
import AssetLoginLogo from "../assets/LoginLogo.svg";
import { useSessionState } from "../util/store";
import { updateGlobalStyles } from "../util/styling";

const SignUp = () => {
  const [globalStyles] = useSessionState("globalStyles");

  let navigate = useNavigate();

  let location = useLocation();

  const [email, setEmail, emailRef] = useStateRef();

  const [formMode, setFormMode] = useStateRef();

  const [password, setPassword, passwordRef] = useStateRef();

  const [agree, setAgree, agreeRef] = useStateRef();

  const isEmailValidRef = useRef();
  const isPasswordValidRef = useRef();
  const theParamsRef = useRef();
  const tourIdRef = useRef();
  const bookingCodeRef = useRef();
  const langIdRef = useRef();

  util.configureLocalization("messages", store.getSession("messages"));
  util.configureLocalization("language", getLanguageCode(location));

  const onEmailChanged = useCallback(async (event) => {
    isEmailValidRef.current = event?.["isValid"];
    if (isEmailValidRef.current) {
      setEmail(event?.["value"]);
    }
  }, []);

  const onPasswordChanged = useCallback(async (event) => {
    isPasswordValidRef.current = event?.["isValid"];
    if (isPasswordValidRef.current) {
      setPassword(event?.["value"]);
    }
  }, []);

  const onAgreeChanged = useCallback(async (value) => {
    setAgree(value);
  }, []);

  const onSignUp = useCallback(async () => {
    if (
      isEmailValidRef.current &&
      isPasswordValidRef.current &&
      agreeRef.current
    ) {
      let user = await doSignup(emailRef.current, passwordRef.current);
      if (user?.["error"]) {
        util.showError(user?.["error"], 4000);
      } else {
        await onLogin();
      }
    } else {
      setFormMode("validate");
    }
  }, []);

  const onPrivacyPolicy = useCallback(async () => {
    window.open("https://cliomusetours.com/privacy-policy/", "_blank");
  }, []);

  const onTermsOfUse = useCallback(async () => {
    window.open("https://cliomusetours.com/terms-and-conditions/", "_blank");
  }, []);

  const onLogin = useCallback(async () => {
    navigate(
      "/login" +
      [
        "?id=",
        tourIdRef.current,
        "&code=",
        bookingCodeRef.current,
        "&lang=",
        langIdRef.current,
      ].join("")
    );
  }, []);

  useEffect(() => {
    const initialize = async () => {
      store.setSession(
        "queryParams",
        Object.fromEntries(new URLSearchParams(location.search))
      );
      theParamsRef.current = store.getSession("queryParams");
      tourIdRef.current = theParamsRef.current?.["id"];
      bookingCodeRef.current = theParamsRef.current?.["code"];
      langIdRef.current = theParamsRef.current?.["lang"];
      await updateGlobalStyles(tourIdRef.current, bookingCodeRef.current);

      setEmail("");
      isEmailValidRef.current = false;
      setPassword("");
      isPasswordValidRef.current = false;
      setAgree(false);
      setFormMode("type");
    }
    initialize().then(() => { });
  }, []);

  return (
    <div className="sign-up-container">
      <div className="sign-up-header-container">
        {globalStyles?.splash == "cliomuse" &&
          <img className="sign-up-image" src={AssetLoginLogo} />
        }
        {globalStyles?.splash == "cobrand" &&
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
            <img className="sign-up-image" src={globalStyles?.logos?.small ?? ''} />
            <img className="sign-up-image" src={AssetLoginLogo} />
          </div>
        }
        {globalStyles?.splash == "whitelabel" &&
          <img className="sign-up-image" src={globalStyles?.logos?.small ?? ''} />
        }

        <div className="sign-up-title-container">
          <span className="sign-up-title">
            {util.getLocalizedMessage("$=signUp_title")}
          </span>
          <span className="sign-up-subtitle" style={{ color: globalStyles?.modal?.cancelColor }}>
            {util.getLocalizedMessage("$=signUp_subtitle")}
          </span>
        </div>
      </div>

      <div className="sign-up-form-container">
        <div className="sign-up-email-container">
          <MuiTextField
            className="sign-up-mui-text-field"
            onChange={_.throttle((value) => onEmailChanged(value), 500)}
            label={util.getLocalizedMessage("$=email")}
            type={"email"}
            value={email}
            mode={formMode}
          />
        </div>

        <div className="sign-up-password-container">
          <MuiTextField
            className="sign-up-mui-text-field-2"
            onChange={_.throttle((value) => onPasswordChanged(value), 500)}
            label={util.getLocalizedMessage("$=password")}
            type={"password"}
            value={password}
            mode={formMode}
          />
        </div>
      </div>

      <div className="sign-up-terms-container">
        <MuiCheckbox
          className="sign-up-mui-checkbox"
          onChange={_.throttle((value) => onAgreeChanged(value), 500)}
          value={agree}
        />
        <span className="sign-up-agree-text">
          {util.getLocalizedMessage("$=signUp_agree_text")}
        </span>
        <span className="sign-up-policy-text" onClick={onPrivacyPolicy}>
          {util.getLocalizedMessage("$=signUp_privacy_policy")}
        </span>
        <span className="sign-up-and-text">
          {util.getLocalizedMessage("$=signUp_and_text")}
        </span>
        <span className="sign-up-terms-text" onClick={onTermsOfUse}>
          {util.getLocalizedMessage("$=signUp_terms_text")}
        </span>
      </div>

      <div className="sign-up-button-container">
        <div className="sign-up-signup-button" onClick={onSignUp} style={{ backgroundColor: globalStyles?.modal?.okColor, borderColor: globalStyles?.modal?.cancelColor }}>
          <span className="sign-up-signup-text" style={{ color: globalStyles?.modal?.okTextColor }}>
            {util.getLocalizedMessage("$=signUp_signup_text")}
          </span>
        </div>
      </div>

      <div className="sign-up-footer-container">
        <span className="sign-up-have-account-text">
          {util.getLocalizedMessage("$=signUp_have_account_text")}
        </span>
        <span className="sign-up-login-text" onClick={onLogin} style={{ color: globalStyles?.modal?.okColor }}>
          {util.getLocalizedMessage("$=signUp_login_text")}
        </span>
      </div>
    </div>
  );
};

export default SignUp;
