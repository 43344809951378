/* eslint-disable react/prop-types */

import { useRef, useEffect, useState } from "react";
import { store, useSessionState } from "../../util/store";

import { useWindowSize } from "@react-hook/window-size";

// https://docs.mapbox.com/mapbox-gl-js/guides/
import mapboxgl from "mapbox-gl";
mapboxgl.prewarm();
import "mapbox-gl/dist/mapbox-gl.css";
import { getMarkerSvg } from "../../util/styling";

const ACCESS_TOKEN = "pk.eyJ1IjoiY2xpb211c2VhcHAiLCJhIjoiY2xlc3Ewa2tpMThyazNycnZqdG93eTNoMSJ9.dTRr6i3NKnE7dbK7CZfkdQ";

const createHtmlMarker = (index, onPoiMarkerTapped, isFirst, globalStyles) => {
  const container = document.createElement("div");
  if (isFirst) {
    container.classList.add('first-poi-marker');
  }
  container.style.width = "32px";
  container.style.height = "40px";
  container.style.cursor = "pointer";
  container.addEventListener("click", () => {
    if (onPoiMarkerTapped) {
      onPoiMarkerTapped(index);
    }
  });

  container.innerHTML = getMarkerSvg(globalStyles, "position: absolute; top: -20px;");

  const label = document.createElement("div");
  label.innerText = "" + index;
  label.style.position = "absolute";
  label.style.top = "-12px";
  label.style.width = "32px";
  label.style["text-align"] = "center";
  label.style.color = "white";
  label.style["font-size"] = "16px";
  label.style["font-weight"] = "bold";
  container.appendChild(label);

  return container;
};

const MapBox = ({ minZoom, maxZoom, mapStyle, mapBounds, stops, onPoiMarkerTapped, carouselType, showRoute, ...containerProps }) => {
  const [globalStyles] = useSessionState("globalStyles");

  const [windowWidth, windowHeight] = useWindowSize();

  const mapContainerRef = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [bounds, setBounds] = useState(null);
  const boundsPadding = 20; // pixels

  const [flyToList, setFlyToList] = useState([]);

  const calculateBounds = (stops) => {
    let bounds = null;
    if (mapBounds) {
      bounds = new mapboxgl.LngLatBounds([mapBounds.southWest.lon, mapBounds.southWest.lat], [mapBounds.northEast.lon, mapBounds.northEast.lat]);
    }

    for (let stop of stops) {
      if (isNaN(stop.lat) || isNaN(stop.lng)) {
        continue;
      }

      if (!bounds) {
        bounds = new mapboxgl.LngLatBounds([stop.lng, stop.lat], [stop.lng, stop.lat]);
      } else {
        bounds.extend([stop.lng, stop.lat]);
      }
    }

    return bounds;
  };

  const applyBounds = (map, bounds, fit) => {
    if (!map || !bounds) {
      return;
    }

    let maxBounds = mapboxgl.LngLatBounds.convert(bounds);
    map.setMaxBounds(maxBounds);

    if (fit) {
      map.fitBounds(bounds, { padding: boundsPadding });
    }
  };

  // https://docs.mapbox.com/help/tutorials/getting-started-directions-api/
  const addRoute = async (map, pathCoordinates) => {
    let added = false;
    try {
      let coordinates = pathCoordinates.map((c) => `${c[0]},${c[1]}`).join(";");

      const query = await fetch(`https://api.mapbox.com/directions/v5/mapbox/walking/${coordinates}?steps=true&overview=full&walkway_bias=-1&geometries=geojson&access_token=${ACCESS_TOKEN}`);
      const json = await query.json();
      const data = json.routes[0];
      if (data) {
        const route = data.geometry.coordinates;
        const geojson = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: route
          }
        };

        if (map.getSource('route')) {
          map.getSource('route').setData(geojson);
        } else {
          map.addLayer({
            id: 'route',
            type: 'line',
            source: {
              type: 'geojson',
              data: geojson
            },
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              "line-color": "#31AEED",
              "line-width": 4,
              "line-dasharray": [3, 2],
            },
          });
        }

        added = true;
      }
    } catch (e) {
      console.log(e);
    }

    if (!added) {
      map.addSource("route", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: pathCoordinates,
          },
        },
      });
      map.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#31AEED",
          "line-width": 4,
          "line-dasharray": [3, 2],
        },
      });
    }
  };

  const resetView = (map, bounds) => {
    applyBounds(map, bounds);

    if (carouselType == "cache") {
      let firstPos = { center: bounds.getSouthWest() };
      setFlyToList([
        { center: bounds.getNorthWest() },
        { center: bounds.getNorthEast() },
        { center: bounds.getSouthEast() }
      ]);
      // console.log("caching mapbox tiles for", firstPos);
      map.flyTo(firstPos);
    } else {
      if (store.getSession("map_zoom") && store.getSession("map_center")) {
        map.setZoom(store.getSession("map_zoom"));
        setTimeout(() => {
          map.flyTo({ center: store.getSession("map_center") });
        }, 500);
      } else {
        let firstStop = stops?.[0];
        if (firstStop) {
          setTimeout(() => {
            map.flyTo({ center: new mapboxgl.LngLat(firstStop.lng, firstStop.lat) });
          }, 500);
        }
      }
    }
  };

  const addMarkers = (map, stops, onPoiMarkerTapped) => {
    const pathCoordinates = [];

    let isFirst = true;
    for (let stop of stops) {
      if (isNaN(stop.lat) || isNaN(stop.lng)) {
        continue;
      }

      let container = createHtmlMarker(stop.index, onPoiMarkerTapped, isFirst, globalStyles);
      isFirst = false;

      let marker = new mapboxgl.Marker(container);
      marker.setLngLat([stop.lng, stop.lat]);
      marker.addTo(map);

      pathCoordinates.push([stop.lng, stop.lat]);
    }

    if (showRoute) {
      addRoute(map, pathCoordinates);
    }
  };

  useEffect(() => {
    if (!stops) {
      return;
    }

    let bounds = calculateBounds(stops);

    let map = mapInstance;
    if (!map) {
      setBounds(bounds);

      let config = {
        accessToken: ACCESS_TOKEN,
        container: mapContainerRef.current,
        style: mapStyle || "mapbox://styles/mapbox/streets-v12",
        minZoom,
        maxZoom,
        bounds,
        fitBoundsOptions: { padding: boundsPadding },
        // cooperativeGestures: true,
      };

      map = new mapboxgl.Map(config);

      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
          showUserHeading: true,
          showAccuracyCircle: true,
        }),
        "top-right"
      );

      map.on('zoom', () => {
        if (store.getSession("current_page") == "Map") {
          store.setSession("map_zoom", map.getZoom());
        }
      });
      map.on('move', () => {
        if (store.getSession("current_page") == "Map") {
          store.setSession("map_center", map.getCenter());
        }
      });

      setMapInstance(map);
    }

    map.once("load", () => {
      addMarkers(map, stops, onPoiMarkerTapped);
      resetView(map, bounds);
    });

    map.on("data", () => {
      if (flyToList.length > 0) {
        let pos = flyToList.shift();
        setFlyToList([...flyToList]);
        // console.log("caching mapbox tiles for", pos);
        map.flyTo(pos);
      }
    })

  }, [minZoom, maxZoom, stops, showRoute, mapStyle]);

  useEffect(() => {
    if (mapInstance) {
      mapInstance.resize();
      resetView(mapInstance, bounds);
    }
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    if (mapInstance) {
      mapInstance.resize();
    }
  }, [carouselType]);

  return <div {...containerProps} ref={mapContainerRef}></div>;
};

export default MapBox;
