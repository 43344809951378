

import { util } from "../../util/index";
import { useStateRef } from "../../util/store";
import { useRef } from "react";
import { useEffect } from "react";
import {
  getItemMediaUrl,
} from "../CallApi";



import "./ItemCardHead.css";

const ItemCardHead = (props) => {
  const [itemImage, setItemImage, itemImageRef] = useStateRef();

  const theItemRef = useRef();



  useEffect(() => {
    const initialize = async () => {
      theItemRef.current = props?.["item"];
      setItemImage(await getItemMediaUrl(theItemRef.current, "imageFile"));
    };
    initialize().then(() => { });
  }, [props]);

  return (
    <div className="item-card-head-main-container">
      <div
        className="item-card-head-item-image"
        style={{ backgroundImage: "url(" + (itemImage) + ")" }}
      ></div>
    </div>
  );
};

export default ItemCardHead;
