

import { util } from "../../util/index";
import { store, useSessionState, useStateRef } from "../../util/store";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import {
  getItemMediaUrl,
  getStoryMediaUrl,
  playAudio,
  pauseAudio,
  msToMinSec,
  playNextAudio,
  playPreviousAudio,
  seekAudioTo,
} from "../CallApi";
import StoryText from "./StoryText";
import PoiCarousel from "../common/PoiCarousel";
import SwipeableContainer from "./SwipeableContainer";

import "./PlayerControl.css";
import AssetPlay10Sec from "../../assets/Play10sec.svg";
import AssetLike from "../../assets/Like.svg";
import AssetLoginLogo from "../../assets/LoginLogo.svg";
import { LikeIcon, NextStoryIcon, PauseIcon, PlayIcon, PreviousStoryIcon, TenSecIcon } from "../../util/stylingComponents";

const PlayerControl = (props) => {
  const [globalStyles] = useSessionState("globalStyles");

  let navigate = useNavigate();

  const [miniHeight, setMiniHeight] = useStateRef();

  const [progressWidth, setProgressWidth] = useStateRef();

  const [currentStory, setCurrentStory, currentStoryRef] = useStateRef();

  const [currentStoryType, setCurrentStoryType] =
    useStateRef();

  const [playPauseImage, setPlayPauseImage] = useStateRef();

  const [itemTitle, setItemTitle] = useStateRef();

  const [playedTime, setPlayedTime] = useStateRef();

  const [totalTime, setTotalTime] = useStateRef();

  const [multimediaImage, setMultimediaImage] =
    useStateRef();

  const [hasMultimedia, setHasMultimedia] = useStateRef();

  const [nextStops, setNextStops] = useStateRef();

  const [hasNextStops, setHasNextStops] = useStateRef();

  const [fullHeight, setFullHeight] = useStateRef();

  const heightAnimationDurationRef = useRef();
  const theTourRef = useRef();
  const currentItemRef = useRef();
  const playingItemRef = useRef();
  const isStoryMiniRef = useRef();
  const itemRef = useRef();
  const theStoriesRef = useRef();

  const onNextStop = useCallback(async (poi) => {
    store.setSession("current_item", poi);
    if (store.getSession("current_page") === "Map") {
      navigate(
        "/items" +
        [
          "?id=",
          props?.tourId,
          "&code=",
          props?.bookingCode,
          "&lang=",
          props?.langId,
        ].join("")
      );
    } else if (store.getSession("current_page") === "Items") {
      await changeCurrentItem();
      await onMinimize();
      await playAudio(poi, null);
    }
  }, [props]);

  const on10secBack = useCallback(async () => {
    await seekAudioTo(-10);
  }, []);

  const onNextStory = useCallback(async () => {
    await playNextAudio();
  }, []);

  const onPreviousStory = useCallback(async () => {
    await playPreviousAudio();
  }, []);

  const onPlayPause = useCallback(async () => {
    if (store.getSession("playing_item.id")) {
      pauseAudio();
      store.setSession("playing_item", null);
    } else {
      store.setSession("playing_item", currentItemRef.current);
      await playAudio(currentItemRef.current, currentStoryRef.current);
    }
  }, []);

  const setProgress = useCallback(async (percent) => {
    if (percent === null) {
      percent = 0;
    }
    setProgressWidth(
      String(Math.round(((window.innerWidth - 60) * percent) / 100)) + "px"
    );
  }, []);

  const showMini = useCallback(async () => {
    setMiniHeight("75px");
  }, []);

  const changeCurrentStory = useCallback(async () => {
    setCurrentStory(store.getSession("current_story"));
    setTotalTime(await msToMinSec(currentStoryRef.current?.["duration"]));
    let categories = currentStoryRef.current?.["category"]?.["values"];
    setCurrentStoryType(
      categories && categories.length > 0 ? categories[0] : ""
    );
    await setMultimedia();
  }, []);

  const onMinimize = useCallback(async () => {
    setFullHeight("0px");
    setTimeout(() => {
      showMini().then(() => { });
    }, Math.round(heightAnimationDurationRef.current * 0.9 * 1000));
  }, []);

  const hideMini = useCallback(async () => {
    setMiniHeight("0px");
  }, []);

  const onMaximize = useCallback(async () => {
    setFullHeight(String(Math.round(window.innerHeight * 0.95)) + "px");
    setTimeout(() => {
      hideMini().then(() => { });
    }, Math.round(heightAnimationDurationRef.current * 0.25 * 1000));
  }, []);

  const onShowImage = useCallback(async () => {
    store.setSession("show_full_story_image", true);
  }, []);

  const loadNextStops = useCallback(async (tour) => {
    let items = tour?.["items"];
    let tempStops = [];
    if (items) {
      for (itemRef.current of items) {
        if (itemRef.current?.["index"] <= currentItemRef.current?.["index"]) {
          continue;
        }
        let titleIndex =
          itemRef.current?.["index"] < 10
            ? ["0", itemRef.current?.["index"], ". "].join("")
            : String(itemRef.current?.["index"]) + ". ";
        tempStops.push({
          ...itemRef.current,
          title: String(titleIndex) + String(itemRef.current?.["name"]),
          imageUrl: await getItemMediaUrl(itemRef.current, "imageFile"),
        });
      }
    }
    setHasNextStops(tempStops.length > 0);
    setNextStops(tempStops);
  }, []);

  const setMultimedia = useCallback(async () => {
    if (currentStoryRef.current?.["imageFile"]) {
      setHasMultimedia(true);
      setMultimediaImage(
        await getStoryMediaUrl(currentStoryRef.current, "imageFile")
      );
    } else {
      setHasMultimedia(false);
      setMultimediaImage(null);
    }
  }, []);

  const setThePlayingItem = useCallback(async () => {
    playingItemRef.current = store.getSession("playing_item");
    if (playingItemRef.current?.["id"]) {
      setPlayPauseImage("Pause");
    } else {
      setPlayPauseImage("Play");
    }
  }, []);

  const setThePlayingTime = useCallback(async () => {
    let currentTime = store.getSession("playing_time");
    if (!currentTime) {
      currentTime = 0;
    }
    await setProgress(
      currentStoryRef.current?.["duration"]
        ? ((currentTime * 1000) / currentStoryRef.current?.["duration"]) * 100
        : 0
    );
    setPlayedTime(await msToMinSec(currentTime * 1000));
  }, []);

  const changeCurrentItem = useCallback(async () => {
    currentItemRef.current = store.getSession("current_item");
    setItemTitle(
      [
        currentItemRef.current?.["index"] < 10 ? "0" : "",
        currentItemRef.current?.["index"],
        ". ",
        currentItemRef.current?.["name"],
      ].join("")
    );
    setCurrentStory(store.getSession("current_story"));
    if (currentItemRef.current && !currentStoryRef.current) {
      theStoriesRef.current = currentItemRef.current?.["stories"];
      setCurrentStory(theStoriesRef.current[0]);
    }
    store.setSession("current_story", currentStoryRef.current);
  }, []);

  useEffect(
    () => store.subscribeSession("current_item", changeCurrentItem),
    []
  );
  useEffect(
    () => store.subscribeSession("current_story", changeCurrentStory),
    []
  );
  useEffect(
    () => store.subscribeSession("playing_item", setThePlayingItem),
    []
  );
  useEffect(
    () => store.subscribeSession("playing_time", setThePlayingTime),
    []
  );

  useEffect(() => {
    const initialize = async () => {
      heightAnimationDurationRef.current = 0.5;
      theTourRef.current = props?.["tour"];
      isStoryMiniRef.current = props?.["showStoryMini"];
      if (isStoryMiniRef.current) {
        setFullHeight("0px");
        setMiniHeight("0px");
      } else {
        await loadNextStops(theTourRef.current);
        await setMultimedia();
      }
      setPlayedTime("0:00");
      setTotalTime("0:00");
      await setThePlayingItem();
      await setThePlayingTime();
      await changeCurrentItem();
    };
    initialize().then(() => { });
  }, [props]);

  return (
    <div className="player-control-main-container">
      <SwipeableContainer
        className="player-control-mini-container"
        style={{ height: (miniHeight ?? "75px"), backgroundColor: globalStyles?.player?.backgroundMini }}
        onSwipedLeft={onNextStory}
        onSwipedRight={onPreviousStory}
        onSwipedUp={onMaximize}
      >
        <div className="player-control-mini-progress-bar-container">
          <div
            className="player-control-mini-current-progress"
            style={{ width: (progressWidth ?? "0px"), backgroundColor: globalStyles?.player?.progressColor }}
          ></div>
        </div>

        <div
          className="player-control-mini-grab-container"
          onClick={onMaximize}
        >
          <div className="player-control-mini-grab"></div>
        </div>

        <div className="player-control-mini-info-container">
          <div className="player-control-title-container">
            <div className="player-control-mini-story-title-container">
              <span className="player-control-mini-story-title title-text">
                {util.getLocalizedMessage(currentStory?.title)}
              </span>
            </div>

            <div className="player-control-mini-story-type-container">
              <span className="player-control-mini-story-type title-text">
                {util.getLocalizedMessage(currentStoryType)}
              </span>
            </div>
          </div>

          <div className="player-control-mini-control-container">
            <img
              className="player-control-mini-tensec-button"
              onClick={on10secBack}
              src={AssetPlay10Sec}
            />
            <img className="player-control-mini-like-button" src={AssetLike} />
            <img
              className="player-control-mini-play-pause-button"
              onClick={onPlayPause}
              src={store.dynamicAsset(playPauseImage)}
            />
          </div>
        </div>
      </SwipeableContainer>

      <div
        className="player-control-full-container"
        style={{ height: (fullHeight ?? "0px"), backgroundColor: globalStyles?.player?.backgroundFull }}
      >
        <SwipeableContainer
          className="player-control-header-container"
          onSwipedDown={onMinimize}
        >
          <div className="player-control-grab-container" onClick={onMinimize}>
            <div className="player-control-grab-2"></div>
          </div>

          <div className="player-control-story-title-container">
            <span className="player-control-story-title" style={{ color: globalStyles?.player?.titleColor }}>
              {util.getLocalizedMessage(currentStory?.title)}
            </span>
          </div>

          <div className="player-control-item-title-container">
            <span className="player-control-item-title" style={{ color: globalStyles?.player?.textColor }}>
              {util.getLocalizedMessage(itemTitle)}
            </span>
          </div>
        </SwipeableContainer>

        <div className="player-control-scroll-container">
          <div className="player-control-progress-bar-container">
            <div
              className="player-control-current-progress"
              style={{ width: (progressWidth ?? "0px"), backgroundColor: globalStyles?.player?.progressColor }}
            ></div>
          </div>

          <div className="player-control-time-container">
            <span className="player-control-played-time-text" style={{ color: globalStyles?.player?.textColor }}>
              {util.getLocalizedMessage(playedTime)}
            </span>
            <span className="player-control-total-time-text" style={{ color: globalStyles?.player?.textColor }}>
              {util.getLocalizedMessage(totalTime)}
            </span>
          </div>

          <div className="player-control-control-container">
            <div
              className="player-control-10sec-button"
              onClick={on10secBack}
            >
              <TenSecIcon globalStyles={globalStyles} size={20} />
            </div>
            <div
              className="player-control-prev-story-button"
              onClick={onPreviousStory}
            >
              <PreviousStoryIcon globalStyles={globalStyles} size={20} />
            </div>
            <div
              className="player-control-play-pause-button"
              onClick={onPlayPause}
              src={store.dynamicAsset(playPauseImage)}
            >
              {playPauseImage == "Play"
                ? <PlayIcon globalStyles={globalStyles} size={60} />
                : <PauseIcon globalStyles={globalStyles} size={60} />}
            </div>
            <div
              className="player-control-next-story-button"
              onClick={onNextStory}
            >
              <NextStoryIcon globalStyles={globalStyles} size={20} />
            </div>
            <div className="player-control-like-button" >
              <LikeIcon globalStyles={globalStyles} size={20} />
            </div>
          </div>

          <div className="player-control-content-container">
            <div className="player-control-story-container">
              <StoryText
                className="player-control-story-text"
                constants={props.constants}
                story={currentStory}
              />
            </div>
            {(hasMultimedia ?? false) && (
              <div className="player-control-multimedia-container">
                <span className="player-control-multimedia-title" style={{ color: globalStyles?.player?.titleColor }}>
                  {util.getLocalizedMessage("$=playerControl_multimedia_title")}
                </span>
                <img
                  className="player-control-image"
                  onClick={onShowImage}
                  src={store.dynamicAsset(multimediaImage)}
                />
              </div>
            )}
            {(hasNextStops ?? false) && (
              <div className="player-control-next-steps-container">
                <span className="player-control-next-stops-title" style={{ color: globalStyles?.player?.titleColor }}>
                  {util.getLocalizedMessage("$=playerControl_next_stops_title")}
                </span>

                <div className="player-control-next-stops-carousel-container" style={{ color: globalStyles?.player?.titleColor }}>
                  <PoiCarousel
                    className="player-control-poi-carousel"
                    onPoiTapped={onNextStop}
                    type={"player-control"}
                    pois={nextStops}
                  />
                </div>
              </div>
            )}
            {globalStyles?.player?.showPoweredBy && (
              <>
                <div className="player-control-divider"></div>
                <div className="player-control-powered-by-container" style={{ color: globalStyles?.player?.titleColor }}>
                  <img className="player-control-powered-by-logo" src={AssetLoginLogo} />
                  <div>
                    Powered by&nbsp;
                  </div>
                  <div style={{ fontWeight: 'bold' }}>
                    Clio Muse Tours
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {props?.showStoryMini && (
        <div className="player-control-story-mini-container">
          <div className="player-control-progress-bar-container-2">
            <div
              className="player-control-current-progress-2"
              style={{ width: (progressWidth ?? "0px"), backgroundColor: props?.categoryType ? globalStyles?.fullStoryText?.[props.categoryType]?.iconColor : globalStyles?.player?.progressColor }}
            ></div>
          </div>

          <div className="player-control-time-container-2">
            <span className="player-control-played-time-text-2" style={{ color: props?.categoryType ? globalStyles?.fullStoryText?.[props.categoryType]?.timeColor : globalStyles?.player?.textColor }}>
              {util.getLocalizedMessage(playedTime)}
            </span>
            <span className="player-control-total-time-text-2" style={{ color: props?.categoryType ? globalStyles?.fullStoryText?.[props.categoryType]?.timeColor : globalStyles?.player?.textColor }}>
              {util.getLocalizedMessage(totalTime)}
            </span>
          </div>

          <div className="player-control-control-container-2">
            {props?.categoryType && (<div
              className="player-control-play-pause-button-2"
              onClick={onPlayPause}>
              {playPauseImage == "Play"
                ? <PlayIcon globalStyles={globalStyles} categoryType={props?.categoryType} size={60} />
                : <PauseIcon globalStyles={globalStyles} categoryType={props?.categoryType} size={60} />}
            </div>
            )}
            {!props.categoryType && (<img
              className="player-control-play-pause-button-2"
              onClick={onPlayPause}
              src={store.dynamicAsset(playPauseImage)}
            />)}
          </div>
        </div>
      )}
    </div>
  );
};

export default PlayerControl;
