

import { util } from "../util/index";
import { store, useSessionState, useStateRef } from "../util/store";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import ItemCardHead from "../components/items/ItemCardHead";
import ItemCarousel from "../components/items/ItemCarousel";
import ItemCardBody from "../components/items/ItemCardBody";
import PlayerControl from "../components/player/PlayerControl";
import {
  playAudio,
  pauseAudio,
  isOnboarded,
  getLanguageCode,
  sendGTMEvent,
} from "../components/CallApi";
import StoryTextFull from "../components/player/StoryTextFull";
import StoryImageFull from "../components/player/StoryImageFull";
import Onboarding from "../components/common/Onboarding";

import "./Items.css";
import { updateGlobalStyles } from "../util/styling";
import { BackIcon, GoToMapIcon } from "../util/stylingComponents";

const Items = (props) => {
  const [globalStyles] = useSessionState("globalStyles");

  let navigate = useNavigate();

  let location = useLocation();

  const [theItems, setTheItems, theItemsRef] = useStateRef();

  const [poiIndex, setPoiIndex, poiIndexRef] = useStateRef();

  const [currentItem, setCurrentItem, currentItemRef] = useStateRef();

  const [itemAnimation, setItemAnimation] = useStateRef();

  const [showMiniPlayer, setShowMiniPlayer] = useStateRef();

  const [tourData, setTourData, tourDataRef] = useStateRef();

  const [showFullStory, setShowFullStory] = useStateRef();

  const [showFullStoryImage, setShowFullStoryImage] =
    useStateRef();

  const tourIdRef = useRef();
  const bookingCodeRef = useRef();
  const langIdRef = useRef();
  const theStoriesRef = useRef();
  const theParamsRef = useRef();
  const playingItemRef = useRef();

  util.configureLocalization("messages", store.getSession("messages"));
  util.configureLocalization("language", getLanguageCode(location));

  const setTheCurrentItem = useCallback(async () => {
    setCurrentItem(theItemsRef.current[poiIndexRef.current - 1]);
    store.setSession("current_item", currentItemRef.current);
    theStoriesRef.current = currentItemRef.current?.["stories"];
    setItemAnimation("item-in-animation 0.2s");
    if (await isOnboarded("items")) {
      await playAudio(currentItemRef.current, null);
    }
  }, []);

  const onItemIndexChange = useCallback(async (index) => {
    setPoiIndex(index);
    setItemAnimation("item-out-animation 0.2s");
    setTimeout(() => {
      setTheCurrentItem().then(() => { });
    }, 200);
  }, []);

  const onMap = useCallback(async () => {
    navigate(
      "/map" +
      [
        "?id=",
        tourIdRef.current,
        "&code=",
        bookingCodeRef.current,
        "&lang=",
        langIdRef.current,
      ].join("")
    );
  }, []);

  const onBack = useCallback(async () => {
    navigate(
      "/map" +
      [
        "?id=",
        tourIdRef.current,
        "&code=",
        bookingCodeRef.current,
        "&lang=",
        langIdRef.current,
      ].join("")
    );
  }, []);

  const manageFullStory = useCallback(async () => {
    if (store.getSession("show_full_story")) {
      setShowFullStory(true);
    } else {
      setShowFullStory(false);
    }
  }, []);

  const manageFullStoryImage = useCallback(async () => {
    if (store.getSession("show_full_story_image")) {
      setShowFullStoryImage(true);
    } else {
      setShowFullStoryImage(false);
    }
  }, []);

  const goToCurrentItem = useCallback(async () => {
    setCurrentItem(store.getSession("current_item"));
    if (!currentItemRef.current) {
      setItemAnimation("item-out-animation 0.5s");
      setTimeout(() => {
        setTheCurrentItem().then(() => { });
      }, 500);
    }
    if (currentItemRef.current) {
      setPoiIndex(currentItemRef.current?.["index"]);
    }
  }, []);

  useEffect(() => store.subscribeSession("current_item", goToCurrentItem), []);
  useEffect(
    () => store.subscribeSession("show_full_story", manageFullStory),
    []
  );
  useEffect(
    () => store.subscribeSession("show_full_story_image", manageFullStoryImage),
    []
  );

  useEffect(() => {
    const initialize = async () => {
      store.setSession(
        "queryParams",
        Object.fromEntries(new URLSearchParams(location.search))
      );
      [][0];

      store.setSession("current_page", "Items");
      theParamsRef.current = store.getSession("queryParams");
      tourIdRef.current = theParamsRef.current?.["id"];
      bookingCodeRef.current = theParamsRef.current?.["code"];
      langIdRef.current = theParamsRef.current?.["lang"];
      await updateGlobalStyles(tourIdRef.current, bookingCodeRef.current);

      sendGTMEvent("screen_view", { page_name: "Items", partner_name: store.getSession("globalStyles.partner.name"), tour_id: tourIdRef.current, lang_id: langIdRef.current });

      try {
        if (!(await isOnboarded("items"))) {
          pauseAudio();
        }
      } catch (e) {
        console.log(e);
      }

      playingItemRef.current = store.getSession("playing_item");
      setShowMiniPlayer(true);
      setTourData(store.getSession("tour_data"));
      setPoiIndex(1);
      if (!tourDataRef.current) {
        // to get user action for audio (iOS)
        navigate(
          "/" +
          [
            "?id=",
            tourIdRef.current,
            "&code=",
            bookingCodeRef.current,
            "&lang=",
            langIdRef.current,
          ].join("")
        );

      }
      if (tourDataRef.current) {
        setTheItems(tourDataRef.current?.["items"]);
        await goToCurrentItem();
      } else {
        setTheItems([]);
      }
    };
    initialize().then(() => { });
  }, []);

  return (
    <div className="items-container">
      <div className="items-buttons-container">
        <div className="items-back-button blurred-background" onClick={onBack}>
          <div className="items-back-icon"><BackIcon globalStyles={globalStyles} size={35} /></div>
        </div>

        <div className="items-map-button" onClick={onMap}>
          <div className="items-map-text" style={{ color: globalStyles?.navigationColor }}>
            {util.getLocalizedMessage("$=items_map_text")}
          </div>
          <div className="items-map-icon"><GoToMapIcon globalStyles={globalStyles} size={35} /></div>
        </div>
      </div>
      <ItemCarousel
        className="items-carousel"
        onIndexChange={onItemIndexChange}
        index={poiIndex}
      >
        {theItems?.map ? (
          theItems.map((item) => (
            <ItemCardHead
              key={store.getJsonPath(item, "id") || util.generateUid()}
              item={item}
            />
          ))
        ) : (
          <></>
        )}
      </ItemCarousel>
      <ItemCardBody
        className="items-item-card-body"
        constants={props.constants}
        item={currentItem}
        showAnimation={itemAnimation}
      />

      <div className="items-margin-container"></div>

      <div className="items-player-container">
        <PlayerControl
          className="items-player-control"
          constants={props.constants}
          showMini={showMiniPlayer}
          tour={tourData}
          tourId={tourIdRef.current}
          bookingCode={bookingCodeRef.current}
          langId={langIdRef.current}
        />
      </div>
      {(showFullStory ?? false) && (
        <div className="items-full-story-container">
          <StoryTextFull
            className="items-story-text-full"
            constants={props.constants}
            tourId={tourIdRef.current}
            bookingCode={bookingCodeRef.current}
            langId={langIdRef.current}
          />
        </div>
      )}
      {(showFullStoryImage ?? false) && (
        <div className="items-full-story-image-container">
          <StoryImageFull
            className="items-story-image-full"
            constants={props.constants}
          />
        </div>
      )}
      <Onboarding className="items-onboarding" page={"items"} />
    </div>
  );
};

export default Items;
