import { useRef, useState, useEffect } from "react";

import { Player } from "@lottiefiles/react-lottie-player";

import { getPlayingAnimation } from "../../util/styling";

const PlayingAnimation = ({ playing, color }) => {
  const [source, setSource] = useState("");

  useEffect(() => {
    setSource(getPlayingAnimation(color))
  }, [color])

  const playerRef = useRef();

  useEffect(() => {
    if (playerRef.current) {
      if (playing) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    }
  }, [playing]);

  return <Player ref={playerRef} autoplay loop src={source} style={{ height: "24px", width: "25px" }} />;
};

export default PlayingAnimation;
