

import { util } from "../../util/index";
import { store, useSessionState, useStateRef } from "../../util/store";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import PlayerControl from "./PlayerControl.jsx";
import {
  playNextAudio,
  playPreviousAudio,
} from "../CallApi";
import SwipeableContainer from "./SwipeableContainer";

import "./StoryTextFull.css";
import AssetCloseFullStory from "../../assets/CloseFullStory.svg";
import AssetShareFullStory from "../../assets/ShareFullStory.svg";
import { determineCategory, fullStoryTextBackground } from "../../util/styling.js";
import { StoryCategoryIcon } from "../../util/stylingComponents.jsx";

const StoryTextFull = (props) => {
  const [globalStyles] = useSessionState("globalStyles");

  const [category, setCategory] = useStateRef();
  const [categoryType, setCategoryType] = useStateRef();

  const [theStory, setTheStory, theStoryRef] = useStateRef();

  const [itemTitle, setItemTitle] = useStateRef();

  const [storyBackground, setStoryBackground] =
    useStateRef();
  const [titleColor, setTitleColor] =
    useStateRef();
  const [textColor, setTextColor] =
    useStateRef();

  const [marginTransition, setMarginTransition] =
    useStateRef();

  const [topMargin, setTopMargin] = useStateRef();

  const currentItemRef = useRef();
  const typeNameRef = useRef();

  const onShare = useCallback(async () => { }, []);

  const onNextStory = useCallback(async () => {
    await playNextAudio();
  }, []);

  const setBackground = useCallback(async () => {
    if (theStoryRef.current?.["category"]?.["type"] === "Info") {
      setCategoryType("info");
      setStoryBackground(fullStoryTextBackground(globalStyles?.theme, globalStyles?.fullStoryText?.info?.backgroundColor));
      setTitleColor(globalStyles?.fullStoryText?.info?.titleColor);
      setTextColor(globalStyles?.fullStoryText?.info?.textColor);
      typeNameRef.current = "Info stories";
    } else if (theStoryRef.current?.["category"]?.["type"] === "Themes") {
      setCategoryType("themes");
      setStoryBackground(fullStoryTextBackground(globalStyles?.theme, globalStyles?.fullStoryText?.themes?.backgroundColor));
      setTitleColor(globalStyles?.fullStoryText?.themes?.titleColor);
      setTextColor(globalStyles?.fullStoryText?.themes?.textColor);
      typeNameRef.current = "Themed stories";
    } else if (theStoryRef.current?.["category"]?.["type"] === "Tips") {
      setCategoryType("tips");
      setStoryBackground(fullStoryTextBackground(globalStyles?.theme, globalStyles?.fullStoryText?.tips?.backgroundColor));
      setTitleColor(globalStyles?.fullStoryText?.tips?.titleColor);
      setTextColor(globalStyles?.fullStoryText?.tips?.textColor);
      typeNameRef.current = "Action stories";
    } else if (theStoryRef.current?.["category"]?.["type"] === "Actions") {
      setCategoryType("actions");
      setStoryBackground(fullStoryTextBackground(globalStyles?.theme, globalStyles?.fullStoryText?.actions?.backgroundColor));
      setTitleColor(globalStyles?.fullStoryText?.actions?.titleColor);
      setTextColor(globalStyles?.fullStoryText?.actions?.textColor);
      typeNameRef.current = "Action stories";
    } else {
      setCategoryType("themes");
      setStoryBackground(fullStoryTextBackground(globalStyles?.theme, globalStyles?.fullStoryText?.themes?.backgroundColor));
      setTextColor(globalStyles?.fullStoryText?.themes?.textColor);
      setTitleColor(globalStyles?.fullStoryText?.themes?.titleColor);
      typeNameRef.current = "Themed stories";
    }
  }, []);

  const onPreviousStory = useCallback(async () => {
    await playPreviousAudio();
  }, []);

  const doClose = useCallback(async () => {
    store.setSession("show_full_story", false);
  }, []);

  const onClose = useCallback(async () => {
    setTopMargin(String(window.innerHeight) + "px");
    setTimeout(() => {
      doClose().then(() => { });
    }, 500);
  }, []);

  const slideUp = useCallback(async () => {
    setTopMargin("0px");
  }, []);

  const updateItemStory = useCallback(async () => {
    currentItemRef.current = store.getSession("current_item");
    setItemTitle(
      [
        currentItemRef.current?.["index"] < 10 ? "0" : "",
        currentItemRef.current?.["index"],
        ". ",
        currentItemRef.current?.["name"],
      ].join("")
    );
    setTheStory(store.getSession("current_story"));
    setCategory(determineCategory(store.getSession("current_story")));
    await setBackground();
  }, []);

  useEffect(() => store.subscribeSession("current_item", updateItemStory), []);
  useEffect(() => store.subscribeSession("current_story", updateItemStory), []);

  useEffect(() => {
    const initialize = async () => {
      setTopMargin(String(window.innerHeight) + "px");
      setMarginTransition("margin-top 0.5s");
      setTimeout(() => {
        slideUp().then(() => { });
      });
      await updateItemStory();
    };
    initialize().then(() => { });
  }, []);

  return (
    <div
      style={{
        backgroundColor: storyBackground,
        transition: (marginTransition),
        marginTop: (topMargin ?? "10000px"),
        width: "100%", height: "100%"
      }}
    >
      <div className="story-text-full-main-container">
        <SwipeableContainer
          className="story-text-full-header-container"
          onSwipedLeft={onNextStory}
          onSwipedRight={onPreviousStory}
        >
          <StoryCategoryIcon category={category} categoryType={theStoryRef.current?.["category"]?.["type"]?.toLowerCase()} globalStyles={globalStyles} section={"fullStoryText"} size={50} />

          <div className="story-text-full-title-container">
            <span className="story-text-full-story-title" style={{ color: (titleColor) }}>
              {util.getLocalizedMessage(theStory?.title)}
            </span>
            <span className="story-text-full-item-title" style={{ color: `${titleColor}a0` }}>
              {util.getLocalizedMessage(itemTitle)}
            </span>
          </div>

          <div className="story-text-full-close-container">
            <img
              className="story-text-full-close-image"
              onClick={onClose}
              src={AssetCloseFullStory}
            />
          </div>
        </SwipeableContainer>

        <div className="story-text-full-text-container">
          <span className="story-text-full-story-text" style={{ color: (textColor) }}>
            {util.getLocalizedMessage(theStory?.body)}
          </span>
        </div>

        <div className="story-text-full-more-container">
          <div
            className="story-text-full-shadow-container" style={{ background: `linear-gradient(${storyBackground}00, ${storyBackground})` }}
          ></div>

          <div
            className="story-text-full-player-container"
            style={{ backgroundColor: storyBackground }}
          >
            <PlayerControl
              className="story-text-full-player-control"
              constants={props.constants}
              showStoryMini={true}
              categoryType={categoryType}
              tourId={props?.tourId}
              bookingCode={props?.bookingCode}
              langId={props?.langId}
            />
          </div>
        </div>
        <img
          className="story-text-full-share-image"
          onClick={onShare}
          src={AssetShareFullStory}
        />
      </div>
    </div>
  );
};

export default StoryTextFull;
