import { util } from "../../util";
import { store, useSessionState, useStateRef } from "../../util/store";
import { useCallback } from "react";
import { useEffect } from "react";

import "./StoryText.css";
import AssetExpandStory from "../../assets/ExpandStory.svg";
import { StoryCategoryIcon } from "../../util/stylingComponents";
import { determineCategory, storyTextBackground } from "../../util/styling";

const StoryText = (props) => {
  const [globalStyles] = useSessionState("globalStyles");

  const [category, setCategory] = useStateRef();

  const [theStory, setTheStory] = useStateRef();

  const [titleColor, setTitleColor] = useStateRef();
  const [moreColor, setMoreColor] = useStateRef();
  const [storyBackground, setStoryBackground] =
    useStateRef();

  const [typeName, setTypeName] = useStateRef();

  const onMore = useCallback(async () => {
    store.setSession("show_full_story", true);
  }, []);

  const setBackground = useCallback(async () => {
    const story = props?.["story"];

    switch (story?.["category"]?.["type"]) {
      case "Info":
        setStoryBackground(storyTextBackground(globalStyles?.storyText?.info?.backgroundColor));
        setTypeName("Info stories");
        setTitleColor(globalStyles?.storyText?.info?.titleColor);
        setMoreColor(globalStyles?.storyText?.info?.moreColor);
        break;
      case "Tips":
        setStoryBackground(storyTextBackground(globalStyles?.storyText?.tips?.backgroundColor));
        setTypeName("Action stories");
        setTitleColor(globalStyles?.storyText?.tips?.titleColor);
        setMoreColor(globalStyles?.storyText?.tips?.moreColor);
        break;
      case "Actions":
        setStoryBackground(storyTextBackground(globalStyles?.storyText?.actions?.backgroundColor));
        setTypeName("Action stories");
        setTitleColor(globalStyles?.storyText?.actions?.titleColor);
        setMoreColor(globalStyles?.storyText?.actions?.moreColor);
        break;
      case "Themes":
      default:
        setStoryBackground(storyTextBackground(globalStyles?.storyText?.themes?.backgroundColor));
        setTypeName("Themed stories");
        setTitleColor(globalStyles?.storyText?.themes?.titleColor);
        setMoreColor(globalStyles?.storyText?.themes?.moreColor);
        break;
    }
  }, [props, globalStyles]);

  useEffect(() => {
    const initialize = async () => {
      setTheStory(props?.["story"]);
      setCategory(determineCategory(props?.["story"]));
      await setBackground();
    };
    initialize().then(() => { });
  }, [props, globalStyles]);

  return (
    <div
      className="story-text-main-container"
      style={{ backgroundColor: storyBackground }}
    >
      <div
        className="story-text-main-inner-container"
      >
        <div className="story-text-header-container">
          <StoryCategoryIcon category={category} categoryType={props?.story?.["category"]?.["type"]?.toLowerCase()} globalStyles={globalStyles} size={50} />

          <div className="story-text-title-container">
            <span
              className="story-text-story-title"
              style={{ color: (titleColor) }}
            >
              {util.getLocalizedMessage(theStory?.title)}
            </span>
            <span className="story-text-story-type">
              {util.getLocalizedMessage(typeName)}
            </span>
          </div>
        </div>

        <div className="story-text-text-container">
          <span className="story_text-1">
            {util.getLocalizedMessage(theStory?.body)}
          </span>
        </div>

        <div
          className="story-text-more-container blurred-background-shadow"
          onClick={onMore}
        >
          <div className="story-text-more-button" style={{ backgroundColor: moreColor }}>
            <span className="story-text-more-text">
              {util.getLocalizedMessage("$=storyText_more_text")}
            </span>
            <img className="story-text-image" src={AssetExpandStory} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoryText;
