import localforage from "localforage";
import { LRUCache } from "lru-cache";

import { store } from "../util/store";

const serverUrl = "https://www.cliomuseappserver.com/api/node/v2";
let serverToken;

const mediaBaseUrl = "https://media.cliomuseappserver.com";

////// TESTS ////////////////////////////////////////////////////////////////////////////

//
// https://www.audiotours.pro?id=240&code=XeBN6qqOct3erphUP7TklLe57B3B9Hyf_1DDDl4BCs0WhTrgtXF4hdCzqCGsi5VIAn
//
// Indoor:
// https://da470f40adfb11edb46de1cc93642165.gonow.link?id=634&code=yPIFRqSXGw0fWHvynEfVRHqbC2pLljll_L2TagZzG7b7jtEQI8KGFS7VRbDMEtKVsRY6Tlbj07grjjc3cHvpQ5cDFJGv
// https://cliomuse-loyalty-app.vercel.app?id=634&code=yPIFRqSXGw0fWHvynEfVRHqbC2pLljll_L2TagZzG7b7jtEQI8KGFS7VRbDMEtKVsRY6Tlbj07grjjc3cHvpQ5cDFJGv
// Outdoor:
// https://da470f40adfb11edb46de1cc93642165.gonow.link?id=641&code=1DjbRRkMj56AhlW00T3xP2a1tg5M2vhVt_h5nrAeWkQFG8t3NU4Yl8OngM8Cev8QMpqFlubbTON6ohJtZT9W2MtIirhWv
// https://cliomuse-loyalty-app.vercel.app?id=641&code=1DjbRRkMj56AhlW00T3xP2a1tg5M2vhVt_h5nrAeWkQFG8t3NU4Yl8OngM8Cev8QMpqFlubbTON6ohJtZT9W2MtIirhWv
//
// https://da470f40adfb11edb46de1cc93642165.gonow.link?id=466
//

////// HTTP ///////////////////////////////////////////////////////////////////////////

const httpImpl = {};
["get", "post", "put", "delete"].forEach((val) => {
  httpImpl[val] = async function (
    url,
    token,
    data,
    cacheKey,
    credentials = true
  ) {
    let result = {};

    if (!url || url.indexOf("undefined") >= 0) {
      throw new Error("Bad url");
    }

    try {
      if (url.startsWith("/")) {
        url = serverUrl + url;
      }

      let headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      if (token) {
        headers.token = token;
      }

      let body;
      if (val === "put" || val === "post") {
        body = data;
        if (data instanceof FormData) {
          delete headers["Content-Type"];
        } else {
          body = data ? JSON.stringify(data) : "{}";
        }
      }

      let response = await fetch(url, {
        headers,
        method: val,
        credentials: credentials ? "include" : "omit",
        body,
      });

      if (response.ok && cacheKey) {
        let cache = await caches.open(tourKeyPrefix);
        await cache.put(url, response);
        response = await cache.match(url);
      }

      let text = await response.text();
      let json = {};
      try {
        json = JSON.parse(text);
      } catch (e) {
        json = { error: text ?? e.message };
      }

      if (response.ok) {
        result = json;
      } else {
        result = {
          error:
            json.error ??
            json.message ??
            response.status + ": " + response.statusText,
        };
      }
    } catch (e) {
      result = { error: e.message };
    }

    return result;
  };
});

const apiGet = httpImpl["get"];
const apiPost = httpImpl["post"];
// const apiPut = httpImpl["put"];
// const apiDelete = httpImpl["delete"];

////// MEDIA ///////////////////////////////////////////////////////////////////////////

const storageVersion = 7;

localforage.config({
  driver: localforage.INDEXEDDB,
  name: "cliomuse",
  storeName: "mediacache-v" + storageVersion,
});

const getMediaLoadedKey = () => {
  let tour = store.getSession("tour_data");
  return `mediacache-v${storageVersion}-${tour?.id ?? "???"}-loaded`;
};

const urlCache = new LRUCache({
  max: 1000,
  dispose: (value) => {
    URL.revokeObjectURL(value);
  },
});

const setLoadingError = () => {
  store.setSession(
    "loading_error",
    "Some of the tour assets were not downloaded."
  );
};

const getMediaUrl = async (originalUrl, preload = false) => {
  if (!originalUrl) {
    return null;
  }

  let cachedUrl = urlCache.get(originalUrl);
  if (cachedUrl) {
    return cachedUrl;
  }

  let blob = await localforage.getItem(originalUrl);
  if (!blob) {
    try {
      if (preload) {
        let response = await fetch(originalUrl);
        if (response.status == 200) {
          blob = await response.blob();
        }

        if (!blob || blob.size == 0) {
          console.log("NO DATA FOR", originalUrl, "status:", response.status);
          blob = null;
          setLoadingError();
        } else {
          await localforage.setItem(originalUrl, blob);
        }
      }
    } catch (e) {
      setLoadingError();
      blob = null;
    }
  }

  if (blob && blob.size > 0) {
    let url = URL.createObjectURL(blob);
    urlCache.set(originalUrl, url);
    return url;
  } else {
    return originalUrl;
  }
};

const tourUrlPrefixes = {
  imageFile: "/museums/museums_img/",
  thumbFile: "/museums/museums_thumb/",
  groundImageFile: "/museums/ground_img/",
  authorImage: "/museums/logos/",
  sponsorImage: "/museums/sponsor_logos/",
};
const getTourMediaUrl = async (tour, field, preload) => {
  if (!tour) {
    return "";
  }
  if (!tour[field]) {
    return "";
  }

  let prefix = tourUrlPrefixes[field] ?? "";
  let originalUrl = mediaBaseUrl + prefix + tour[field];

  let url = await getMediaUrl(originalUrl, preload);
  return url;
};

const itemUrlPrefixes = {
  thumbFile: "/exhibits/exhibit_thumb/",
  imageFile: "/exhibits/exhibit_img/",
};
const getItemMediaUrl = async (item, field, preload) => {
  if (!item) {
    return "";
  }
  if (!item[field]) {
    return "";
  }

  let prefix = itemUrlPrefixes[field] ?? "";
  if (!prefix) {
    prefix = itemUrlPrefixes["thumbFile"] ?? "";
  }
  let originalUrl = mediaBaseUrl + prefix + item[field];

  let url = await getMediaUrl(originalUrl, preload);
  return url;
};

const storyUrlPrefixes = {
  imageFile: "/exhibits/historical_background/hb_img/",
  audioFile: "/exhibits/historical_background/hb_audio/",
};
const getStoryMediaUrl = async (story, field, preload) => {
  if (!story) {
    return "";
  }
  if (!story[field]) {
    return "";
  }

  let prefix = storyUrlPrefixes[field] ?? "";
  let originalUrl = mediaBaseUrl + prefix + story[field];

  let url = await getMediaUrl(originalUrl, preload);
  return url;
};

const loadTourMedia = async (tour) => {
  let promises = [];

  promises.push(getTourMediaUrl(tour, "imageFile", true));
  promises.push(getTourMediaUrl(tour, "thumbFile", true));
  promises.push(getTourMediaUrl(tour, "groundImageFile", true));
  promises.push(getTourMediaUrl(tour, "authorImage", true));
  promises.push(getTourMediaUrl(tour, "sponsorImage", true));

  await Promise.all(promises);
};

const loadItemMedia = async (item) => {
  let promises = [];
  promises.push(getItemMediaUrl(item, "thumbFile", true));
  promises.push(getItemMediaUrl(item, "imageFile", true));

  for (let story of item.stories) {
    promises.push(getStoryMediaUrl(story, "imageFile", true));
    promises.push(getStoryMediaUrl(story, "audioFile", true));
  }

  await Promise.all(promises);
};

const mediaAlreadyLoaded = async () => {
  return (await localforage.getItem(getMediaLoadedKey())) == true;
};

const loadMedia = async (tour) => {
  try {
    store.setSession("loading_error", null);

    if (await localforage.getItem(getMediaLoadedKey())) {
      store.setSession("loading_percent", 100);
      return;
    }

    let totalParts = tour.items.length + 1;
    let completedParts = 0;

    await loadTourMedia(tour);
    completedParts++;
    store.setSession(
      "loading_percent",
      Math.round((completedParts * 100) / totalParts)
    );
    for (let item of tour.items) {
      await loadItemMedia(item);
      completedParts++;
      store.setSession(
        "loading_percent",
        Math.round((completedParts * 100) / totalParts)
      );
    }

    if (!store.getSession("loading_error")) {
      store.setSession("loading_percent", 100);
      await localforage.setItem(getMediaLoadedKey(), true);
    }
  } catch (e) {
    setLoadingError();
  }
};

////// AUDIO ///////////////////////////////////////////////////////////////////////////

let currentItem;
let currentStory;

const audio = new Audio();
audio.onplay = () => {
  store.setSession("playing_item", currentItem);
};
audio.onpause = () => {
  store.setSession("playing_item", null);
};
audio.ontimeupdate = () => {
  store.setSession("playing_time", audio.currentTime);
};
audio.onended = () => {
  playNextAudio();
};

const playNextAudio = () => {
  if (!currentItem) {
    return;
  }
  if (!currentStory) {
    currentStory = currentItem.stories[0];
  }

  if (currentStory.index < currentItem.stories.length) {
    let story = currentItem.stories[currentStory.index];
    playAudio(currentItem, story);
  } else {
    let tour = store.getSession("tour_data");
    if (tour && currentItem.index < tour.items.length) {
      let nextItem = tour.items[currentItem.index - 1 + 1];
      store.setSession("current_item", nextItem);
      playAudio(nextItem);
    }
  }
};

const playPreviousAudio = () => {
  if (!currentItem) {
    return;
  }
  if (!currentStory) {
    currentStory = currentItem.stories[0];
  }

  if (currentStory.index > 1) {
    let story = currentItem.stories[currentStory.index - 2];
    playAudio(currentItem, story);
  } else {
    let tour = store.getSession("tour_data");
    if (tour && currentItem.index > 1) {
      let previousItem = tour.items[currentItem.index - 1 - 1];
      store.setSession("current_item", previousItem);
      let lastStory = previousItem.stories[previousItem.stories.length - 1];
      playAudio(previousItem, lastStory);
    }
  }
};

const setupLockScreen = () => {
  getStoryMediaUrl(currentItem, "imageFile").then((imageUrl) => {
    let metadata = {
      title: currentStory.title,
      artist: currentItem.name,
      artwork: [
        {
          src: imageUrl,
          type: "image/jpeg",
        },
      ],
    };
    navigator.mediaSession.metadata = new MediaMetadata(metadata);
  });

  navigator.mediaSession.setActionHandler("nexttrack", playNextAudio);
  navigator.mediaSession.setActionHandler("previoustrack", playPreviousAudio);
  navigator.mediaSession.setActionHandler("play", () => {
    audio.play();
  });
  navigator.mediaSession.setActionHandler("pause", () => {
    audio.pause();
  });
};

const initAudio = () => {
  try {
    audio.src = "/1secondofsilence.mp3";
    audio.play();
  } catch (e) {
    console.log(e);
  }
};

const playAudio = async (item, story) => {
  if (!item) {
    return;
  }
  let onboarded = await isOnboarded(store.getSession("current_page"));
  if (!onboarded) {
    return;
  }

  let itemChanged = false;
  if (
    store.getSession("current_item.id") != item?.id ||
    store.getSession("playing_item.id") != item?.id
  ) {
    store.setSession("current_item", item);
    itemChanged = true;
  }
  currentItem = item;

  if (!story) {
    story = itemChanged ? item.stories[0] : currentStory ?? item.stories[0];
  }
  store.setSession("current_story", story);

  let canPlay = true;
  if (story.id != currentStory?.id) {
    currentStory = story;

    let url = await getStoryMediaUrl(story, "audioFile");
    if (url) {
      if (audio.src) {
        audio.pause();
      }
      audio.src = url;
      setupLockScreen();
    } else {
      audio.src = "";
      canPlay = false;
    }
  }

  if (canPlay && audio.src != location.toString()) {
    try {
      audio.play();
    } catch (e) {
      store.setSession("playing_item", null);
      console.log(e);
    }
  } else {
    store.setSession("playing_item", null);
  }
};

const pauseAudio = () => {
  audio.pause();
};

const seekAudioTo = (delta) => {
  audio.currentTime = Math.max(audio.currentTime + delta, 0);
};

const msToMinSec = (ms) => {
  var minutes = Math.floor(ms / 60000);
  var seconds = ((ms % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};

////// UTIL ///////////////////////////////////////////////////////////////////////////

const addStoryIndex = (tour) => {
  if (!tour?.items) {
    return;
  }

  for (let i = 0; i < tour.items.length; i++) {
    let item = tour.items[i];
    item.index = i + 1;
    for (let s = 0; s < item.stories.length; s++) {
      item.stories[s].index = s + 1;
    }
  }
};

const tourKeyPrefix = "tour-data";

const getTourData = async (id, lang, bookingCode) => {
  let data = null;

  if (!id) {
    id = "433"; // "240";
  }
  if (!lang) {
    lang = "2";
  }

  let key = `${tourKeyPrefix}-${id}-${lang}`;
  data = await localforage.getItem(key);
  if (!data) {
    data = await apiGet(
      `/tours/${id}?lang_id=${lang}`,
      serverToken,
      null,
      tourKeyPrefix
    );
    if (!data?.error) {
      data.bookingCode = bookingCode;
      await localforage.setItem(key, data);
    }
  }

  if (!data?.error) {
    addStoryIndex(data);
  }

  return data;
};

const getOpenedTours = async () => {
  let keys = await localforage.keys();
  let tourKeys = keys.filter((k) => k.startsWith(tourKeyPrefix));

  let tours = [];
  if (tourKeys.length > 0) {
    for (let key of tourKeys) {
      tours.push(await localforage.getItem(key));
    }
  } else {
    let cache = await caches.open(tourKeyPrefix);
    tourKeys = await cache.keys();
    for (let key of tourKeys) {
      let response = await cache.match(key);
      let tourText = await response.text();
      let tour = {};
      try {
        tour = JSON.parse(tourText);
      } catch (e) {
        tour = { error: tourText ?? e.message };
      }

      if (tour.id) {
        let tourKey = `${tourKeyPrefix}-${tour.id}`;
        await localforage.setItem(tourKey, tour);
      }

      tours.push(tour);
    }
  }

  return tours;
};

// https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API/Using_the_Permissions_API
const getGeolocationState = async () => {
  let result = await navigator.permissions.query({ name: "geolocation" });
  store.setSession("geolocation_status", result.state);

  result.addEventListener("change", () => {
    store.setSession("geolocation_state", result.state);
  });

  return result.state; // "granted", "prompt", "denied"
};

const getGeolocation = async () => {
  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      (location) => resolve(location),
      (error) => resolve({ error }),
      { timeout: 10000 }
    );
  });
};

// const getClickPercent = (id, event) => {
//   let element = document.getElementById(id)
//   let bounds = element.getBoundingClientRect();
//   return (event.clientX - bounds.left) / bounds.width;
// }

const isOnboarded = async (page) => {
  if (page) {
    let onboarded = await localforage.getItem(
      "onboarded-" + page.toLowerCase()
    );
    return onboarded;
  } else {
    return false;
  }
};

const setOnboarded = async (page) => {
  await localforage.setItem("onboarded-" + page, true);
};

const isOnline = () => {
  return navigator.onLine;
};

////// AUTHENTICATE ////////////////////////////////////////////////////////////////////

const getDeviceId = async () => {
  let key = "device-id";
  let deviceId = await localforage.getItem(key);
  if (!deviceId) {
    deviceId = "BR-" + window.crypto.randomUUID();
    await localforage.setItem(key, deviceId);
  }

  return deviceId;
};

const getUser = async () => {
  let user = await localforage.getItem("user");

  if (user) {
    serverToken = user.token;
  }

  return user;
};

const updateUser = async (data, loginType) => {
  if (data?.user) {
    data = data.user;
  }

  let user = {
    id: data?.user_id,
    role_id: data?.user_role_id,
    token: data?.security_token, // "77132880538a8eb7a9464983c0d12d89"
    loginType,
  };

  await localforage.setItem("user", user);

  store.setSession("user", user);

  return user;
};

const redeem = async (code) => {
  let deviceId = await getDeviceId();

  let user;
  try {
    let data = await apiPost(`/auth/redeem`, null, {
      code,
      device_id: deviceId,
    });

    if (data.error) {
      user = { error: data.error };
    } else {
      user = await updateUser(data, "redeem");
    }
  } catch (e) {
    console.log(e);
    user = { error: e.message };
  }

  serverToken = user?.token;

  return user;
};

const deviceLogin = async () => {
  let deviceId = await getDeviceId();

  let user;
  try {
    let data = await apiPost(`/auth/deviceLogin`, null, {
      device_id: deviceId,
    });

    if (data.error) {
      user = { error: data.error };
    } else {
      user = await updateUser(data, "device-login");
    }
  } catch (e) {
    console.log(e);
    user = { error: e.message };
  }

  serverToken = user?.token;

  return user;
};

const isLoggedIn = async () => {
  let user = await localforage.getItem("user");
  return user.loggedIn;
};

const doLogin = async (email, password) => {
  let user = await localforage.getItem("user");

  try {
    let data = await apiPost(`/auth/login`, null, {
      email,
      password,
      mobile_user_id: user?.id,
    });
    if (data.error) {
      user = { error: data.error };
    } else {
      user = await updateUser(data, "user-login");
    }
  } catch (e) {
    console.log(e);
    user = { error: e.message };
  }

  return user;
};

const doSignup = async (email, password) => {
  let deviceId = await getDeviceId();
  let user = await localforage.getItem("user");

  try {
    let data = await apiPost(`/auth/register`, null, {
      email,
      password,
      device_id: deviceId,
      mobile_user_id: user?.id,
    });
    if (data.error) {
      user = { error: data.error };
    } else {
      user = await updateUser(data, "user-signup");
    }
  } catch (e) {
    console.log(e);
    user = { error: e.message };
  }

  return user;
};

const getLanguageCode = (location) => {
  let lang = "" + Object.fromEntries(new URLSearchParams(location.search)).lang;
  if (!lang) {
    lang = "2";
  }
  let languages = store.getSession("languages");
  let code = languages[parseInt(lang)] ?? "en";
  return code;
};

const partnerKeyPrefix = "partner-data";

const getStyleConfig = async (id, code) => {
  if (!id) {
    return null;
  }

  let key = `${partnerKeyPrefix}-${id}-${code}`;
  let data = await localforage.getItem(key);
  if (!data) {
    try {
      const accessCode = code ? `?access_code=${code}` : "";
      data = await apiGet(`/partners/tour/${id}${accessCode}`, "f5e99ada11e0f68299c2bb2641127cca", null, "style-config");
    } catch (e) {
      console.log(e);
      data = { error: e.message };
    }
  }

  /***/
  // data = {
  //   partner: {
  //     name: "Headout",
  //     email: "headout@example.com",
  //     logos: {
  //       main: "https://www.cliomuseappserver.com/supplier_logos/headout.png",
  //       medium:
  //         "https://www.cliomuseappserver.com/supplier_logos/headout_512.png",
  //       small:
  //         "https://www.cliomuseappserver.com/supplier_logos/headout_192.png",
  //     },
  //     brand: {
  //       theme: "dark", // "dark" "light"
  //       type: "whitelabel",
  //       primary_color_hex: "37da37",
  //       secondary_color_hex: null,
  //       info_color_hex: "31aeed",
  //       tip_color_hex: "edc431",
  //       font_family: "Montserrat",
  //       font_css_file:
  //         "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap",
  //     },
  //     authentication: false,
  //   },
  // };
  /***/

  if (!data?.error) {
    return data;
  }
};

const sendGTMEvent = (/* eventName, data */) => {
  // if (window.gtag) {
  // console.log("gtag", eventName, data);
  // window.gtag('event', eventName, data);
  // }
};

////// EXPORTS /////////////////////////////////////////////////////////////////////////

export {
  apiGet,
  getTourMediaUrl,
  getItemMediaUrl,
  getStoryMediaUrl,
  mediaAlreadyLoaded,
  loadMedia,
  initAudio,
  playAudio,
  pauseAudio,
  playNextAudio,
  playPreviousAudio,
  seekAudioTo,
  msToMinSec,
  addStoryIndex,
  getTourData,
  getOpenedTours,
  getGeolocationState,
  getGeolocation,
  isOnboarded,
  setOnboarded,
  isOnline,
  getUser,
  redeem,
  deviceLogin,
  isLoggedIn,
  doLogin,
  doSignup,
  getLanguageCode,
  getStyleConfig,
  sendGTMEvent,
};
