

import { util } from "../util/index";
import { store, useSessionState, useStateRef } from "../util/store";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import {
  getTourData,
  redeem,
  getUser,
  deviceLogin,
  getOpenedTours
} from "../components/CallApi";
import OpenedTourItem from "../components/common/OpenedTourItem";

import "./Splash.css";
import AssetClioMuseLogo from "../assets/ClioMuseLogo.svg";
// import AssetLoginLogo from "../assets/LoginLogo.svg";
import { updateGlobalStyles } from "../util/styling";

const VERSION = "1.1.43";

const Splash = () => {
  const [globalStyles] = useSessionState("globalStyles");

  let navigate = useNavigate();

  let location = useLocation();

  const [openedTourList, setOpenedTourList] = useStateRef();

  const [appVersion, setAppVersion] = useStateRef();

  const theParamsRef = useRef();
  const tourIdRef = useRef();
  const tourRef = useRef();
  const bookingCodeRef = useRef();
  const testScenarioRef = useRef();
  const langIdRef = useRef();

  const doGetTour = useCallback(async () => {
    tourRef.current = await getTourData(tourIdRef.current, langIdRef.current, bookingCodeRef.current);
    if (tourRef.current?.["error"] || (await isTestCase("no-tour"))) {
      store.setSession("authentication_error", "Unable to unlock tour");
      navigate(
        "/login" +
        [
          "?id=",
          tourIdRef.current,
          "&code=",
          bookingCodeRef.current,
          "&lang=",
          langIdRef.current,
        ].join("")
      );
    } else {
      store.setSession("tour_data", tourRef.current);
      navigate(
        "/tour" +
        [
          "?id=",
          tourIdRef.current,
          "&code=",
          bookingCodeRef.current,
          "&lang=",
          langIdRef.current,
        ].join("")
      );
    }
  }, []);

  const doRedeem = useCallback(async () => {
    let user = await redeem(bookingCodeRef.current);
    if (user?.["error"] || (await isTestCase("no-redeem"))) {
      store.setSession(
        "authentication_error",
        "Unable to unlock tour. Please contact support."
      );
      navigate(
        "/login" +
        [
          "?id=",
          tourIdRef.current,
          "&code=",
          bookingCodeRef.current,
          "&lang=",
          langIdRef.current,
        ].join("")
      );
    } else {
      await doGetTour();
    }
  }, []);

  const isTestCase = useCallback(async (case2) => {
    let isCase = false;
    testScenarioRef.current = theParamsRef.current?.["test-scenario"];
    if (testScenarioRef.current) {
      testScenarioRef.current = testScenarioRef.current.split(".");
      let index = testScenarioRef.current.indexOf(case2);
      isCase = index >= 0;
    }
    return isCase;
  }, []);

  const myBookingFlow = useCallback(async (user) => {
    if (user?.["token"] && !(await isTestCase("no-token"))) {
      tourRef.current = await getTourData(tourIdRef.current, langIdRef.current, bookingCodeRef.current);
      if (tourRef.current?.["error"] || (await isTestCase("no-tour"))) {
        await doRedeem();
      } else {
        store.setSession("tour_data", tourRef.current);
        navigate(
          "/tour" +
          [
            "?id=",
            tourIdRef.current,
            "&code=",
            bookingCodeRef.current,
            "&lang=",
            langIdRef.current,
          ].join("")
        );
      }
    } else {
      await doRedeem();
    }
  }, []);

  const shareLinkFlow = useCallback(async (user) => {
    if (user?.["token"] && !(await isTestCase("no-token"))) {
      await doGetTour();
    } else {
      user = await deviceLogin();
      await doGetTour();
    }
  }, []);

  useEffect(() => {
    const initialize = async () => {
      store.setSession(
        "queryParams",
        Object.fromEntries(new URLSearchParams(location.search))
      );

      theParamsRef.current = store.getSession("queryParams");
      tourIdRef.current = theParamsRef.current?.["id"];
      bookingCodeRef.current = theParamsRef.current?.["code"];
      langIdRef.current = theParamsRef.current?.["lang"];
      await updateGlobalStyles(tourIdRef.current, bookingCodeRef.current);

      setAppVersion(VERSION);
      util.print("Clio Muse Loyalty App " + VERSION);

      if (tourIdRef.current) {
        let user = await getUser();
        setOpenedTourList([]);
        if (bookingCodeRef.current) {
          await myBookingFlow(user);
        } else {
          await shareLinkFlow(user);
        }
      } else {
        setOpenedTourList(await getOpenedTours());
      }
    };
    initialize().then(() => { });
  }, []);

  return (
    <div className="splash-container main-container">
      {globalStyles?.splash == "cliomuse" &&
        <img className="splash-image" src={AssetClioMuseLogo} />
      }
      {globalStyles?.splash == "cobrand" &&
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
          <img className="splash-image" src={globalStyles?.logos?.main ?? ''} />
          <img className="splash-image" src={AssetClioMuseLogo} />
        </div>
      }
      {globalStyles?.splash == "whitelabel" &&
        <img className="splash-image" src={globalStyles?.logos?.main ?? ''} />
      }

      <div className="splash-tours-container">
        {openedTourList?.map ? (
          openedTourList.map((item) => (
            <OpenedTourItem
              key={store.getJsonPath(item, "_id") || util.generateUid()}
              OpenedTour={item}
            />
          ))
        ) : (
          <></>
        )}
      </div>
      <span className="splash-text">
        {util.getLocalizedMessage(appVersion)}
      </span>
    </div>
  );
};

export default Splash;
