import React from "react";
import { createRoot } from "react-dom/client";

import { Workbox } from 'workbox-window';
if ('serviceWorker' in navigator) {
  const wb = new Workbox('/sw-8.js');

  wb.addEventListener('controlling', () => {
    window.location.reload();
  });

  wb.register();
}

import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
